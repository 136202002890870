import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../button/Button';
import {
  COMPANION,
  CONTACT_BY,
  PRODUCT_FAMILIARITY,
  SPECIAL_TECHNICAL_INSTRUCTIONS_COUNTRIES,
} from '../../constants/form';

export default function Summary({
  data,
  handleClick,
  handleSubmit,
  // eslint-disable-next-line no-unused-vars
  handleClose,
  country,
  ...rest
}) {
  const { t } = useTranslation();
  const dataToShow = { ...data };

  const products = SPECIAL_TECHNICAL_INSTRUCTIONS_COUNTRIES[country]
    ? SPECIAL_TECHNICAL_INSTRUCTIONS_COUNTRIES[country]
    : [];
  const selectedProduct = products.find(
    product => product.id === dataToShow.prod_type
  );
  if (selectedProduct) {
    dataToShow.prod_type = t(selectedProduct.label);
  }
  const selectedContact = CONTACT_BY.find(
    contact => contact.id === dataToShow.contact_by
  );
  if (selectedContact) {
    dataToShow.contact_by = t(selectedContact.label);
  }

  const selectedCompanion = COMPANION.find(
    companion => companion.id === dataToShow.has_companion
  );
  if (selectedCompanion) {
    dataToShow.has_companion = t(selectedCompanion.label);
  }

  if (data.product_familiarity) {
    const toFind = data.product_familiarity || '1';
    const selectedFamiliarity = PRODUCT_FAMILIARITY.find(
      familiarity => familiarity.id === toFind
    );
    dataToShow.product_familiarity = t(selectedFamiliarity.label);
  }

  return (
    <form noValidate onSubmit={handleSubmit} {...rest}>
      <p className="uppercase font-bold text-primary">
        {t('user_provided_information')}
      </p>
      <p>
        {t('product_type') + ': ' + dataToShow.prod_type}{' '}
        {data.product_familiarity
          ? '(' + dataToShow.product_familiarity + ')'
          : ''}
      </p>
      {dataToShow.za_experience !== '' ? (
        <p>{t('za_experience') + ': ' + dataToShow.za_experience}</p>
      ) : (
        <></>
      )}
      <p>{t('first_name') + ': ' + dataToShow.first_name}</p>
      <p>{t('last_name') + ': ' + dataToShow.family_name}</p>
      <p>{t('age') + ': ' + dataToShow.age}</p>
      {dataToShow.city !== '' ? (
        <p>{t('city') + ': ' + dataToShow.city}</p>
      ) : (
        <></>
      )}
      {dataToShow.postal_code !== '' ? (
        <p>{t('post_code') + ': ' + dataToShow.postal_code}</p>
      ) : (
        <></>
      )}
      {dataToShow.za_region !== '' ? (
        <p>{t('za_region') + ': ' + dataToShow.za_region}</p>
      ) : (
        <></>
      )}
      {dataToShow.za_sales_representative !== '' ? (
        <p>{t('za_sales_representative') + ': ' + dataToShow.za_sales_representative}</p>
      ) : (
        <></>
      )}
      {dataToShow.za_organizer !== '' ? (
        <p>{t('za_organizer') + ': ' + dataToShow.za_organizer}</p>
      ) : (
        <></>
      )}

      {dataToShow.za_requested_date !== '' ? (
        <p>{t('za_requested_date') + ': ' + dataToShow.za_requested_date}</p>
      ) : (
        <></>
      )}
      {dataToShow.companion !== '' ? (
        <p>
          {t('companion') + ': '}
          {dataToShow.companion_quantity
            ? ` ( ${dataToShow.companion_quantity} ) `
            : dataToShow.has_companion}
        </p>
      ) : (
        <></>
      )}

      {data.address && <p>{t('address') + ': ' + dataToShow.address}</p>}

      {data.hcp && <p>{t('cvent.form.hcp') + ': ' + dataToShow.hcp}</p>}

      {data.diabetesCenter && (
        <p>
          {t('cvent.form.diabetes_center') + ': ' + dataToShow.diabetesCenter}
        </p>
      )}

      {data.reason && (
        <p>{t('cvent.form.reason') + ': ' + dataToShow.reason}</p>
      )}

      {dataToShow.message !== '' ? (
        <p>{t('message') + ': ' + dataToShow.message}</p>
      ) : (
        <></>
      )}
      <p className="uppercase font-bold text-primary mt-5">
        {t('contact_information') + ': '}
      </p>
      <p>{t('email_address') + ': ' + dataToShow.email}</p>
      <p>{t('phone_number') + ': ' + dataToShow.phone}</p>
      <p>{t('will_be_contacted_by') + ': ' + dataToShow.contact_by}</p>

      <div className="mt-10 flex justify-between">
        <div className="mr-5">
          <Button type="button" onClick={() => handleClick(data)}>
            {t('back_to_form')}
          </Button>
        </div>
        <Button onClick={handleSubmit}>{t('request_technical_info')}</Button>
      </div>
    </form>
  );
}

Summary.propTypes = {
  data: PropTypes.object.isRequired,
  severity: PropTypes.string,
  title: PropTypes.string,
  handleClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  country: PropTypes.string,
};

Summary.defaultProps = {
  data: {},
};
