import {
  InMemoryCache,
  ApolloClient,
  ApolloLink,
  Observable,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { persistCache } from 'apollo3-cache-persist';
import Config from './config';
import Auth from './helpers/Auth';
import introspectionResult from './fragmentTypes.json';
import { store } from './store/redux';

export async function initClient() {
  await persistCache({
    cache,
    storage: window.localStorage,
  });
}

const cache = new InMemoryCache({
  introspectionQueryResultData: introspectionResult,
  dataIdFromObject: (value) => value.id,
});

const authLink = setContext(async (_, { headers }) => {
  // const isLoggedIn = await Auth.isLoggedIn();
  const token = await Auth.getAccessToken();

  const userCountry = store.getState().userReducer.user.country;
  const notificationLanguage =
    store.getState().localeReducer.notificationLanguage;

  const context = {
    headers: {},
  };

  if (userCountry) {
    context.headers['Accept-Language'] =
      notificationLanguage.notificationAcceptLanguage;
  }

  context.headers['X-user-locale'] = notificationLanguage.notificationLanguage;

  // if (isLoggedIn) {
  //   context.headers.Authorization = `Bearer ${Auth.getAccessToken()}`;
  // }

  // if (operation) {
  //   operation.setContext(context);
  // }

  return {
    headers: {
      ...headers,
      ...context.headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const APIGraphqlUploadLink = createUploadLink({
  uri: Config.API_GRAPHQL,
});

const client = new ApolloClient({
  // uri: Config.API_GRAPHQL,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          if (err?.extensions?.code === 'jwt expired') {
            return new Observable((observer) => {
              Auth.refreshAccessToken()
                .then(() => {
                  // Retry last failed request
                  forward(operation).subscribe(observer);
                })
                .catch((error) => {
                  // No refresh or client token available, we force user to login
                  observer.error(error);
                  Auth.logout();
                });
            });
          } else if (err?.extensions?.code === 'INACTIVE_USER') {
            // If inactive user do logout
            Auth.logout();
          }
        }
      }
      if (networkError) {
        console.log(networkError.message);
        if (networkError.statusCode === 500) {
          Auth.logout();
        }
      }
    }),
    authLink,
    APIGraphqlUploadLink,
  ]),
  fetchOptions: {
    mode: 'cors',
  },
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'cache-first',
    },
  },
});

export default client;
