import React from 'react';
import PropTypes from 'prop-types';
import Text from './Text';

function Select(props) {
  const {
    selectItems,
    ...rest
  } = props;

  return (
    <Text
      select
      SelectProps={{
        native: true,
      }}
      {...rest}
    >
      {selectItems}
    </Text>
  );
}

export const getMapFromArray = data => {
  return data.reduce((acc, item) => {
    acc.push({
      label: item.name,
      id: item.name
    });

    return acc;
  }, []);
};

Select.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.string,
  validators: PropTypes.array,
  errorMessages: PropTypes.array,
  onChange: PropTypes.func,
  selectItems: PropTypes.node,
};

export default Select;
