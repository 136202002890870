/* eslint-disable */

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import StepCrumbs from '../../steps/StepCrumbs';
// import Previous from '../../button/Previous';
import Button from '../../button/Button';

function SelectionFrame({
  className,
  renderHeader,
  renderContent,
  renderFooter,
  renderFooterButton,
  handleBack,
  currentStepData,
}) {
  const { t } = useTranslation();
  return (
    <div className='shadow-3xl mt-5'>
      <div className={`${className} flex flex-col lg:flex-row `}>
        <div className='md:p-12 lg:w-2/5 '>
          {currentStepData?.stepCategory !==
            ('disable_breadcumbs' && undefined) && (
            <div className='grid justify-items-center'>
              <StepCrumbs currentStep={currentStepData} />
            </div>
          )}

          <div className=''>
            <div className=''>{renderHeader}</div>
          </div>
        </div>

        <div className='w-full lg:w-3/5 bg-tint-stack-teal p-12'>
          {renderContent}
          {/* <Previous onClick={handleBack}>{t('previous')}</Previous> */}
          {/* <div className='flex flex-row justify-end items-stretch'> */}
          <div>{renderFooter}</div>

          <div className='flex justify-between '>
            <Button
              // className='w-28 self-end bg-cobalt hover:bg-green'
              className='rounded-full text-dark-grey-body px-4 mt-3 '
              onClick={handleBack}
            >
              {t('previous')}
            </Button>
            <div>{renderFooterButton}</div>
            {/* <span className='flex flex-row justify-end items-stretch'> */}
            {/* </span> */}
          </div>
        </div>
      </div>
      {/* 
      <div className='flex flex-col lg:flex-row'>
        <div className='w-full lg:w-3/5'>{renderFooter}</div>
      </div> */}
    </div>
  );
}

export default function SelectionFrameLightBlue({ className, ...props }) {
  return <SelectionFrame className={` ${className}`} {...props} />;
}

export function SelectionFramePrimary({ className, ...props }) {
  //Summary screen
  return <SelectionFrame className={` ${className}`} {...props} />;
}

SelectionFrame.propTypes = {
  renderContent: PropTypes.node,
  renderHeader: PropTypes.node,
  renderFooter: PropTypes.node,
  renderFooterButton: PropTypes.node,
  className: PropTypes.string,
  handleBack: PropTypes.func,
  stepCategory: PropTypes.any,
  currentStepData: PropTypes.any,
};

SelectionFrameLightBlue.propTypes = SelectionFrame.propTypes;
SelectionFramePrimary.propTypes = SelectionFrame.propTypes;
