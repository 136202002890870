import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import RegisterAttendee from './RegisterAttendee';
import Event from '../../schema/Event';
import { store } from '../../store/redux';
/* eslint-disable */
// eslint-disable-next-line no-unused-vars
function GraphQLWrapper({ computedMatch, location }) {
  const { t } = useTranslation();
  const [mutateAttendee] = useMutation(Event.POST_EVENT_ATTENDEE);
  const [registerAttendee] = useMutation(Event.SEND_EMAIL_ATTENDEE);
  const [saveToDashboard] = useMutation(Event.EVENT_REGISTER_TO_DASHBOARD);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [severity, setSeverity] = useState('success');
  const [showFeedback, setShowFeedback] = useState(false);

  const alpha2 = store.getState().userReducer.user
    ? store.getState().userReducer.user
    : { country: { alpha2: 'FAIL' } };

  const notificationAcceptLanguage = store.getState().localeReducer
    .notificationLanguage.notificationAcceptLanguage
    ? store.getState().localeReducer.notificationLanguage
        .notificationAcceptLanguage
    : 'en-US';

  const flowForm = store.getState().flowFormReducer.flowForm;

  const id = computedMatch.params.id;
  const { data: eventData } = useQuery(Event.GET_ORGANIZER_EMAIL_BY_EVENT_ID, {
    variables: { eventId: id },
  });

  let organizers = [];

  if (eventData !== undefined) {
    eventData.event.organizers.forEach((organizer) => {
      organizers.push(organizer.email);
    });
  }
  const organizersEmail = organizers.join(', ');
  async function register(form, event) {
    setIsDisabled(true);

    const dataUserRegisterToEvent = {
      eventTitle: event.title,
      eventStart: event.start,
      eventEnd: event.end,
      eventID: event.id,
      userAlpha2: alpha2.country.alpha2,
      userCountryName: alpha2.country.name,
      userUsername: alpha2.username,
      extraForm: JSON.stringify({ ...form, flowForm: { ...flowForm } }),
      extraEvent: JSON.stringify(event),
      extraUser: JSON.stringify(alpha2),
    };

    try {
      await mutateAttendee({
        variables: {
          input: {
            eventId: form.id,
            localeFromSelector: notificationAcceptLanguage,
          },
        },
      });

      await registerAttendee({
        variables: {
          input: {
            eventId: form.id,
            name: form.name,
            email: form.email,
            postalCode: form.zip,
            phoneNumber: form.phone,
            eventDate: form.eventDate,
            eventTitle: event.title,
            eventLocation: event.city.locality,
            at_center_treating_doctor: form.at_center_treating_doctor,
            fi_treatment_place: form.fi_treatment_place,
            fi_pump_number: form.fi_pump_number,
            fi_nurse: form.fi_nurse,
            fi_patient_group: form.fi_patient_group,
            il_city_of_residence: form.il_city_of_residence || '',
            il_diabetes_clinic: form.il_diabetes_clinic || '',
            il_doctors_name: form.il_doctors_name || '',
            nl_hospital: flowForm?.hospital || '',
            za_whos_your_doctor: form.za_whos_your_doctor,
            za_region: form.za_region || '',
            za_experience: form.za_experience || '',
            za_requested_date: form.za_requested_date || '',
            za_organizer: form.za_organizer || '',
            za_organizer_email: form.za_organizer_email || '',
            za_representative_email: form.za_representative_email || '',
            za_sales_representative: form.za_sales_representative || '',
            de_pump_only_question: form.de_pump_only_question,
            de_cgm_only_question: form.de_cgm_only_question,
            de_health_insurance_company: form.de_health_insurance_company,
            uk_pronouns_qestion: form.uk_pronouns_qestion || '',
            uk_hospital: form.uk_hospital || '',
            uk_hospital_not_in_list_optional:
              form.uk_hospital_not_in_list_optional || '',
            uk_healthcare_professional: form.uk_healthcare_professional || '',
            es_province: form.es_province,
            es_hospital: form.es_hospital,
            es_hospital_not_in_list_optional:
              form.es_hospital_not_in_list_optional,
            es_companion_name: form.es_companion_name,
            age: form.age,
            date_of_birth: form.date_of_birth || '',
            guests: form.guests,
            message: form.message,
            formTitle: form.formTitle,
            organizerEmail: '',
            organizerEmailList: organizersEmail,
            environmentType: process.env.REACT_APP_ENV,
            countryUser: alpha2.country?.alpha2,
          },
        },
      }).then(async () => {
        await saveToDashboard({
          variables: { input: dataUserRegisterToEvent },
        });
      });

      if (organizers) {
        for (const organizer of organizers) {
          await registerAttendee({
            variables: {
              input: {
                eventId: form.id,
                name: form.name,
                email: form.email,
                postalCode: form.zip,
                phoneNumber: form.phone,
                eventDate: form.eventDate,
                eventTitle: event.title,
                eventLocation: event.city.locality,
                at_center_treating_doctor: form.at_center_treating_doctor,
                fi_treatment_place: form.fi_treatment_place,
                fi_pump_number: form.fi_pump_number,
                fi_nurse: form.fi_nurse,
                fi_patient_group: form.fi_patient_group,
                il_city_of_residence: form.il_city_of_residence || '',
                il_diabetes_clinic: form.il_diabetes_clinic || '',
                il_doctors_name: form.il_doctors_name || '',
                nl_hospital: flowForm?.hospital || '',
                za_whos_your_doctor: form.za_whos_your_doctor,
                za_region: form.za_region || '',
                za_experience: form.za_experience || '',
                za_requested_date: form.za_requested_date,
                za_organizer: form.za_organizer || '',
                za_organizer_email: form.za_organizer_email || '',
                za_representative_email: form.za_representative_email || '',
                za_sales_representative: form.za_sales_representative,
                de_pump_only_question: form.de_pump_only_question,
                uk_pronouns_qestion: form.uk_pronouns_qestion || '',
                uk_hospital: form.uk_hospital || '',
                uk_hospital_not_in_list_optional:
                  form.uk_hospital_not_in_list_optional || '',
                uk_healthcare_professional:
                  form.uk_healthcare_professional || '',
                de_cgm_only_question: form.de_cgm_only_question,
                de_health_insurance_company: form.de_health_insurance_company,
                es_province: form.es_province,
                es_hospital: form.es_hospital,
                es_hospital_not_in_list_optional:
                  form.es_hospital_not_in_list_optional,
                es_companion_name: form.es_companion_name,
                age: form.age,
                date_of_birth: form.date_of_birth || '',
                guests: form.guests,
                message: form.message,
                formTitle: form.formTitle,
                organizerEmail: organizer,
                organizerEmailList: organizersEmail,
                environmentType: process.env.REACT_APP_ENV,
                countryUser: alpha2.country?.alpha2,
              },
            },
          });
        }
      }

      setSeverity('success');
      setSnackbarText(t('register.attendee.success_and_redirect'));
      setShowFeedback(true);
    } catch (e) {
      if (e.message.includes('Attendee already exists related')) {
        setSnackbarText(t('registration.account_exists'));
      } else {
        setSnackbarText(e.message);
      }

      setSeverity('warning');
      setIsDisabled(false);
    } finally {
      setOpenSnackbar(true);
    }
  }

  const handleCloseDialog = () => {
    setShowFeedback(!showFeedback);
  };

  return (
    <RegisterAttendee
      computedMatch={computedMatch}
      handleCloseDialog={handleCloseDialog}
      submit={register}
      isDisabled={isDisabled}
      openSnackbar={openSnackbar}
      setOpenSnackbar={setOpenSnackbar}
      snackbarText={snackbarText}
      severity={severity}
      showFeedback={showFeedback}
    />
  );
}

GraphQLWrapper.propTypes = {
  computedMatch: PropTypes.object,
  location: PropTypes.object,
};

export default GraphQLWrapper;
