/* eslint-disable */

import React, { useState } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  InfoWindow,
  Marker,
} from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { groupBy, sortBy } from 'lodash';
import * as dates from 'react-big-calendar/lib/utils/dates';
import { Navigate } from 'react-big-calendar';
import Config from '../../../config';
import routes from '../../../helpers/routes';

export default function CustomMapView(props) {
  const { t } = useTranslation();
  const [markerList, setMarkerList] = useState([]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: Config.API_KEY,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(async function callback(map) {
    const grouped = groupBy(props.events, (item) => item.lat && item.lng);

    const handleGrouped = [];

    for (const group in grouped) {
      let groupInformation = {
        events: [],
      };

      for (let i = 0; i < grouped[group].length; i++) {
        const currentEvent = grouped[group][i];

        if (i === 0) {
          groupInformation.totalEvents = grouped[group].length;
          groupInformation.id = currentEvent.id;
          groupInformation.lat = Number(currentEvent.lat);
          groupInformation.lng = Number(currentEvent.lng);
        }

        groupInformation.events.push(currentEvent);
      }

      handleGrouped.push(groupInformation);
    }

    setMarkerList(handleGrouped);
    const bounds = new google.maps.LatLngBounds();
    handleGrouped.forEach((data) =>
      bounds.extend({ lat: data.lat, lng: data.lng })
    );
    map.fitBounds(bounds);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ height: '94%' }}
      google={props.google}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={() => setActiveMarker(null)}
    >
      {markerList.map((element, index) => {
        return (
          <Marker
            key={index}
            position={{
              lat: Number(element.lat),
              lng: Number(element.lng),
            }}
            onClick={() => handleActiveMarker(element.id)}
          >
            {activeMarker === element.id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <p>
                  <a
                    href={routes.calendar_register.replace(':id', element.id)}
                    target='_blank'
                    rel='noreferrer'
                    title={element.events[0].description}
                    className='hover:underline'
                  >
                    {element.events[0].title} -{' '}
                    {moment(element.events[0].start).format(
                      'dddd, MMMM Do YYYY, h:mm:ss a'
                    )}
                  </a>
                </p>
              </InfoWindow>
            ) : null}
          </Marker>
        );
      })}
    </GoogleMap>
  ) : (
    <></>
  );
}

export const MapView = CustomMapView;

MapView.range = (date, { localizer }) => {
  let start = dates.firstVisibleDay(date, localizer);
  let end = dates.lastVisibleDay(date, localizer);
  return { start, end };
};

MapView.navigate = (date, action) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return dates.add(date, -1, 'month');

    case Navigate.NEXT:
      return dates.add(date, 1, 'month');

    default:
      return date;
  }
};

MapView.title = (date, { localizer }) =>
  localizer.format(date, 'monthHeaderFormat');

CustomMapView.propTypes = {
  events: PropTypes.array,
  google: PropTypes.object,
  id: PropTypes.string,
};

CustomMapView.defaultProps = {
  events: [],
  google: {},
};
