/* eslint-disable */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import Menu from '../menu/Menu';
import MenuDashboard from '../menuDashboard/MenuDashboard';
import HeaderMedtronic from '../header/HeaderMedtronic';
import User from '../../schema/User';
import { setUser, setUserLoggedIn } from '../../actions/user';
import { setLocale, setNotificationLanguage } from '../../actions/locale';
import Container from '../container/Container';
import AlertPTH from '../header/AlertPathway';
import routes from '../../helpers/routes';

import {
  ENGLISH,
  LANGUAGES_BY_COUNTRY,
} from '../../helpers/SupportedLanguages';

const mapDispatchToProps = (dispatch) => ({
  dispatchUser: (user) => dispatch(setUser(user)),
  dispatchLocale: (locale) => dispatch(setLocale(locale)),
  dispatchNotificationLanguage: (notificationLanguage) =>
    dispatch(setNotificationLanguage(notificationLanguage)),
  dispatchLoggedIn: (loggedIn) => dispatch(setUserLoggedIn(loggedIn)),
});

const mapStateToProps = (state) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    loggedInDashboard: state.userDashboardReducer.loggedIn,
    locale: state.localeReducer.locale,
    notificationLanguage: state.localeReducer.notificationLanguage,
    last_trainrg: state.tagsReducer.last_trainrg,
    pathway: state.pathwayReducer.pathway,
  };
};

export function Main({
  children,
  dispatchUser,
  dispatchLoggedIn,
  loggedIn,
  loggedInDashboard,
  last_trainrg,
  pathway,
  locale,
  notificationLanguage,
  dispatchLocale,
  dispatchNotificationLanguage,
}) {
  const pathname = window.location.pathname;

  const { data, loading } = useQuery(User.GET_PROFILE_INFO, {
    skip: loggedIn === false,
  });
  const [user, setUser] = useState({});
  var getPathLocation = pathname?.includes(routes.dashboard.index);

  useEffect(() => {
    const user = data ? data.getProfileData : null;
    if (user) {
      dispatchUser(user);
      setUser(user);
    }

    if (
      user &&
      LANGUAGES_BY_COUNTRY[user.country.alpha2]?.filter(
        (country) => country.code === locale
      ).length === 0
    ) {
      dispatchLocale(ENGLISH);
    }

    if (!user && !loading) {
      dispatchUser({});
      dispatchLoggedIn(false);
    }
  }, [data, loading]);

  return (
    <div>
      {/* {t('search.onlineandfacetoface')} */}
      <div className='bg-white'>
        <HeaderMedtronic
          isLoggedIn={loggedIn}
          dispatchLocale={dispatchLocale}
          dispatchNotificationLanguage={dispatchNotificationLanguage}
          locale={locale}
          notificationLanguage={notificationLanguage}
          user={user}
        />
      </div>
      <div className='sticky top-0 z-60'>
        {loggedIn && <Menu />}
        {loggedIn &&
          ((pathway?.id.length > 0 && pathway !== null) ||
            (last_trainrg?.length > 0 && last_trainrg !== null)) && (
            <AlertPTH />
          )}
      </div>
      <div className='min-h-screen bg-fixed bg-no-repeat bg-cover bg-main '>
        {!getPathLocation && (
          <div className=''>
            <Container>{children}</Container>
          </div>
        )}

        {getPathLocation && (
          <div
            className={`'' + ${loggedInDashboard ? 'grid grid-cols-6' : ''}`}
          >
            <div className='col-start-1 col-end-2'>
              {loggedInDashboard && (
                <div className=''>
                  <MenuDashboard />
                </div>
              )}
            </div>

            <div className='col-start-2 col-end-7 pr-7'>
              <Container>{children}</Container>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

Main.propTypes = {
  dispatchUser: PropTypes.func,
  dispatchLoggedIn: PropTypes.func,
  loggedIn: PropTypes.bool,
  loggedInDashboard: PropTypes.bool,
  last_trainrg: PropTypes.any,
  pathway: PropTypes.any,
  initial_tags: PropTypes.any,
  children: PropTypes.node,
  locale: PropTypes.string,
  notificationLanguage: PropTypes.any,
  dispatchLocale: PropTypes.func,
  dispatchNotificationLanguage: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
