import { gql } from '@apollo/client';

const GET_COUNTRIES = gql`
  query {
    countries {
      _id
      name
      alpha2
      lat
      lng
    }
  }
`;

const Countries = {
  GET_COUNTRIES,
};

export default Countries;
