export default [
  {
    stepNumber: '1',
    stepName: '',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'CardSelection',
    options: [
      {
        from: '1',
        to: '5',
        title: 'cvent.cards.first_step.title',
        label: 'cvent.cards.first_step.button',
        image: '/workflow/minimed.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.first_step.description',
        alert: 'cvent.cards.first_step.alert',
        disabled: false,
      },
      {
        from: '1',
        to: '2.1',
        title: 'cvent.cards.second_step.title',
        label: 'cvent.cards.second_step.button',
        image: '/workflow/at_smartpen.png',
        device: '/workflow/at_smartpen_icon.png',
        description: 'cvent.cards.second_step.description',
        disabled: false,
        skipFlow: false,
        flowTags: [
          {
            key: 'Product',
            value: 'Smartpen',
          },
        ],
      },
      // {
      //   from: '1',
      //   to: '20',
      //   title: 'cvent.cards.cgm_step.title',
      //   label: 'cvent.cards.cgm_step.button',
      //   image: '/workflow/CGMTraining.jpg',
      //   // device: '/workflow/guardian-cgm.png',
      //   description: 'cvent.cards.cgm_step.description',
      // },
    ],
  },
  {
    stepNumber: '2',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2',
        to: '3',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '2',
        to: '4',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '2.1',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_pen_arrived_title',
    stepDescription: 'cvent.steps.cvent_check_if_pen_arrived_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2.1',
        to: '7.1',
        ATshouldDisplayImage: false,
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '2.1',
        to: 'qr_codes',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '4',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDescription: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '3',
    centered: true,
    stepName: 'cvent.steps.ch.form_title',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: {},
    componentType: 'FormSelection',
    options: [
      {
        from: '2',
        to: '5',
        title: 'global.continue',
        label: 'global.continue',
      },
    ],
  },
  {
    stepNumber: '5',
    centered: true,
    stepName: 'cvent.steps.ch.select_product',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_entry_level',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: '5',
        to: '6',
        title: 'cvent.steps.cvent_M780G',
      },
      // {
      //   from: '5',
      //   to: 'Summary',
      //   title: 'cvent.steps.cvent_CGM',
      //   label: '',
      // },
    ],
  },
  {
    stepNumber: '6',
    centered: true,
    stepName: 'cvent.steps.ch.therapy_experience',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    stepImages: [],
    componentType: 'RadioSelection',
    options: [
      {
        from: '6',
        to: 'Summary',
        title: 'cvent.steps.ch.no_experience',
        label: '',
      },
      {
        from: '6',
        to: '7',
        title: 'cvent.steps.ch.experience_with_mdt',
        label: '',
      },
      {
        from: '6',
        to: 'Summary',
        title: 'cvent.steps.ch.experience_with_other',
        label: '',
      },
    ],
  },
  {
    stepNumber: '6.1',
    centered: true,
    stepName: 'cvent.steps.inpen_experience_title',
    stepDescription: 'cvent.steps.inpen_experience_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '6.1',
        to: '7.1',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '6.1',
        to: '4',
        flowTags: [
          {
            key: 'Product',
            value: 'Full system2',
          },
        ],
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '7',
    centered: true,
    stepName: 'cvent.steps.ch.device_training_participation',
    stepDescription: 'cvent.steps.ch.device_training_participation',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    stepImages: [],
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '7',
        to: '9',
        title: 'cvent.steps.ch.yes',
        label: '',
      },
      {
        from: '7',
        to: '8',
        title: 'cvent.steps.ch.no',
        label: '',
      },
    ],
  },
  {
    stepNumber: '7.1',
    centered: true,
    stepName: 'cvent.steps.cvent_settings_title',
    stepDescription: 'cvent.steps.cvent_settings_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '7.1',
        //to: '6.1',
        flowTags: [
          {
            key: 'Product',
            value: 'Smartpen',
          },
        ],
        skipSummary: true,
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '7.1',
        to: '8.1',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '8',
    centered: true,
    stepName: 'cvent.steps.ch.contract_disclaimer',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '8.1',
    centered: true,
    stepName: 'cvent.steps.cvent_connect_diabetes_team',
    stepDescription: 'cvent.steps.cvent_connect_diabetes_team',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '9',
    centered: true,
    stepName: 'cvent.steps.ch.select_pump',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_finish',
    stepImages: [],
    componentType: 'RadioSelection',
    options: [
      {
        from: '9',
        to: '10',
        title: 'cvent.steps.cvent_VEO',
        label: '',
      },
      {
        from: '9',
        to: '10',
        title: 'cvent.steps.cvent_M640G',
        label: '',
      },
      {
        from: '9',
        to: 'Summary',
        title: 'cvent.steps.cvent_M670G',
        label: '',
      },
    ],
  },
  {
    stepNumber: '10',
    centered: true,
    stepName: 'cvent.steps.ch.cgm_experience',
    stepDescription: 'cvent.steps.ch.cgm_experience',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    stepImages: [],
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '10',
        to: 'Summary',
        title: 'cvent.steps.ch.yes',
        label: '',
      },
      {
        from: '10',
        to: 'Summary',
        title: 'cvent.steps.ch.no',
        label: '',
      },
    ],
  },
  {
    stepNumber: '11',
    centered: true,
    stepName: 'cvent.steps.ch.select_therapy_sensor',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: '11',
        to: 'Summary',
        title: 'cvent.steps.ch.no_experience',
        label: '',
      },
      {
        from: '11',
        to: 'Summary',
        title: 'cvent.steps.ch.experience_with_mdt',
        label: '',
      },
    ],
  },
  {
    stepNumber: '20',
    centered: true,
    stepName: 'cvent.steps.ch.select_product',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_entry_level',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: '20',
        to: '12',
        title: 'cvent.steps.cvent_CGM',
        label: '',
      },
    ],
  },
  {
    stepNumber: '12',
    centered: true,
    stepName: 'cvent.steps.ch.device_training_participation',
    stepDescription: 'cvent.steps.ch.device_training_participation',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    stepImages: [],
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '20',
        to: 'Summary',
        title: 'cvent.steps.ch.yes',
        label: '',
      },
      {
        from: '12',
        to: '8',
        title: 'cvent.steps.ch.no',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'qr_codes',
    centered: true,
    stepName: 'cvent.steps.cvent_qr_codes',
    stepDescription: 'cvent.steps.cvent_qr_codes',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        ATDisplayQRCode: true,
        title: '',
        label: '',
      },
    ],
  },
];
