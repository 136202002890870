import React from 'react';
import { useTranslation } from 'react-i18next';
import { TECHNICAL_TRAINING_FOR_KIDS_PRODUCTS, TECHNICAL_TRAINING_FOR_KIDS_FORM_TYPE } from '../../../constants/form';
import FormManager from '../FormManager';
import TechnicalBriefingSchema from '../../../schema/forms/TechnicalBriefing';
import FormInformation from '../FormInformation';

export function Heading() {
  const { t } = useTranslation();
  return (
    <FormInformation
      title={t('cvent.technical.training_kids.title')}
      description={t('cvent.technical.training_kids.description')}
      helper={t('cvent.technical.training_kids.helper')}
      required={t('cvent.technical.form.required_fields')}
    />
  );
}

export default function TechnicalBriefing(props) {
  const { t } = useTranslation();
  return (
    <FormManager
      {...props}
      heading={<Heading />}
      products={TECHNICAL_TRAINING_FOR_KIDS_PRODUCTS}
      formType={TECHNICAL_TRAINING_FOR_KIDS_FORM_TYPE}
      productFamiliarity={false}
      selectCompanion={false}
      mutation={TechnicalBriefingSchema.DATA_MUTATION}
      validator={{
        age: [
          {
            rule: 'maxNumber: 9',
            message: t('registration_error_age_disclaimer'),
          }
        ],
      }}
    />
  );
}
