import PropTypes from 'prop-types';
export function DeliveryIcon({ className, ...props }) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      className={['fill-current', className].join(' ')}
      {...props}>
      <g>
        <polygon points="7.95 7.95 7.95 27.81 10.24 27.81 10.24 10.24 60.63 10.24 60.63 60.63 30.5 60.63 30.5 62.91 62.91 62.91 62.91 7.95 7.95 7.95" />
        <path d="m22.66,59.84l0,-4.43l19.86,-8.41l0,4.4l-19.86,8.44zm-12.37,-10.56l10,6l0,4.24l-10,-6l0,-4.24zm2.9,-17.07l19.62,-8.33l-2.51,13.12l-19.74,8.33l2.63,-13.12zm8.38,21.12l-9.88,-5.94l19.55,-8.3l9.88,5.91l-19.55,8.33zm23.29,-8.78l-12.22,-7.34l3.3,-17.21l-24.8,10.54l-3.14,15.9l0,8.4l13.44,8.08l23.42,-9.92l0,-8.45z" />
      </g>
    </svg>
  );
}

DeliveryIcon.propTypes = {
  className: PropTypes.string,
};