import { useTranslation } from 'react-i18next';
import FormManager from '../FormManager';
import {
  TECHNICAL_BRIEFING_PRODUCTS,
  TECHNICAL_BRIEFING_FORM_TYPE,
} from '../../../constants/form';
import TechnicalBriefingSchema from '../../../schema/forms/TechnicalBriefing';
import FormInformation from '../FormInformation';

export function Heading() {
  const { t } = useTranslation();
  return (
    <FormInformation
      title={t('cvent.technical.briefing.title')}
      description={t('cvent.technical.briefing.description')}
      helper={t('cvent.technical.briefing.helper')}
      required={t('cvent.technical.form.required_fields')}
    />
  );
}

export default function TechnicalBriefing(props) {
  const { t } = useTranslation();
  return (
    <FormManager
      {...props}
      heading={<Heading />}
      products={TECHNICAL_BRIEFING_PRODUCTS}
      formType={TECHNICAL_BRIEFING_FORM_TYPE}
      productFamiliarity={false}
      selectCompanion={true}
      mutation={TechnicalBriefingSchema.DATA_MUTATION}
      validator={{
        age: [
          {
            rule: 'minNumber: 10',
            message: t('registration_error_age_under_ten_years_old'),
          },
        ],
      }}
    />
  );
}
