/* eslint-disable  */

import React, { lazy } from 'react';
import { connect } from 'react-redux';

export function extraFields() {
  return {
    adminDashboard: {
      tableFields: [
        {
          id: 'extraForm.de_pump_only_question',
          label: 'Experience with the therapy',
          minWidth: 170,
        },
        {
          id: 'extraForm.de_health_insurance_company',
          label: 'Health Insurance Companies',
          minWidth: 170,
        },
      ],
    },
  };
}
