// First card
// NAME- unique name to replace based on Representative

//------------------------------------------------------------------------------------------
// Virtual Group Pump Training
// Therapy: 'Virtual Group Pump Training'

//------------------------------------------------------------------------------------------
// Virtual or Face to face Pump Training NAME
// Therapy: 'Virtual or FF Pump Training'
// Region: 'Johannesburg'      //form.za_region,
// Representative: 'Martie'    //form.za_representative,

//------------------------------------------------------------------------------------------
// CGM & inPen Virtual Group Training
// Therapy: 'Standalone CGM and inPen'

// Second card
// X- hour
// Y- number, unique selection

// 1.  Pump, CGM, SmartGuard (Xh) Y

// Training: '640g' or '780g' depends on XML flow
// CGM Experience: 'Medtronic Sensor' or 'Other' Based on XML flow  the "Other" tag is a combo of No Experience and Other CGM
// Educator: 'Carina',         //form.za_educator
// Region: 'Johannesburg'      //form.za_region,
// Representative: 'Martie'    //form.za_representative,

// 2. Pump Academy (group session) + CGM Academy (group session) + Pump Only (Xh) Y + CGM & SmartGuard (Xh) Y
// The first 2 Groups session must be available on the selection on all of them Pump Experience: 'Other Pump' and the Pump Experience: 'No Experience'
// Pump Only and CGM & SmartGuard must become available after the 2 group sessions and are UNIQUE
// Pump Academy (group session) -> CGM Academy (group session) -> Pump Only (Xh) Y -> CGM & SmartGuard (Xh) Y

// Pump Experience: 'Other Pump' or Pump Experience: 'No Experience' selection are combined into Therapy: 'Pump Academy' !!!!!!
//------------------------------------------------------------------------------------------
// Pump Academy (group session):
// Therapy: 'Pump Academy'
// Training: '640g' or '780g' depends on XML flow

// -> CGM Academy (group session)
// Therapy: 'CGM Academy'
// Training: '640g' or '780g' depends on XML flow

// ->Pump Only (Xh) Y                  //These are unique based on selection
// Therapy: 'Pump Educator 640g' or 'Pump Educator 780g' depends on XML flow
// Educator: 'Carina',         //form.za_educator
// Region: 'Johannesburg'      //form.za_region,
// Representative: 'Martie'    //form.za_representative,

// ->CGM & SmartGuard (Xh) Y           //These are unique based on selection
// Therapy: 'CGM and SmartGuard Educator 640g' or 'CGM and SmartGuard Educator 780g' depends on XML flow
// Educator: 'Carina',         //form.za_educator
// Region: 'Johannesburg'      //form.za_region,
// Representative: 'Martie'    //form.za_representative,
//------------------------------------------------------------------------------------------
// Guardian Connect (1.5h)
// Pump Experience': 'Guardian Connect'

//------------------------------------------------------------------------------------------
// Guardian 4 system only (1.5h)
// Pump Experience': 'Guardian 4 system'

//------------------------------------------------------------------------------------------
// Guardian 4 system + inPen (1.5h)
// Pump Experience': 'Guardian 4 system and inPen'

//------------------------------------------------------------------------------------------
// InPen only (1h)
// Pump Experience': 'inPen'

const tagsExclusinCases = {
  tagPumpAcademyGroupSession: [
    {
      key: 'Therapy',
      value: 'Pump Academy',
    },
  ],
  tagCGMAcademyGroupSession: [
    {
      key: 'Therapy',
      value: 'CGM Academy',
    },
  ],
  tagPumpOnlyEducator640g: [
    {
      key: 'Therapy',
      value: 'Pump Educator 640g',
    },
  ],
  tagPumpOnlyEducator780g: [
    {
      key: 'Therapy',
      value: 'Pump Educator 780g',
    },
  ],
  tagCGMandSmartGuardEducator640g: [
    {
      key: 'Therapy',
      value: 'CGM and SmartGuard Educator 640g',
    },
  ],
  tagCGMandSmartGuardEducator780g: [
    {
      key: 'Therapy',
      value: 'CGM and SmartGuard Educator 780g',
    },
  ],
  tagMM640g670g780g: [
    {
      key: 'Pump Experience',
      value: '640g 670g 780g',
    },
  ],
  tagGuardianConnect: [
    {
      key: 'Pump Experience',
      value: 'Guardian Connect',
    },
  ],
  tagGuardian4SystemOnly: [
    {
      key: 'Pump Experience',
      value: 'Guardian 4 system',
    },
  ],
  tagGuardian4SystemandinPen: [
    {
      key: 'Pump Experience',
      value: 'Guardian 4 system and inPen',
    },
  ],
  taginPen: [
    {
      key: 'Pump Experience',
      value: 'inPen',
    },
  ],
  tagStandaloneCGMandinPen: [
    {
      key: 'Therapy',
      value: 'Standalone CGM and inPen',
    },
    {
      key: 'Training',
      value: 'One on One',
    },
  ],
  tagGroupCGMandinPen: [
    {
      key: 'Therapy',
      value: 'Standalone CGM and inPen',
    },
    {
      key: 'Training',
      value: 'Group',
    },
  ],
  tagVirtualGroupPumpTraining: [
    {
      key: 'Therapy',
      value: 'Pump Training',
    },
    {
      key: 'Training',
      value: 'Group',
    },
  ],
  tagVirtualOneOnOnePumpTraining: [
    {
      key: 'Therapy',
      value: 'Pump Training',
    },
    {
      key: 'Training',
      value: 'One on One',
    },
  ],
  tagVirtualorFFPumpTraining: [
    {
      key: 'Therapy',
      value: 'Virtual or FF Pump Training',
    },
  ],
};

export function getNextTag(tags, last_training_registered_to, initial_tags) {
  let objEducator = initial_tags.find((o) => o.key === 'Educator');
  let objRegion = initial_tags.find((o) => o.key === 'Region');
  let objRepresentative = initial_tags.find((o) => o.key === 'Representative');

  if (
    last_training_registered_to === null ||
    last_training_registered_to === undefined
  ) {
    //this is from 1st to 2nd tag
    if (
      initial_tags.some(
        (e) =>
          e.key === 'Pump Experience' &&
          (e.value === 'Other Pump' || e.value === 'No Experience')
      )
    ) {
      let objTraining = tags.find((o) => o.key === 'Training');

      return [
        ...tagsExclusinCases.tagCGMAcademyGroupSession,
        {
          key: objTraining.key,
          value: objTraining.value,
        },
      ];
    }
    else if (
      initial_tags.some(
        (e) =>
          e.key === 'Therapy' && e.value === 'Device training'
      ) &&
      tags.some(
        (e) => e.key === 'Therapy' && e.value === 'Pump Academy'
      )
    ) {
      return [
        ...tagsExclusinCases.tagCGMAcademyGroupSession
      ];
    } else {
      return [];
    }
  } else if (
    last_training_registered_to !== null ||
    last_training_registered_to !== undefined
  ) {
    //this is from 2st to 3rd tag
    if (
      initial_tags.some(
        (e) =>
          e.key === 'Pump Experience' &&
          (e.value === 'Other Pump' || e.value === 'No Experience')
      ) &&
      last_training_registered_to.some(
        (e) => e.key === 'Therapy' && e.value === 'CGM Academy'
      ) &&
      initial_tags.some((e) => e.key === 'Training' && e.value === '640g')
    ) {
      return [
        ...tagsExclusinCases.tagPumpOnlyEducator640g,
        objEducator,
        objRegion,
        objRepresentative,
      ];
    } else if (
      initial_tags.some(
        (e) =>
          e.key === 'Pump Experience' &&
          (e.value === 'Other Pump' || e.value === 'No Experience')
      ) &&
      last_training_registered_to.some(
        (e) => e.key === 'Therapy' && e.value === 'CGM Academy'
      ) &&
      initial_tags.some((e) => e.key === 'Training' && e.value === '780g')
    ) {
      return [
        ...tagsExclusinCases.tagPumpOnlyEducator780g,
        objEducator,
        objRegion,
        objRepresentative,
      ];
    } else if (
      //this is from 3rd to 4rd tag
      initial_tags.some(
        (e) =>
          e.key === 'Pump Experience' &&
          (e.value === 'Other Pump' || e.value === 'No Experience')
      ) &&
      last_training_registered_to.some(
        (e) => e.key === 'Therapy' && e.value === 'Pump Educator 640g'
      )
    ) {
      return [
        ...tagsExclusinCases.tagCGMandSmartGuardEducator640g,
        objEducator,
        objRegion,
        objRepresentative,
      ];
    } else if (
      initial_tags.some(
        (e) =>
          e.key === 'Pump Experience' &&
          (e.value === 'Other Pump' || e.value === 'No Experience')
      ) &&
      last_training_registered_to.some(
        (e) => e.key === 'Therapy' && e.value === 'Pump Educator 780g'
      )
    ) {
      return [
        ...tagsExclusinCases.tagCGMandSmartGuardEducator780g,
        objEducator,
        objRegion,
        objRepresentative,
      ];
    } else {
      return [];
    }
  } else {
    return [];
  }
}

const NlTagSystem = {
  'cvent.steps.boxes': {
    'cvent.cards.first_step.title': {
      key: 'Therapy',
      value: 'Demonstration',
    },
    'cvent.cards.second_step.title': {
      key: 'Therapy',
      value: 'Device training',
    },
  },
  'cvent.steps.za.za_representative': {
    'cvent.steps.za.insuling_pump_therapy': {
      key: 'Therapy',
      value: 'Insuline pump',
    },
    'cvent.steps.za.standalone_cgm_and_inpen': {
      key: 'Therapy',
      value: 'Standalone CGM and inPen',
    },
  },
  'cvent.steps.za.medtronic_pump_question': {
    'cvent.steps.cvent_select_group': {
      key: 'Training',
      value: 'Group',
    },
    'cvent.steps.cvent_select_one_to_one': {
      key: 'Training',
      value: 'One on One',
    },
  },
  'cvent.steps.za.cvent_secondbox_therapy': {
    'cvent.steps.za.cvent_insulim_pump_therapy': {
      key: 'Therapy',
      value: 'Insuline pump',
    },
    'cvent.steps.za.standalone_cgm_and_inpen': {
      key: 'Therapy',
      value: 'Cgm and inPen',
    },
  },
  'cvent.steps.za.cvent_secondbox_trained_on': {
    'cvent.steps.za.cvent_mm_640g': {
      key: 'Training',
      value: '640g',
    },
    'cvent.steps.za.cvent_mm_780g': {
      key: 'Training',
      value: '780g',
    },
  },
  'cvent.steps.za.cvent_secondbox_pump_experience': {
    'cvent.steps.za.cvent_mm_640g_670g_780g': {
      key: 'Pump Experience',
      value: '640g 670g 780g',
    },
    'cvent.steps.za.cvent_other_pump': {
      key: 'Pump Experience',
      value: 'Other Pump',
    },
    'cvent.steps.za.cvent_no_experience': {
      key: 'Pump Experience',
      value: 'No Experience',
    },
  },
  'cvent.steps.za.cvent_secondbox_sensor_experience': {
    'cvent.steps.za.cvent_medtronic_sensor': {
      key: 'CGM Experience',
      value: 'Medtronic Sensor',
    },
    'cvent.steps.za.cvent_other_cgm': {
      key: 'CGM Experience',
      value: 'Other',
    },
    'cvent.steps.za.cvent_no_experience_2': {
      key: 'CGM Experience',
      value: 'Other',
    },
  },
  'cvent.steps.za.cvent_secondbox_select_region': {
    'cvent.steps.za.cvent_johannesburg': {
      key: 'Region',
      value: 'Johannesburg',
    },
    'cvent.steps.za.cvent_pretoria': {
      key: 'Region',
      value: 'Pretoria',
    },
    'cvent.steps.za.cvent_western_cape': {
      key: 'Region',
      value: 'Western Cape',
    },
    'cvent.steps.za.cvent_kzn': {
      key: 'Region',
      value: 'KZN',
    },
    'cvent.steps.za.cvent_free_state': {
      key: 'Region',
      value: 'Free State',
    },
    'cvent.steps.za.cvent_eastern_cape': {
      key: 'Region',
      value: 'Eastern Cape',
    },
  },
  'cvent.steps.za.cvent_secondbox_trained_on_2': {
    'cvent.steps.za.cvent_guardian_connect': {
      key: 'Therapy',
      value: 'Guardian Connect',
    },
    'cvent.steps.za.cvent_guardian_4_system_only': {
      key: 'Therapy',
      value: 'Guardian 4 system',
    },
    'cvent.steps.za.cvent_guardian_4_system_and_inpen': {
      key: 'Therapy',
      value: 'Guardian 4 system and inPen',
    },
    'cvent.steps.za.cvent_inpen': {
      key: 'Therapy',
      value: 'inPen',
    },
  },
};
export function getInitialTags(summary, form) {
  const finalTags = [];

  const tagsForm = {
    tagFormTagComposed: [
      {
        key: 'Educator',
        value: form.za_educator,
      },
      {
        key: 'Region',
        value: form.za_region,
      },
      {
        key: 'Representative',
        value: form.za_sales_representative,
      },
    ],
  };
  for (const choice of summary) {
    if (NlTagSystem[choice.stepName]) {
      finalTags.push(NlTagSystem[choice.stepName][choice.option.title]);
    }
  }

  finalTags.push(...tagsForm.tagFormTagComposed);

  return finalTags;
}

export function flow(summary, form) {
  const finalTags = [];
  const finalTagsEdit = [];

  for (const choice of summary) {
    if (NlTagSystem[choice.stepName]) {
      finalTags.push(NlTagSystem[choice.stepName][choice.option.title]);
    }
  }
  if (
    finalTags.some((e) => e.key === 'Therapy' && e.value === 'Demonstration') //! This represent the first BOX
  ) {
    if (
      finalTags.some(
        (e) => e.key === 'Therapy' && e.value === 'Standalone CGM and inPen'
      )  &&
      finalTags.some((e) => e.key === 'Training' && e.value === 'One on One') 
    ) {
      finalTagsEdit.push(...tagsExclusinCases.tagStandaloneCGMandinPen);
    } else if (
      finalTags.some(
        (e) => e.key === 'Therapy' && e.value === 'Standalone CGM and inPen'
      ) &&
      finalTags.some((e) => e.key === 'Training' && e.value === 'Group') 
    ) {
      finalTagsEdit.push(...tagsExclusinCases.tagGroupCGMandinPen);
    } else if (
      finalTags.some(
        (e) => e.key === 'Therapy' && e.value === 'Insuline pump'
      ) &&
      finalTags.some((e) => e.key === 'Training' && e.value === 'Group') 
    ) {
      finalTagsEdit.push(...tagsExclusinCases.tagVirtualGroupPumpTraining);
    }   
    else if (
      finalTags.some(
        (e) => e.key === 'Therapy' && e.value === 'Insuline pump'
      ) &&
      finalTags.some((e) => e.key === 'Training' && e.value === 'One on One')
    ) {
      finalTagsEdit.push(...tagsExclusinCases.tagVirtualOneOnOnePumpTraining);
    }
  } else if (
    finalTags.some((e) => e.key === 'Therapy' && e.value === 'Device training') //! This represent the second BOX
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagPumpAcademyGroupSession);
    if (
      finalTags.some(
        (e) =>
          e.key === 'Pump Experience' &&
          (e.value === 'Other Pump' || e.value === 'No Experience')
      )
    ) {
      let objTraining = finalTags.find((o) => o.key === 'Training');

      finalTagsEdit.push(...tagsExclusinCases.tagPumpAcademyGroupSession, {
        key: objTraining.key,
        value: objTraining.value,
      });
    } else if (
      finalTags.some(
        (e) => e.key === 'Pump Experience' && e.value === '640g 670g 780g'
      )
    ) {
      let objTraining = finalTags.find((o) => o.key === 'Training');
      let objCGMExperience = finalTags.find((o) => o.key === 'CGM Experience');

      finalTagsEdit.push(
        {
          key: objTraining.key,
          value: objTraining.value,
        },
        {
          key: objCGMExperience.key,
          value: objCGMExperience.value,
        },
        {
          key: 'Educator',
          value: form.za_educator,
        },
        {
          key: 'Region',
          value: form.za_region,
        },
        {
          key: 'Representative',
          value: form.za_sales_representative,
        }
      );
    } else if (
      finalTags.some(
        (e) => e.key === 'Therapy' && e.value === 'Guardian Connect'
      )
    ) {
      finalTagsEdit.push(...tagsExclusinCases.tagGuardianConnect);
    } else if (
      finalTags.some(
        (e) => e.key === 'Therapy' && e.value === 'Guardian 4 system'
      )
    ) {
      finalTagsEdit.push(...tagsExclusinCases.tagGuardian4SystemOnly);
    } else if (
      finalTags.some(
        (e) => e.key === 'Therapy' && e.value === 'Guardian 4 system and inPen'
      )
    ) {
      finalTagsEdit.push(...tagsExclusinCases.tagGuardian4SystemandinPen);
    } else if (
      finalTags.some((e) => e.key === 'Therapy' && e.value === 'inPen')
    ) {
      finalTagsEdit.push(...tagsExclusinCases.taginPen);
    }
  }
  return finalTagsEdit;
}
