const tagsExclusinCases = {
  tagPumpTraining: [
    {
      key: 'Pump Experience',
      value: 'Other',
    },
  ],
  tagPumpOnly: [
    {
      key: 'Therapy',
      value: 'Pump Training',
    },
  ],
  tagCGMOnly: [
    {
      key: 'Therapy',
      value: 'CGM Only',
    },
  ],
  tagCGMToPump: [
    {
      key: 'Therapy',
      value: 'CGM Training',
    },
    {
      key: 'Therapy',
      value: 'SmartGuard Training',
    },
  ],
  tagShortenedPumpTraining: [
    { key: 'Therapy', value: 'Pump' },
    { key: 'Product', value: 'MM780G' },
  ],
  tagShortenedPumpTrainingPathway: [
    { key: 'Therapy', value: 'Pump & CGM' },
    { key: 'Product', value: 'MM780G' },
    { key: 'Pump Experience', value: 'MM640G' },
    { key: 'Sensor Experience', value: 'Other' },
  ],
  tagCGMTraining: [
    { key: 'Product', value: 'MM640G' },
    { key: 'Pump Experience', value: 'MM640G' },
    { key: 'Sensor Experience', value: 'Other' },
  ],
  // tagGuardianConnect: [
  //   {
  //     key: 'Therapy',
  //     value: 'Guardian Connect',
  //   },
  // ],
  tagSmartGuard: [
    {
      key: 'Therapy',
      value: 'SmartGuard',
    },
  ],
  tagSpecialMessage: [
    {
      key: 'Special Message',
      value: 'Empty Calendar',
    },
  ],
  tagUpgradeTraining670to780: [
    { key: 'Therapy', value: 'Pump & CGM' },
    { key: 'Product', value: 'MM780G' },
    { key: 'Pump Experience', value: 'MM670G' },
  ],
  tagSmartInPenPlusSimplera: [
    { key: 'Therapy', value: 'SMART InPen + Simplera' },
  ],
  // tagShortenedCGMTraining: [
  //   { key: 'Therapy', value: 'Pump & CGM' },
  //   { key: 'Product', value: 'MM780G' },
  //   { key: 'Pump Experience', value: 'MM640G' },
  //   { key: 'Sensor Experience', value: 'Experience MDT' },
  // ],
};

export function getNextTag(tags, last_training_registered_to, initial_tags) {
  let obj = initial_tags.find((o) => o.key === 'Hospital');

  if (
    last_training_registered_to === null ||
    last_training_registered_to === undefined
  ) {
    //this is from 1st to 2nd tag
    if (
      initial_tags.some(
        (e) => e.key === 'Therapy' && e.value === 'Pump & CGM'
      ) &&
      initial_tags.some(
        (e) =>
          e.key === 'Product' && (e.value === 'MM640G' || e.value === 'MM780G')
      ) &&
      initial_tags.some(
        (e) => e.key === 'Pump Experience' && e.value === 'Other'
      )
    ) {
      return [...tagsExclusinCases.tagCGMTraining, obj];
    } else if (
      initial_tags.some(
        (e) => e.key === 'Therapy' && e.value === 'Pump & CGM'
      ) &&
      initial_tags.some((e) => e.key === 'Product' && e.value === 'MM780G') &&
      initial_tags.some(
        (e) => e.key === 'Pump Experience' && e.value === 'MM640G'
      )
      // && initial_tags.some( e => e.key === 'Sensor Experience' && e.value === 'Other')
    ) {
      return [...tagsExclusinCases.tagCGMTraining, obj];
      // } else if (
      //   initial_tags.some(e => e.key === 'Therapy' && e.value === 'Pump & CGM') &&
      //   initial_tags.some(e => e.key === 'Product' && e.value === 'MM780G') &&
      //   initial_tags.some(
      //     e => e.key === 'Pump Experience' && e.value === 'MM640G'
      //   ) &&
      //   initial_tags.some(
      //     e => e.key === 'Sensor Experience' && e.value === 'Experience MDT'
      //   )
      // ) {
      //   return [...tagsExclusinCases.tagShortenedCGMTraining, obj];
    } else if (
      initial_tags.some(
        (e) => e.key === 'Therapy' && e.value === 'PUMP & CGM'
      ) &&
      initial_tags.some((e) => e.key === 'Product' && e.value === 'MM740G') &&
      tags.some((e) => e.key === 'Therapy' && e.value === 'Pump Training')
    ) {
      return [...tagsExclusinCases.tagCGMOnly, obj];
    } else if (
      initial_tags.some(
        (e) => e.key === 'Therapy' && e.value === 'PUMP & CGM'
      ) &&
      initial_tags.some((e) => e.key === 'Product' && e.value === 'MM780G') &&
      tags.some((e) => e.key === 'Therapy' && e.value === 'Pump Training')
    ) {
      return [...tagsExclusinCases.tagCGMToPump, obj];
    } else {
      return [];
    }
  } else if (
    last_training_registered_to !== null ||
    last_training_registered_to !== undefined
  ) {
    //this is from 2st to 3rd tag
    if (
      initial_tags.some(
        (e) => e.key === 'Therapy' && e.value === 'Pump & CGM'
      ) &&
      initial_tags.some(
        (e) => e.key === 'Product' && e.value === 'MM780G'
        // e.key === 'Product' && (e.value === 'MM780G' || e.value === 'MM640G')
      ) &&
      initial_tags.some(
        (e) =>
          e.key === 'Pump Experience' &&
          (e.value === 'Other' || e.value === 'MM640G')
      ) &&
      last_training_registered_to.some(
        (e) =>
          e.key === 'Sensor Experience' &&
          (e.value === 'Other' || e.value === 'Experience MDT')
      )
    ) {
      return [...tagsExclusinCases.tagSmartGuard, obj];
    } else {
      return [];
    }
  } else {
    return [];
  }
}

const NlTagSystem = {
  'cvent.steps.boxes': {
    'cvent.cards.first_step.title': {
      key: '',
      value: '',
    },
    'cvent.cards.fourth_step.title': {
      key: '',
      value: '',
    },
    'cvent.cards.sixth_step.title': {
      key: 'Therapy',
      value: 'Pump & CGM',
    },
    'cvent.cards.seventh_step.title': {
      key: 'Therapy',
      value: 'SMART InPen + Simplera',
    },
  },
  'cvent.steps.nl.entry_level_title': {
    'cvent.steps.nl.pump_only': {
      key: 'Therapy',
      value: 'Pump',
    },
    'cvent.steps.nl.pump_only_2': {
      key: 'Therapy',
      value: 'Pump Only',
    },
    'cvent.steps.nl.pump_and_cgm': {
      key: 'Therapy',
      value: 'Pump & CGM',
    },
    'cvent.steps.nl.pump_and_cgm_2': {
      key: 'Therapy',
      value: 'PUMP & CGM',
    },
    'cvent.steps.nl.cgm_for_pump': {
      key: 'Therapy',
      value: 'CGM',
    },
    'cvent.steps.nl.cgm_for_pump_2': {
      key: 'Therapy',
      value: 'CGM to Pump',
    },
    'cvent.steps.nl.guardian_connect': {
      key: 'Therapy',
      value: 'Guardian Connect',
    },
  },
  'cvent.steps.nl.select_product': {
    '640g': {
      key: 'Product',
      value: 'MM640G',
    },
    '740g': {
      key: 'Product',
      value: 'MM740G',
    },
    '740g_cgm': {
      key: 'Product',
      value: 'MM740G',
    },
    '740g_cgm_pump': {
      key: 'Product',
      value: 'MM740G',
    },
    '780g': {
      key: 'Product',
      value: 'MM780G',
    },
    '780g_cgm': {
      key: 'Product',
      value: 'MM780G',
    },
    '780g_cgm_pump': {
      key: 'Product',
      value: 'MM780G',
    },
  },
  'cvent.steps.nl.select_therapy': {
    'cvent.steps.nl.veo': {
      key: 'Pump Experience',
      value: 'Other',
    },
    '640g': {
      key: 'Pump Experience',
      value: 'MM640G',
    },
    '670g': {
      key: 'Pump Experience',
      value: 'MM670G',
    },
    'cvent.steps.nl.other': {
      key: 'Pump Experience',
      value: 'Other',
    },
    'cvent.steps.nl.no_experience': {
      key: 'Pump Experience',
      value: 'Other',
    },
  },
  'cvent.steps.nl.select_therapy_sensor': {
    'cvent.steps.nl.no_experience': {
      key: 'Sensor Experience',
      value: 'Other',
    },
    'cvent.steps.nl.experience_with_mdt': {
      key: 'Sensor Experience',
      value: 'Experience MDT',
    },
    'cvent.steps.nl.experience_with_other': {
      key: 'Sensor Experience',
      value: 'Other',
    },
  },
};
export function getInitialTags(summary, form) {
  const finalTags = [];

  for (const choice of summary) {
    if (NlTagSystem[choice.stepName]) {
      finalTags.push(NlTagSystem[choice.stepName][choice.option.title]);
    }
  }

  finalTags.push({ key: 'Hospital', value: form.hospital });

  return finalTags;
}

export function flow(summary, form) {
  const finalTags = [];
  const finalTagsEdit = [];

  for (const choice of summary) {
    if (NlTagSystem[choice.stepName]) {
      finalTags.push(NlTagSystem[choice.stepName][choice.option.title]);
    }
  }

  if (
    finalTags.some((e) => e.key === 'Therapy' && e.value === 'Pump & CGM') //! This represent the third BOX
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagUpgradeTraining670to780);
  }

  if (
    finalTags.some(
      (e) => e.key === 'Therapy' && e.value === 'SMART InPen + Simplera'
    )
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagSmartInPenPlusSimplera);
  }

  if (
    finalTags.some((e) => e.key === 'Therapy' && e.value === 'Pump') &&
    finalTags.some((e) => e.key === 'Product' && e.value === 'MM780G') &&
    finalTags.some(
      (e) =>
        e.key === 'Pump Experience' &&
        (e.value === 'MM640G' || e.value === 'MM670G')
    )
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagShortenedPumpTraining);
  } else if (
    finalTags.some((e) => e.key === 'Therapy' && e.value === 'Pump & CGM') &&
    finalTags.some((e) => e.key === 'Product' && e.value === 'MM780G') &&
    finalTags.some((e) => e.key === 'Pump Experience' && e.value === 'MM640G')
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagShortenedPumpTrainingPathway);
  } else if (
    finalTags.some(
      (e) =>
        e.key === 'Therapy' && (e.value === 'Pump & CGM' || e.value === 'CGM')
    ) &&
    finalTags.some((e) => e.key === 'Product' && e.value === 'MM640G') &&
    finalTags.some(
      (e) => e.key === 'Pump Experience' && e.value === 'MM640G'
    ) &&
    finalTags.some((e) => e.key === 'Sensor Experience' && e.value === 'Other')
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagCGMTraining);
    // } else if (
    //   finalTags.some(e => e.key === 'Therapy' && e.value === 'Guardian Connect')
    // ) {
    //   finalTagsEdit.push(...tagsExclusinCases.tagGuardianConnect);
  } else if (
    finalTags.some(
      (e) =>
        e.key === 'Therapy' && (e.value === 'Pump' || e.value === 'Pump & CGM')
    ) &&
    finalTags.some((e) => e.key === 'Pump Experience' && e.value === 'Other')
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagPumpTraining);
  } else if (
    finalTags.some((e) => e.key === 'Therapy' && e.value === 'Pump Only')
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagPumpOnly);
  } else if (
    finalTags.some((e) => e.key === 'Therapy' && e.value === 'Pump & CGM') &&
    finalTags.some((e) => e.key === 'Product' && e.value === 'MM780G') &&
    finalTags.some((e) => e.key === 'Pump Experience' && e.value === 'MM670G')
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagUpgradeTraining670to780);
  } else if (
    finalTags.some((e) => e.key === 'Therapy' && e.value === 'CGM to Pump') &&
    finalTags.some((e) => e.key === 'Product' && e.value === 'MM780G')
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagCGMToPump);
  } else if (
    finalTags.some((e) => e.key === 'Therapy' && e.value === 'CGM to Pump') &&
    finalTags.some((e) => e.key === 'Product' && e.value === 'MM740G')
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagCGMOnly);
  } else if (
    finalTags.some((e) => e.key === 'Therapy' && e.value === 'PUMP & CGM')
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagPumpOnly);
  } else if (
    finalTags.some((e) => e.key !== 'Therapy' && e.value !== 'Pump & CGM')
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tagSpecialMessage);
  }

  // finalTagsEdit.push({ key: 'Hospital', value: form.hospital });
  if (
    finalTags.some((e) => e.key === 'Therapy' && e.value === 'Pump & CGM') &&
    finalTags.some((e) => e.key === 'Product' && e.value === 'MM780G') &&
    finalTags.some((e) => e.key === 'Pump Experience' && e.value === 'MM670G')
  ) {
    // For this training we don't need an hospital, the tag of the hospital should be removed -> Pump & CGM -> MM780G -> MM670G -> ...
  } else if (
    finalTags.some((e) => e.key !== 'Therapy' && e.value !== 'Pump & CGM')
  ) {
    finalTagsEdit.push({ key: 'Hospital', value: form.hospital });
  }

  return finalTagsEdit;
}
