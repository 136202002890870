/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FLOW } from '../../constants/cvent';
import BubbleFlow from '../ui/medtronic/BubbleFlow';

function StepCrumbs({ currentStep, className }) {
  const { t } = useTranslation();
  const steps = [
    t('cvent.steps.cvent_entry_level'),
    t('cvent.steps.cvent_product_and_therapy'),
    t('cvent.steps.cvent_finish'),
  ];

  const findStepCategory = () => {
    return FLOW[currentStep.stepCategory];
  };

  return (
    <div className={`flex justify-center ${className}`}>
      <div className='flex'>
        {steps.map((label, index) => {
          return (
            <div key={index} className='flex'>
              {index > 0 && (
                <div
                  className={`mt-9 lg:block items-center text-center z-0 md:w-20 lg:w-30`}
                  style={{
                    borderTop: '5px dotted #170f5f',
                  }}
                ></div>
              )}
              <div className='flex flex-col items-center justify-start'>
                <BubbleFlow
                  active={findStepCategory() >= index ? true : false}
                  className='hidden md:flex '
                >
                  {index + 1}
                </BubbleFlow>
                <p
                  className={`md:w-16 p-2 text-xs uppercase font-bold ${
                    findStepCategory() >= index
                      ? 'text-navy-blue'
                      : 'text-pale-gray'
                  } md:text-sm`}
                >
                  {label}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

StepCrumbs.propTypes = {
  currentStep: PropTypes.object,
  className: PropTypes.string,
};

StepCrumbs.defaultProps = {
  currentStep: {},
};

export default StepCrumbs;
