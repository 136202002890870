import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';

export default function RBCToolbar(props) {
  const [viewState, setViewState] = useState('month');
  let label = props.label;
  const { t } = useTranslation();

  const goToMapView = () => {
    props.onView('map');
    setViewState('map');
  };
  const goToWeekView = () => {
    props.onView('week');
    setViewState('week');
  };
  const goToMonthView = () => {
    props.onView('month');
    setViewState('month');
  };
  const goToAgendaView = () => {
    props.onView('agenda');
    setViewState('agenda');
  };

  const goToBackMonth = () => {
    let view = viewState;
    let mDate = props.date;
    let newDate;
    if (view === 'month') {
      newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
    } else if (view === 'week') {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth(),
        mDate.getDate() - 7,
        1
      );
    } else {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth(),
        mDate.getDate() - 1,
        1
      );
    }
    props.onNavigate('prev', newDate);
  };
  const goToNextMonth = () => {
    let view = viewState;
    let mDate = props.date;
    let newDate;
    if (view === 'month') {
      newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
    } else if (view === 'week') {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth(),
        mDate.getDate() + 7,
        1
      );
    } else {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth(),
        mDate.getDate() + 1,
        1
      );
    }
    props.onNavigate('next', newDate);
  };

  const goToToday = () => {
    const now = new Date();
    props.date.setMonth(now.getMonth());
    props.date.setYear(now.getFullYear());
    props.date.setDate(now.getDate());
    props.onNavigate('current');
  };

  return (
    <div className='flex grid justify-center grid-cols-1 gap-1 rbc-toolbar'>
      <div className='rbc-btn-group'>
        <span className='text-4xl capitalize rbc-toolbar-label'>{label}</span>
      </div>

      <div className='flex grid justify-center grid-flow-col gap-1 rbc-btn-group auto-cols-max'>
        <button className='bg-electric-blue' onClick={goToBackMonth}>
          <ArrowBackIosSharpIcon style={{ color: 'rgb(16, 16, 235)' }} />
        </button>

        <button className='' onClick={goToToday}>
          <span className='label-filter-off'>{t('global.today')}</span>
        </button>

        <button className='' onClick={goToNextMonth}>
          <ArrowForwardIosSharpIcon style={{ color: 'rgb(16, 16, 235)' }} />
        </button>
      </div>

      <span className='flex grid grid-flow-col gap-1 rbc-btn-group auto-cols-max xs:justify-center md:justify-end'>
        <button className='' onClick={goToMonthView}>
          <span className='label-filter-off'>{t('calendar.month_view')}</span>
        </button>

        <button className='' onClick={goToWeekView}>
          <span className='label-filter-off'>{t('calendar.week_view')}</span>
        </button>
        <button className='' onClick={goToAgendaView}>
          <span className='label-filter-off'>{t('calendar.agenda_view')}</span>
        </button>
        <button className='' onClick={goToMapView}>
          <span className='label-filter-off'>{t('calendar.map_view')}</span>
        </button>
      </span>
    </div>
  );
}

RBCToolbar.propTypes = {
  date: PropTypes.any,
  onView: PropTypes.any,
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  messages: PropTypes.object,
  onLeftMenu: PropTypes.func,
  onNavigate: PropTypes.func.isRequired,
  onViewChange: PropTypes.func,
};
