import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Layout from '../../forms/Layout';
import Label from '../../label/Label';
import routes from '../../../helpers/routes';
import Text from '../../forms/inputs/Text';
import Button from '../../button/Button';
import { addTags } from '../../../actions/tags';

const mapDispatchToProps = (dispatch) => ({
  dispatchTags: (tags) => dispatch(addTags(tags)),
});

export function SingleInputSelection({
  handleBack,
  stepName,
  stepDescription,
  dispatchTags,
  history,
}) {
  const [eventCode, setEventCode] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = () => {
    setLoading(true);

    const finalTag = [
      {
        key: 'Event Code',
        value: eventCode ? eventCode : 'WORKSHOP',
      },
    ];

    dispatchTags(finalTag);
    history.push(routes.calendar);
  };

  return (
    <Layout
      handleBack={handleBack}
      heading={
        <div className='mb-5'>
          <Label
            label={t(stepName)}
            variant='h1'
            className='text-2xl font-bold leading-tight uppercase text-electric-blue'
          />
          <Label
            label={t(stepDescription)}
            className='text-base leading-tight'
          />
        </div>
      }
    >
      <ValidatorForm onSubmit={(e) => e.preventDefault()} className='p-8'>
        <Text
          placeholder={t('cvent.technical.workshop.event_code')}
          name='eventCode'
          disabled={loading}
          value={eventCode}
          onChange={(e) => setEventCode(e)}
        />

        <div className='flex justify-end mt-4'>
          <Button
            type='submit'
            disabled={loading}
            onClick={handleSubmit}
            className='px-4 rounded-full text-dark-grey-body '
          >
            {t('global.next')}
          </Button>
        </div>
      </ValidatorForm>
    </Layout>
  );
}

SingleInputSelection.propTypes = {
  handleBack: PropTypes.func,
  stepName: PropTypes.string,
  stepDescription: PropTypes.string,
  dispatchTags: PropTypes.func,
  history: PropTypes.object,
  data: PropTypes.object,
};

SingleInputSelection.defaultProps = {
  data: {},
};
export default connect(null, mapDispatchToProps)(SingleInputSelection);
