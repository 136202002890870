/* eslint-disable  */

import React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import User from '../schema/User';
import Countries from '../schema/Countries';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const mapStateToProps = (state) => {
  return {
    loggedInDashboard: state.userDashboardReducer.loggedIn,
    userDashboard: state.userDashboardReducer.user,
  };
};

export function AdministrateUsersDashboard(props) {
  const { t } = useTranslation();
  const [countriesData, setcountriesData] = useState([]);

  const [deleteUserAccount] = useMutation(User.DELETE_ACCOUNT_DASHBOARD);
  const [deleteAccountWarn, setDeleteAccountWarn] = useState(false);
  const [editedRow, setEditedRow] = useState({});

  async function generateWarnandStore(editedRowSelected) {
    setEditedRow(editedRowSelected);
    setDeleteAccountWarn(true);
  }

  const handleCloseDeletionWarn = (e) => {
    e.preventDefault();
    setDeleteAccountWarn(false);
  };

  function getCountryNameByID(country_id) {
    var findCountryObj = countriesData?.find((e) => e?._id === country_id);
    return findCountryObj?.name ? findCountryObj?.name : country_id;
  }

  const [getCoutryes] = useLazyQuery(Countries.GET_COUNTRIES, {
    onCompleted: (data) => {
      setcountriesData(data?.countries);
    },
    onError: (e) => {
      console.log('Error: ' + JSON.stringify(e));
      toast.error('Getting country has failed, will display only ID', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    },
  });

  const { data, loading } = useQuery(User.GET_USERS_DASHBOARD);

  const columns = [
    { id: 'username', label: 'Email', minWidth: 170 },
    { id: 'role', label: 'Role', minWidth: 170 },
    { id: 'country_id', label: 'Country ID', minWidth: 170 },
    {
      id: 'verified',
      label: 'Verified',
      minWidth: 170,
    },
    {
      id: 'name',
      label: 'Delete',
      minWidth: 170,
    },
  ];

  const [eventsData, setEventsData] = useState([]);
  const [updateDataUsersVerified] = useMutation(
    User.UPDATE_USERS_ADMINISTRATION
  );

  const [
    getUser,
    {
      data: event_data,
      loading: event_loading,
      error: event_error,
      fetchMore: event_fertchMore,
    },
  ] = useLazyQuery(User.GET_USERS_DASHBOARD, {
    onCompleted: (data) => {
      setEventsData(data.getUsersDashboardData);
    },
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(async () => {
    getUser();
    getCoutryes();

    if (event_loading) {
      setEventsData([]);
    }

    if (event_error) {
      toast.warn('Error has occured', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  }, []);

  const getLatestUpdates = async () => {
    getUser();
  };

  const accountEnaandDis = async (value) => {
    try {
      const res = await updateDataUsersVerified({
        variables: {
          input: {
            id: value.id,
            username: value.username,
          },
        },
      });

      if (res && res.data) {
        getLatestUpdates();

        toast.success('Update was successful', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (e) {
      toast.error(`'Error: '+ ${JSON.stringify(e)}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const deleteAccount = async (value) => {
    try {
      const res = await deleteUserAccount({
        variables: {
          input: {
            email: editedRow.username,
          },
        },
      });

      if (res && res.data) {
        getLatestUpdates();

        toast.success(
          `The account ${editedRow.username} was successfully deleted`,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          }
        );
        setDeleteAccountWarn(false);
      }
    } catch (e) {
      toast.error(e, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  if (loading || !data) {
    return <></>;
  }

  return (
    <div>
      <ToastContainer />

      {/* <div className='grid grid-cols-4 gap-2 place-content-center'>
        <div className='grid place-content-center'>
          <Button
            variant='contained'
            color='primary'
            onClick={getLatestUpdates}
          >
            <RefreshIcon />
          </Button>
        </div>
      </div> */}

      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {eventsData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        // var editedRow = { ...row, idInc: index + 1 };
                        var editedRow = { ...row };
                        let value;

                        if (column.id.indexOf('.') > -1) {
                          //! V2 ES6
                          const getObjectValueForPath = (obj, path) =>
                            path
                              .split('.')
                              .reduce((a, v) => (a ? a[v] : undefined), obj);

                          value = getObjectValueForPath(editedRow, column.id);
                        } else {
                          value = editedRow[column.id];
                        }

                        if (column.id === 'verified') {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Button
                                variant='contained'
                                color='primary'
                                onClick={() => accountEnaandDis(editedRow)}
                              >
                                {value == 1 ? 'Enabled' : 'Disabled'}
                              </Button>
                            </TableCell>
                          );
                        }
                        if (column.id === 'name') {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Button
                                variant='contained'
                                style={{
                                  backgroundColor: '#FF0000',
                                }}
                                onClick={() => generateWarnandStore(editedRow)}
                              >
                                {'Delete'}
                              </Button>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === 'country_id'
                              ? getCountryNameByID(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={eventsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog
        open={deleteAccountWarn}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDeletionWarn}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {'This will delete the account!'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {'Are you sure you want to delete the following account: ' +
              editedRow.username}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeletionWarn} color='primary'>
            Disagree
          </Button>
          <Button onClick={deleteAccount} color='primary'>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default connect(mapStateToProps, null)(AdministrateUsersDashboard);
