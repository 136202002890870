/* eslint-disable no-unused-vars */
import React, { lazy, Suspense, useMemo } from 'react';
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import Button from '../button/Button';
import Snackbar from '../header/SnackbarOpenOption';
import Text from '../forms/inputs/Text';
import TextArea from '../forms/inputs/TextArea';
import Event from '../../schema/Event';
import routes from '../../helpers/routes';
import { LoadingFullScreenCentered } from '../loading-indicator/Loading';
import Layout from '../forms/Layout';
import FormInformation from '../forms/FormInformation';
import Dialog from '../dialog/Dialog';
import RegisterFollowUp from '../calendar/follow-up-modal/RegisterFollowUp';
import { setPathway } from '../../actions/pathway';
import { store } from '../../store/redux';
import { ES_PROVINCE_AND_HOSPITAL } from '../../constants/form';

import {
  addTags,
  lastTrainRG,
  initialTags,
  setLastEventReg,
} from '../../actions/tags';

const mapDispatchToProps = (dispatch) => ({
  dispatchTags: (tags) => dispatch(addTags(tags)),
  dispatchlastTrainrg: (last_trainrg) => dispatch(lastTrainRG(last_trainrg)),
  dispatchPathway: (pathway) => dispatch(setPathway(pathway)),
  dispatchIntialTag: (initial_tags) => dispatch(initialTags(initial_tags)),
  dispatchLastEventReg: (last_event) => dispatch(setLastEventReg(last_event)),
});

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    pathway: state.pathwayReducer.pathway,
    box: state.pathwayReducer.box,
    tags: state.tagsReducer.tags,
    last_trainrg: state.tagsReducer.last_trainrg,
    initial_tags: state.tagsReducer.initial_tags,
    def_flow: state.tagsReducer.def_flow,
  };
};

const COMPANION_QUANTITY_ARRAY = [
  { id: 0, label: '0' },
  { id: 1, label: '1' },
  { id: 2, label: '2' },
  { id: 3, label: '3' },
  { id: 4, label: '4' },
];

let name = 'DEFAULT.js';

//! do not refactor this with `./countryesCustomRegisterAttendee/${name}` it will cause sometime error range null...
let ExtraFieldsRegisterAtendee;

const importModule = () => {
  return import('./countryesCustomRegisterAttendee/' + name).catch((error) => {
    return import('./countryesCustomRegisterAttendee/DEFAULT.js');
  });
};
ExtraFieldsRegisterAtendee = React.lazy(importModule);

export function RegisterAttendee({
  computedMatch,
  pathway,
  dispatchPathway,
  box,
  user,
  submit,
  showFeedback,
  // eslint-disable-next-line no-unused-vars
  handleCloseDialog,
  isDisabled,
  openSnackbar,
  snackbarText,
  severity,
  setOpenSnackbar,
  tags,
  last_trainrg,
  dispatchTags,
  dispatchlastTrainrg,
  dispatchLastEventReg,
  initial_tags,
  def_flow,
}) {
  name = user.country?.alpha2;

  const { t } = useTranslation();
  const history = useHistory();
  //! CheckValidator
  // const [checked, setChecked] = useState(false);
  const [event, setEvent] = useState();
  const id = computedMatch.params.id;
  const [form, setForm] = useState({});
  const [showPathwayFeedback, setShowPathwayFeedback] = useState(false);
  const [isLastInPathway, setIsLastInPathway] = useState(false);
  const [isFakePathway, setIsFakePathway] = useState(false);

  const [open, setSnackBarState] = useState(true);

  const [uk_hospital_selected, setUk_hospital_selected] = useState(0);
  const { data: eventData, loading } = useQuery(Event.GET_SINGLE_EVENT, {
    variables: { id },
  });

  useEffect(() => {
    ValidatorForm.addValidationRule('isTruthy', (value) => value);
    ValidatorForm.addValidationRule('isAutocompleteDiff', (value) => {
      if (uk_hospital_selected != 0) {
        return true;
      }
      return false;
    });
    ValidatorForm.addValidationRule('isDefaultValue', (value) => {
      if (value === 'defaultValue') {
        return false;
      }
      return true;
    });
  }, [eventData, user, uk_hospital_selected]);

  useEffect(() => {
    const data = {
      id: id,
      email: user.username,
      name: '',
      age: '',
      date_of_birth: '',
      guests: '',
      zip: '',
      phone: '',
      message: '',
      eventTitle: '',
      eventDate: '',
      eventLocation: '',
      formTitle: '',
      organizerEmail: '',
      organizerEmailList: '',
      il_doctors_name: '',
      il_diabetes_clinic: '',
      il_city_of_residence: '',
      at_center_treating_doctor: '',
      de_pump_only_question: '',
      de_cgm_only_question: '',
      de_health_insurance_company: '',
      es_province: '',
      es_hospital: '',
      es_hospital_not_in_list_optional: '',
      es_companion_name: '',
      uk_pronouns_qestion: '',
      uk_hospital: '',
      uk_healthcare_professional: '',
      uk_hospital_not_in_list_optional: '',
      fi_treatment_place: '',
      fi_pump_number: '',
      fi_patient_group: '',
      fi_nurse: '',
      nl_hospital: '',
      za_whos_your_doctor: '',
      za_sales_representative: '',
      za_region: '',
      za_experience: '',
      za_requested_date: '',
      za_organizer: '',
      za_organizer_email: '',
      za_representative_email: '',
    };

    if (eventData && eventData.event) {
      const eventDate = moment
        .unix(eventData.event.start)
        .format('MMMM Do YYYY, h:mm A');
      data.eventDate = eventDate;
      setEvent(eventData.event);
      setIsLastInPathway(eventData.event?.pathwayDescendants?.length === 0);
    }

    setForm(data);
  }, [eventData, user]);

  //! CheckValidator
  // const handleCheck = () => {
  //   setChecked(!checked);
  // };

  const toCalendar = useCallback(() => {
    comboTo();

    history.replace(routes.calendar);
  }, [history]);

  // const alpha2 = store.getState().userReducer.user
  //   ? store.getState().userReducer.user
  //   : { country: { alpha2: 'FAIL' } };

  const toCalendarOnly = useCallback(() => {
    history.replace(routes.calendar);
  }, [history]);

  const toHome = useCallback(() => {
    comboTo();
    history.replace(routes.my_events);
  }, [history]);

  function comboTo() {
    if (
      severity !== 'warning' &&
      open === true &&
      (user.country.alpha2 === 'NL' ||
        user.country.alpha2 === 'GB' ||
        user.country.alpha2 === 'ZA') &&
      require(`../steps/flow/${user.country.alpha2.toLowerCase()}`).getNextTag(
        tags,
        last_trainrg,
        initial_tags
      ).length === 0
    ) {
      dispatchlastTrainrg(null);
      dispatchTags(def_flow);
    }
  }

  const toMyEvents = useCallback(() => {
    if (
      severity !== 'warning' &&
      open === true &&
      (user.country.alpha2 === 'NL' ||
        user.country.alpha2 === 'GB' ||
        user.country.alpha2 === 'ZA') &&
      require(`../steps/flow/${user.country.alpha2.toLowerCase()}`).getNextTag(
        tags,
        last_trainrg,
        initial_tags
      ).length === 0
    ) {
      dispatchlastTrainrg(null);
      dispatchTags(def_flow);
    }

    history.replace(routes.my_events);
  }, [history]);

  function handleFormInput(key, value) {
    setForm({ ...form, [key]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    await submit({ ...form, formTitle: t(box.title) }, event);
  }

  function handleSubmitPathway(e) {
    e.preventDefault();
    combohandleSubmit(e);
    toCalendar();
  }

  function handleSubmitHome(e) {
    e.preventDefault();
    combohandleSubmit(e);
    toHome();
  }

  function combohandleSubmit(e) {
    e.preventDefault();
    dispatchPathway(null);
    if (!isLastInPathway && !isFakePathway) {
      dispatchPathway({
        id: event.pathwayItem.pathway.id,
        events: event.pathway.events,
        descendants: event.pathwayDescendants,
        eventsAttending:
          pathway && pathway.eventsAttending ? pathway.eventsAttending++ : 1,
      });
    }
    dispatchLastEventReg(eventData);
  }

  function ukHospitalSelected(fromInput, value) {
    var hospital = value;
    hospital === null
      ? setUk_hospital_selected(0)
      : setUk_hospital_selected(hospital.value);

    if (hospital?.value !== 'not_in_list' && hospital !== null) {
      //! Hospital selecton from list
      form.uk_hospital = hospital.name;
      form.uk_hospital_not_in_list_optional = '';
    } else {
      //! Custom hospital from input
      form.uk_hospital = '';
    }
  }

  const handleFilter = (options, state) => {
    let newOptions = [];
    options.forEach((element) => {
      if (element.name.toLowerCase().includes(state.inputValue.toLowerCase())) {
        newOptions.push(element);
      }
    });
    if (newOptions.length < 5) {
      return options.filter((item) => {
        return item.value.toLowerCase().startsWith('not_in_list');
      });
    }

    return newOptions;
  };

  function checkIfMother() {
    if (event?.pathwayItem && severity !== 'warning' && open === true) {
      setShowPathwayFeedback(true);
      setSnackBarState(false);
    } else if (
      !event?.pathwayItem &&
      open === true &&
      (user.country.alpha2 === 'NL' ||
        user.country.alpha2 === 'GB' ||
        user.country.alpha2 === 'ZA') &&
      require(`../steps/flow/${user.country.alpha2.toLowerCase()}`).getNextTag(
        tags,
        last_trainrg,
        initial_tags
      ).length > 0
    ) {
      const storeTags = tags;
      const storelast_trainrg = last_trainrg;
      const storeinitial_tags = initial_tags;

      setIsFakePathway(true); //condition bypass
      setIsLastInPathway(false); // false for other to appear / true to show there is no more pathway
      setShowPathwayFeedback(true); //for dialog to appear

      const doMagicTags =
        require(`../steps/flow/${user.country.alpha2.toLowerCase()}`).getNextTag(
          storeTags,
          storelast_trainrg,
          storeinitial_tags
        );
      setSnackBarState(false);

      dispatchTags(doMagicTags);
      dispatchlastTrainrg(doMagicTags);
    }
  }

  if (loading) {
    return <LoadingFullScreenCentered />;
  }

  return (
    <Layout
      handleBack={toCalendarOnly}
      heading={
        <div>
          {event && (
            <FormInformation
              title={event.title}
              description={t('register.attendee.event_register_disclaimer')}
              required={t(
                'register.attendee.event_register_asteriks_disclaimer'
              )}
            />
          )}
        </div>
      }
    >
      {showFeedback && showPathwayFeedback === false && (
        <Dialog
          className='justify-center'
          open={showFeedback}
          handleClose={toHome}
          render={
            <RegisterFollowUp
              text={t('register.attendee.follow_up')}
              textBtn={t('register.attendee.continue')}
              handleNext={toHome}
              handleBack={toMyEvents}
            />
          }
        />
      )}
      {showPathwayFeedback && (
        <Dialog
          className='justify-center'
          open={showPathwayFeedback}
          handleClose={handleSubmitPathway}
          render={
            <RegisterFollowUp
              text={
                isLastInPathway
                  ? t('register.attendee.pathway_final')
                  : t('register.attendee.pathway_follow_up')
              }
              textBtn={
                isLastInPathway
                  ? t('register.attendee.continue')
                  : t('register.attendee.continue_registration')
              }
              handleNext={
                isLastInPathway ? handleSubmitHome : handleSubmitPathway
              }
            />
          }
        />
      )}
      <ValidatorForm
        onSubmit={handleSubmit}
        instantValidate={false}
        noValidate
        className='p-8'
      >
        <Text
          validators={['required']}
          errorMessages={[t('registration_error_required')]}
          placeholder={t('register.attendee.full_name')}
          value={form.name}
          name='name'
          onChange={(value) => handleFormInput('name', value)}
          disabled={isDisabled}
        />

        <Suspense fallback={<div>Loading...</div>}>
          <ExtraFieldsRegisterAtendee
            form={form}
            setForm={setForm}
            tags={tags}
            handleFormInput={handleFormInput}
            loading={loading}
            // esHospitalandProvinceSelected={esHospitalandProvinceSelected}
            COMPANION_QUANTITY_ARRAY={COMPANION_QUANTITY_ARRAY}
            // es_hospital_selected={es_hospital_selected}
            isDisabled={isDisabled}
            uk_hospital_selected={uk_hospital_selected}
            handleFilter={handleFilter}
            ukHospitalSelected={ukHospitalSelected}
            store={store}
          />
        </Suspense>
        <TextArea
          placeholder={t('register.attendee.message')}
          value={form.message}
          name='message'
          onChange={(value) => handleFormInput('message', value)}
          disabled={isDisabled}
        />
        {/* <CheckboxValidator
          label={t('register.attendee.terms_and_conditions')}
          validators={['isTruthy']}
          errorMessages={[t('registration_error_required')]}
          checked={checked}
          value={checked}
          name="terms_and_conditions"
          onChange={handleCheck}
          style={styles.checkBox}
          disabled={isDisabled}
        /> */}
        <br></br>
        <div className='flex justify-end mt-4'>
          <Button
            disabled={isDisabled}
            type='submit'
            className='px-4 rounded-full text-dark-grey-body '
          >
            {t('register.attendee.register_button')}
          </Button>
        </div>
        {openSnackbar && (
          <Snackbar
            openSnackbar={checkIfMother()}
            open={openSnackbar}
            title={snackbarText}
            severity={severity}
            setOpen={setOpenSnackbar}
          />
        )}
      </ValidatorForm>
    </Layout>
  );
}

const styles = {
  attendHeaderWrapper: {
    textAlign: 'center',
  },
  checkBox: {
    alignItem: 'center',
  },
  attendButtonWrapper: {
    margin: 10,
    textAlign: 'center',
  },
  fromContainer: {
    height: '100%',
  },
  endOfForm: {
    margin: 10,
  },
  containerRow: {
    width: '95%',
    marginRight: '5px',
  },
};

RegisterAttendee.propTypes = {
  computedMatch: PropTypes.object.isRequired,
  user: PropTypes.object,
  submit: PropTypes.func,
  showFeedback: PropTypes.bool,
  isDisabled: PropTypes.bool,
  openSnackbar: PropTypes.bool,
  snackbarText: PropTypes.string,
  severity: PropTypes.string,
  setOpenSnackbar: PropTypes.func,
  handleCloseDialog: PropTypes.func,
  dispatchPathway: PropTypes.func,
  pathway: PropTypes.any,
  box: PropTypes.any,
  tags: PropTypes.any,
  last_trainrg: PropTypes.any,
  initial_tags: PropTypes.any,
  def_flow: PropTypes.any,
  dispatchNextTag: PropTypes.func,
  dispatchTags: PropTypes.func,
  dispatchlastTrainrg: PropTypes.func,
  dispatchLastEventReg: PropTypes.func,
  nextTag: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterAttendee);
