/* eslint-disable */

import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as dates from 'react-big-calendar/lib/utils/dates';
import { Navigate } from 'react-big-calendar';
import PropTypes from 'prop-types';
import routes from '../../../helpers/routes';
import Button from '../../button/Button';

const Agenda = (props) => {
  const { t } = useTranslation();
  const { events } = props;
  const history = useHistory();

  const rows = () => {
    if (!events || !events.length) {
      return (
        <tr>
          <td colSpan='6' align='center'>
            {t('calendar.no_events_found')}
          </td>
        </tr>
      );
    }

    return events.map((event, index) => {
      let openSlots = event.attendeeLimit - event.attendees.length;
      openSlots = openSlots < 0 ? 0 : openSlots;

      const detailsLink = routes.calendar_register.replace(':id', event.id);

      const routeChange = () => {
        history.push(detailsLink);
      };

      const link = openSlots ? (
        <Button onClick={routeChange}>{t('register_button')}</Button>
      ) : (
        <p>{t('calendar.no_slots_available')}</p>
      );

      return (
        <tr key={index} className='rbc-agenda-body-wrapper'>
          <td>{moment(event.start).format('MMM Do')}</td>
          <td>{moment(event.start).format('HH:mm')}</td>
          <td>{event.title}</td>
          <td>{event.location}</td>
          <td>{link}</td>
          <td>
            <Typography className='textParticipants'>
              {openSlots} {t('open')}
            </Typography>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className='rbc-agenda-table-wrapper'>
      <table>
        <thead>
          <tr className='rbc-agenda-header-wrapper'>
            <th>{t('date')}</th>
            <th>{t('time')}</th>
            <th>{t('event')}</th>
            <th>{t('address')}</th>
            <th>{t('register_button')}</th>
            <th>{t('slots')}</th>
          </tr>
        </thead>
        <tbody>{rows()}</tbody>
      </table>
    </div>
  );
};

Agenda.navigate = (date, action) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return dates.add(date, -1, 'week');

    case Navigate.NEXT:
      return dates.add(date, 1, 'week');

    default:
      return date;
  }
};

Agenda.range = (date, { localizer }) => {
  let firstOfWeek = localizer.startOfWeek();
  let start = dates.startOf(date, 'week', firstOfWeek);
  let end = dates.endOf(date, 'week', firstOfWeek);

  return dates.range(start, end);
};

Agenda.title = (date, { localizer }) => {
  let [start, ...rest] = Agenda.range(date, { localizer });
  return localizer.format({ start, end: rest.pop() }, 'dayRangeHeaderFormat');
};

Agenda.propTypes = {
  events: PropTypes.array,
};

export default Agenda;
