import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import routes from '../../helpers/routes';
import Label from '../label/Label';
import Button from '../button/Button';

function NoEventsFound({ className, ...rest }) {
  const history = useHistory();
  const { t } = useTranslation();

  const goTo = () => {
    history.push(routes.app.index);
  };

  return (
    <div className={['', className].join(' ')} {...rest}>
      <div className='shadow-3xl flex justify-center '>
        <div className='lg:w-2/3 p-18 space-y-6'>
          <div className='text-electric-blue text-5xl'>
            {t('navigation.my_events')}
          </div>
          <hr
            className='rounded-full text-center z-0'
            style={{
              borderTop: '2px solid rgba(136,139,141, 0.3)',
            }}
          />
          <Label
            label={t('feedback.no_events')}
            className='text-dark-grey-body text-2xl'
          />
          <Label
            label={t('feedback.invalid_search_description')}
            className='text-dark-grey-body  mb-10'
          />

          <Button
            className='rounded-full text-dark-grey-body px-4'
            onClick={goTo}
          >
            {t('feedback.home')}
          </Button>
        </div>
        <div className='w-1/3 bg-no-repeat bg-cover h-login bg-login bg-30 hidden lg:block'></div>
      </div>
      {/* <Label label={t('feedback.no_events')} className='text-yellow' />
      <Label
        label={t('feedback.invalid_search_description')}
        className='text-yellow mb-10'
      /> */}
      {/* <Button onClick={goTo}>{t('feedback.home')}</Button> */}
    </div>
  );
}

NoEventsFound.propTypes = {
  className: PropTypes.string,
};

export default NoEventsFound;
