import i18n from 'i18next';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import detectBrowserLanguage from 'detect-browser-language';
import translation_en from './locales/translation_en.json';
import translation_es from './locales/translation_es.json';
import translation_de from './locales/translation_de.json';
import translation_nl from './locales/translation_nl.json';
import translation_at from './locales/translation_at.json';
import translation_fr_ch from './locales/translation_fr_ch.json';
import translation_de_ch from './locales/translation_de_ch.json';
import translation_it_ch from './locales/translation_it_ch.json';
import translation_en_gb from './locales/translation_en_gb.json';
import translation_fi from './locales/translation_fi.json';
import translation_za from './locales/translation_za.json';
import translation_se from './locales/translation_se.json';
import translation_dk from './locales/translation_dk.json';
import translation_pl from './locales/translation_pl.json';
import translation_pt from './locales/translation_pt.json';
import translation_gr from './locales/translation_gr.json';
import translation_il from './locales/translation_il.json';
import translation_no from './locales/translation_no.json';

import { ENGLISH, GERMAN } from './helpers/SupportedLanguages';

const resources = {
  en: {
    translation: { ...translation_en },
  },
  es: {
    translation: { ...translation_es },
  },
  de: {
    translation: { ...translation_de },
  },
  nl: {
    translation: { ...translation_nl },
  },
  at: {
    translation: { ...translation_at },
  },
  fr_ch: {
    translation: { ...translation_fr_ch },
  },
  de_ch: {
    translation: { ...translation_de_ch },
  },
  it_ch: {
    translation: { ...translation_it_ch },
  },
  en_gb: {
    translation: { ...translation_en_gb },
  },
  fi: {
    translation: { ...translation_fi },
  },
  za: {
    translation: { ...translation_za },
  },
  se: {
    translation: { ...translation_se },
  },
  dk: {
    translation: { ...translation_dk },
  },
  pl: {
    translation: { ...translation_pl },
  },
  pt: {
    translation: { ...translation_pt },
  },
  gr: {
    translation: { ...translation_gr },
  },
  il: {
    translation: { ...translation_il },
  },
  no: {
    translation: { ...translation_no },
  },
};

export const changeLanguage = (locale) => {
  i18n.changeLanguage(locale, (err) => {
    if (err) {
      console.error('changing language error: ', err);
      return;
    }
  });
  moment.locale(locale);
};

const browserLang = detectBrowserLanguage().split('-')[0];
const language =
  browserLang !== ENGLISH && browserLang !== GERMAN ? GERMAN : browserLang;

if (initReactI18next) {
  i18n.use(initReactI18next);
}

i18n.init({
  resources,
  lng: language,
  fallbackLng: ENGLISH,
  debug: false,
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
