/* eslint-disable */

import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert/Alert';
import Summary from './Summary';
import HoCTechnicalInstructions from './TechnicalInstructions';
import Layout from './Layout';
import routes from '../../helpers/routes';

const AWAIT_BEFORE_REDIRECT = 4000;

const mapStateToProps = (state) => {
  return {
    box: state.pathwayReducer.box,
  };
};

export function FormManager(props) {
  const {
    heading,
    box,
    products,
    productFamiliarity,
    selectCompanion,
    handleBack,
    validator,
    formType,
    user,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [saveData] = useMutation(props.mutation);
  const [openValidateScreen, setValidateScreen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [severity, setSeverity] = useState(t('warning'));
  const [title, setTitle] = useState('');
  const [formData, setFormData] = useState({
    email: user ? user.username : '',
    city: user ? user.city : '',
  });
  const classes = useStyles();
  const TechnicalInstructions = HoCTechnicalInstructions(
    productFamiliarity,
    selectCompanion
  );
  const country = user && user.country ? user.country.alpha2 : '';

  const handleclose = () => {
    history.replace(routes.app.index);
  };

  const showSummary = (data) => {
    setFormData(data);
    setValidateScreen(true);
  };

  const showTechnicalForm = (data) => {
    setFormData(data);
    setValidateScreen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      const ret = await saveData({
        variables: {
          input: { ...formData, formTitle: t(box.title) },
          formtype: formType,
        },
      });

      if (ret && ret.data) {
        setSeverity('success');
        setTitle(t('information_requested'));
        setTimeout(handleclose, AWAIT_BEFORE_REDIRECT);
      }
    } catch (e) {
      setSeverity('error');
      setTitle(t('error_has_occured'));
      setLoading(false);
    } finally {
      setOpenAlert(true);
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <Layout heading={heading} handleBack={handleBack}>
      <Snackbar
        open={openAlert}
        autoHideDuration={AWAIT_BEFORE_REDIRECT}
        onClose={handleAlertClose}
      >
        <Alert variant='filled' severity={severity} onClose={handleAlertClose}>
          {title}
        </Alert>
      </Snackbar>

      <div
        id='summary'
        className={` p-8 ${openValidateScreen ? classes.show : classes.hide}`}
      >
        <Summary
          className={loading ? classes.disabled : null}
          data={formData}
          handleClick={showTechnicalForm}
          handleClose={handleclose}
          handleSubmit={handleSubmit}
          country={country}
        />
      </div>

      <div id='' className={!openValidateScreen ? classes.show : classes.hide}>
        <div className='mb-5'>
          <TechnicalInstructions
            country={country}
            products={products}
            onSubmitForm={showSummary}
            data={formData}
            validator={validator}
            formtype={formType}
          />
        </div>
      </div>
    </Layout>
  );
}

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  show: {
    display: 'block',
  },
  hide: {
    display: 'none',
  },
  disabled: {
    opacity: '0.7',
    pointerEvents: 'none',
  },
}));

FormManager.propTypes = {
  heading: PropTypes.node,
  products: PropTypes.array,
  productFamiliarity: PropTypes.bool,
  selectCompanion: PropTypes.bool,
  mutation: PropTypes.any,
  handleBack: PropTypes.func,
  validator: PropTypes.object,
  formType: PropTypes.string,
  user: PropTypes.object,
  box: PropTypes.object,
};

FormManager.defaultProps = {
  formtype: '',
};

export default connect(mapStateToProps, null)(FormManager);
