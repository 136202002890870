import React from 'react';
import YesOrNoSelection from './steps/YesOrNoSelection';
import CardSelection from './steps/CardSelection';
import RadioSelection from './steps/RadioSelection';
import SelectionSummary from './steps/SelectionSummary';
import InformativeSelection from './steps/InformativeSelection';
import TechnicalBriefing from './forms/briefing/TechnicalBriefing';
import TechnicalTrainingForKids from './forms/training/TechnicalTrainingForKids';
import SpecialTechnicalInstructions from './forms/special-instructions/SpecialTechnicalInstructions';
import { LoadingFullScreenCentered } from './loading-indicator/Loading';
import SingleInputSelection from './steps/single-input';
import FormSelection from './steps/form-selection';
import CalendarScreen from '../screens/CalendarScreen';

const Components = {
  CardSelection: CardSelection,
  YesOrNoSelection: YesOrNoSelection,
  RadioSelection: RadioSelection,
  FormSelection: FormSelection,
  Calendar: CalendarScreen,
  SingleInputSelection,
  Summary: SelectionSummary,
  InformativeSelection,
  FirstForm: TechnicalBriefing,
  SecondForm: TechnicalTrainingForKids,
  ThirdForm: SpecialTechnicalInstructions,
};

export default function WorkflowComponentRenderer(
  block,
  next,
  prev,
  summary,
  user,
  dispatchUser,
  setCustomFlow,
  customFlow,
  currentStepData
) {
  if (typeof Components[block.componentType] !== 'undefined') {
    return React.createElement(Components[block.componentType], {
      key: block._uid,
      handleClick: next,
      handleBack: prev,
      summary: summary,
      user: user,
      dispatchUser: dispatchUser,
      setCustomFlow: setCustomFlow,
      customFlow: customFlow,
      currentStepData: currentStepData,
      ...block,
    });
  }

  return <LoadingFullScreenCentered />;
}
