import React from 'react';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { ToastContainer, toast } from 'react-toastify';
// import ProfileCard from '../components/settings/ProfileCard';
import User from '../schema/User';
// import Snackbar from '../components/header/SnackbarOpenOption';
import PasswordCard from '../components/settings/PasswordCard';
import { LoadingFullScreenCentered } from '../components/loading-indicator/Loading';
// import Label from '../components/label/Label';

function SettingsScreen() {
  const { t } = useTranslation();
  // const [showSnackbar, setShowSnackbar] = useState(false);
  // const [snackbarSeverity, setSnackbarSeverity] = useState();
  // const [snackbarText, setSnackbarText] = useState();
  const [profileData, setProfileData] = useState({});
  const { loading, error, data } = useQuery(User.GET_PROFILE_INFO);

  useEffect(() => {
    if (loading) {
      setProfileData({
        name: 'loading',
        email: 'loading',
        city: 'loading',
      });
    }

    if (error) {
      // setSnackbarSeverity('warning');
      // setSnackbarText(t('error_has_occured'));
      // setShowSnackbar(true);
      toast.warn('Error has occured', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }

    if (data) {
      setProfileData(data.getProfileData);
    }
  }, [
    loading,
    data,
    error,
    t,
    // setSnackbarSeverity,
    // setShowSnackbar,
    // setSnackbarText,
  ]);

  const handleSnackbar = (text, severity) => {
    // setSnackbarSeverity(severity);
    // setSnackbarText(t(text));
    // setShowSnackbar(true);
    if (severity === 'warning') {
      toast.warn(t(text), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } else if (severity === 'success') {
      toast.success(t(text), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } else {
      toast.info(t(text), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  if (loading) {
    return <LoadingFullScreenCentered />;
  }

  return (
    <div className='mt-10'>
      <ToastContainer />

      {profileData.country && (
        <>
          <div className=''>
            <div className='flex justify-center shadow-3xl'>
              <div className='space-y-6 lg:w-2/3 lg:p-18 xs:p-6'>
                <div className='text-5xl text-electric-blue'>
                  {t('settings.title')}
                </div>
                <hr
                  className='z-0 text-center rounded-full'
                  style={{
                    borderTop: '2px solid rgba(136,139,141, 0.3)',
                  }}
                />

                <div className='text-lg text-dark-grey-body '>
                  <span className='font-bold'>
                    {t('settings.email_address')}
                    {': '}
                  </span>
                  {profileData.username}
                </div>

                <div className='flex flex-col mt-10 md:flex-row md:justify-end'>
                  <div className='w-full '>
                    <PasswordCard handleSnackbar={handleSnackbar} />
                  </div>
                </div>
              </div>
              <div className='hidden w-1/3 bg-no-repeat bg-cover h-login bg-login bg-30 lg:block'></div>
            </div>
            {/* <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-2/4">
              <Label label={t('settings.title')} className="text-xl text-gray" />
            </div>
            <div className="w-full md:w-2/4">
              <ProfileCard data={profileData} handleSnackbar={handleSnackbar} />
            </div>
          </div>

          <div className="flex flex-col mt-10 md:flex-row md:justify-end">
            <div className="w-full md:w-2/4">
              <PasswordCard handleSnackbar={handleSnackbar} />
            </div>
          </div> */}
          </div>
        </>
      )}
      {/* {showSnackbar && (
        <Snackbar
          open={showSnackbar}
          title={snackbarText}
          severity={snackbarSeverity}
          setOpen={setShowSnackbar}
        />
      )} */}
    </div>
  );
}

export default SettingsScreen;
