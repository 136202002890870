import { ENGLISH } from './helpers/SupportedLanguages';

export const AVAILABLE_ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production'
};

/* eslint-disable no-undef */
export default {
  CALENDAR_ENDPOINT: process.env.REACT_APP_CALENDAR_ENDPOINT || 'http://localhost:4000',
  API_GRAPHQL: process.env.REACT_APP_API_GRAPHQL || 'http://localhost:5000/graphql',
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID || 'patient_portal',
  DEFAULT_LANG: process.env.DEFAULT_LANG || ENGLISH,
  DEFAULT_TZ: process.env.DEFAULT_TZ || 'Europe/Madrid',
  API_KEY: process.env.REACT_APP_API_KEY || 'AIzaSyAqduyJ5hbyJHoJta0F2Yd6hgspWU7Rg6c',
  VALID_ISSUERS: JSON.parse(
    process.env.REACT_APP_VALID_ISSUERS || '{"https://patient_portal.eu":"http://localhost:5000"}'
  ),
  LOCAL_CERTS: JSON.parse(process.env.REACT_APP_LOCAL_CERTS || '{}'),
  ENVIRONMENT: process.env.REACT_APP_ENV,
  LOGIN: {
    COOKIE_NAME: 'l',
    ACCESS_TOKEN: 'pjwt',
    REFRESH_TOKEN: 'prjwt',
    STAY_LOGGED: 'sl',
    DATA: 'jwtd',
    REFRESH_DATA: 'jwtrd',
    LOGOUT_STORAGE_KEY: 'logout',
    LOGIN_STORAGE_KEY: 'login'
  }
};
