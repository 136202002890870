/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { FormControl } from '@material-ui/core';
import Country from './forms/inputs/Country';
// import City from './forms/inputs/City';
import Select from './forms/inputs/Select';
import ItemList from './item-list/ItemList';
// import Label from './label/Label';
import { DISTANCES } from '../constants/search';
import { COUNTRIES } from '../constants/cvent';

function SearchBar({
  city,
  country,
  isOnline,
  isFaceToFace,
  onSearch,
  language,
}) {
  const { t } = useTranslation();
  const [searchData, setSearchData] = useState({
    distance: '',
    city: { locality: city || '' },
    language: language || '',
    country,
    isOnline,
    isFaceToFace,
  });
  const selectorWrapperClasses =
    'my-2 xs:w-3/4 md:w-full bg-white border-2 border-gray ';
  const divWrapperClasses =
    'grid xs:grid-col-1 md:grid-cols-3 md:gap-4 xs:justify-items-center ';

  // const divWrapperClasses =
  //   'grid xs:grid-col-1 md:grid-cols-4 md:gap-4 xs:justify-items-center '; //! Enable for 4th searchbar on filter

  const TYPETRAINING = [
    {
      id: 'both',
      label: t('search.onlineandfacetoface'),
      value: '{"isOnline":true,"isFaceToFace":true}',
    },
    {
      id: 'online',
      label: t('search.online'),
      value: '{"isOnline":true,"isFaceToFace":false}',
    },
    {
      id: 'facetoface',
      label: t('search.facetoface'),
      value: '{"isOnline":false,"isFaceToFace":true}',
    },
  ];

  useEffect(() => onSearch(searchData), [searchData]);
  const handleSearch = (name) => (event) => {
    setSearchData({
      ...searchData,
      [name]: event,
      // [name]: event.target.value,
    });
  };

  const onEventTypeTrainingChange = (name) => (event) => {
    const valueParsed = JSON.parse(event);
    setSearchData({
      ...searchData,
      ...valueParsed,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const onEventLanguageChange = (language) => {
    setSearchData({
      ...searchData,
      language,
    });
  };

  if (!country) {
    return <div />;
  }

  const isNetherlands = country.toLocaleLowerCase() === COUNTRIES.NETHERLANDS;
  const isSpain = country.toLocaleLowerCase() === COUNTRIES.SPAIN;
  const isSouthAfrica = country.toLocaleLowerCase() === COUNTRIES.SOUTH_AFRICA;
  return (
    <ValidatorForm onSubmit={handleSearch}>
      <div className={`${divWrapperClasses} ${''}`}>
        {/* <div className={selectorWrapperClasses}>
          <p className='mb-2 text-base text-primary'>
            {t('search.training_type')}
          </p>
          <div className='p-2 text-xs whitespace-pre-wrap bg-light-blue text-primary lg:block'>
            <Label label={t('search.instructions.note')} />
            <ul className='pl-4 list-square'>
              <li>
                <Label label={t('search.instructions.tick_box')} />
              </li>
              {t('search.instructions.online_and_face_to_face').length > 5 && (
                <li>
                  <Label
                    label={t('search.instructions.online_and_face_to_face')}
                  />
                </li>
              )}
              {t('search.instructions.online').length > 5 && (
                <li>
                  <Label label={t('search.instructions.online')} />
                </li>
              )}
              {t('search.instructions.faceToface').length > 5 && (
                <li>
                  <Label label={t('search.instructions.faceToface')} />
                </li>
              )}
              {t('search.instructions.refine_selection').length > 5 && (
                <li>
                  <Label label={t('search.instructions.refine_selection')} />
                </li>
              )}
            </ul>
          </div>
        </div> */}
        {/* <div className={selectorWrapperClasses}>
          <div className='flex justify-start mt-2 md:mt-5 md:justify-center'>
            <div className='flex items-start lg:flex-col'>
               <CheckboxMDTStyles
                label={
                  <>
                    <span className='uppercase text-primary'>
                      {t('calendar.trainings.online')}
                    </span>
                  </>
                }
                onChange={(active) =>
                  setSearchData({ ...searchData, isOnline: active })
                }
                active={searchData.isOnline}
                color={isSpain ? 'bg-pale-gray' : 'bg-cobalt'}
                disabled={isSpain}
              />
              <CheckboxMDTStyles
                label={
                  <>
                    <span className='uppercase text-primary'>
                      {t('calendar.trainings.face_to_face')}
                    </span>
                  </>
                }
                onChange={(active) =>
                  setSearchData({ ...searchData, isFaceToFace: active })
                }
                color={isSpain ? 'bg-pale-gray' : 'bg-primary'}
                active={searchData.isFaceToFace}
                disabled={isSpain}
              /> 
            </div>
          </div>
        </div> */}

        <div className={`${selectorWrapperClasses}`}>
          <FormControl className='w-full '>
            <Select
              value={searchData.typetraining}
              onChange={onEventTypeTrainingChange('typetraining')}
              selectItems={
                <>
                  <option value='' className='text-electric-blue' disabled>
                    {t('search.training_type')}
                  </option>
                  <ItemList items={TYPETRAINING} translate={false} />
                </>
              }
            />
          </FormControl>
        </div>
        <div
          className={`${selectorWrapperClasses} ${
            isNetherlands ? 'hidden' : 'block'
          } ${isSouthAfrica ? 'hidden' : 'block'}`}
        >
          <FormControl className='w-full'>
            <Country
              disabled={true}
              defaultLabel={false}
              value={searchData.country ? searchData.country : ''}
              onChange={handleSearch('country')}
            />
          </FormControl>
        </div>

        <div
          className={`${selectorWrapperClasses} ${
            isNetherlands ? 'hidden' : 'block'
          }
          ${isSouthAfrica ? 'hidden' : 'block'}`}
        >
          {/* <FormControl className='w-full'>
            <City
              countryId={searchData.country}
              valueByCityName={searchData.city.locality}
              defaultLabel={true}
              onChange={(city) => setSearchData({ ...searchData, city })}
              defaultLabelWithoutText={true}
              disabled={searchData.isOnline}
            />
          </FormControl> */}
          <FormControl className='w-full'>
            <Select
              value={searchData.distance}
              onChange={handleSearch('distance')}
              disabled={searchData.isOnline}
              selectItems={
                <>
                  <option value=''>{t('radius')}</option>
                  <ItemList items={DISTANCES} translate={false} />
                </>
              }
            />
          </FormControl>
        </div>
        {/* <div
          className={`${selectorWrapperClasses} ${
            isNetherlands ? 'hidden' : 'block'
          } ${isSouthAfrica ? 'hidden' : 'block'}`}
        >
          <FormControl className='w-full'>
            {searchData.country !== 'SE' && (
              <CountryLanguage
                country={searchData.country}
                value={searchData.language}
                onChange={onEventLanguageChange}
                // onMount={onEventLanguageChange}
                className={country === COUNTRIES.SWITZERLAND ? '' : 'hidden'}
                disabled={country.toLowerCase() === COUNTRIES.GERMANY}
              />
            )}
            {searchData.country === 'SE' && (
              <CountryLanguage
                country={searchData.country}
                value={searchData.language}
                onChange={onEventLanguageChange}
                // onMount={onEventLanguageChange}
                disabled={country.toLowerCase() === COUNTRIES.SWEDEN}
              />
            )}
          </FormControl>
        </div> */}
      </div>
    </ValidatorForm>
  );
}

SearchBar.propTypes = {
  country: PropTypes.string,
  city: PropTypes.any,
  isOnline: PropTypes.bool,
  isFaceToFace: PropTypes.bool,
  onSearch: PropTypes.func,
  language: PropTypes.string,
};

export default SearchBar;
