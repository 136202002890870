import { useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormSelection from './FormSelection';
import { setFlowForm } from '../../../actions/flowForm';
import CventForm from '../../../schema/forms/CventForm';

const mapDispatchToProps = (dispatch) => ({
  dispatchFlowForm: (flowForm) => dispatch(setFlowForm(flowForm)),
});

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
  };
};

export function GraphQLWrapper(props) {
  const { handleClick, stepName, componentType, options, dispatchFlowForm } =
    props;
  const { data, loading: loadingHospitals } = useQuery(CventForm.GET_HOSPITALS);
  const [loading, setLoading] = useState(false);
  const obj = {
    stepName: stepName,
    componentType: componentType,
    ...options[0],
  };

  const handleSubmit = (form) => {
    setLoading(true);
    dispatchFlowForm({ ...form });
    handleClick(obj);
  };

  return (
    <FormSelection
      {...props}
      data={data}
      loadingHospitals={loadingHospitals}
      submit={handleSubmit}
      loading={loading}
    />
  );
}

GraphQLWrapper.propTypes = {
  handleClick: PropTypes.func,
  componentType: PropTypes.string,
  stepName: PropTypes.string,
  options: PropTypes.array,
  dispatchFlowForm: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(GraphQLWrapper);
