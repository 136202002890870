import React from 'react';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.linksBlue,
    borderBottom: `2px solid ${theme.palette.linksBlue}`,
    fontWeight: '600',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

export default function PatientPortalLink(props) {
  const { children, onClick, ...rest } = props;
  const classes = useStyles();
  return (
    <Link
      onClick={onClick}
      component='button'
      variant='h6'
      classes={{
        root: classes.root,
      }}
      {...rest}
    >
      {children}
    </Link>
  );
}

PatientPortalLink.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};
