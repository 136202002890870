/* eslint-disable */

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { ToastContainer, toast } from 'react-toastify';
import LoginDashboard from '../components/loginDashboard/LoginDashboard';
import Auth from '../helpers/Auth';
import User from '../schema/User';
import Config from '../config';
import routes from '../helpers/routes';
import Loading from '../components/loading-indicator/Loading';
import Home from '../components/container/Home';
import {
  setUserLoggedInDashboard,
  setUserDashboard,
} from '../actions/userDashboard';

const mapDispatchToProps = (dispatch) => ({
  dispatchLoggedInDashboard: (loggedIn) =>
    dispatch(setUserLoggedInDashboard(loggedIn)),
  dispatchUserDasboard: (loggedUser) => dispatch(setUserDashboard(loggedUser)),
});

export function LoginscreenDashboard(props) {
  const { t } = useTranslation();

  const data = {
    username: props.username,
    password: props.password,
    clientId: Config.CLIENT_ID,
  };
  const [login] = useMutation(User.LOGINDASHBOARD, {
    variables: { input: data },
  });

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [invalidCred, setinvalidCred] = useState(false);

  const history = useHistory();

  const handleClick = async () => {
    if (loading) {
      return <Loading />;
    }

    setLoading(true);
    try {
      const data = {
        username: username,
        password: password,
        clientId: Config.CLIENT_ID,
      };
      const ret = await login({ variables: { input: data } });
      const responseParsed = JSON.parse(
        ret.data?.loginTokenDashboard?.response
      );
      if (responseParsed.status === 200) {
        // await Auth.setTokens(lt.accessToken, lt.refreshToken, lt.rememberMe);
        props.dispatchLoggedInDashboard(true);
        props.dispatchUserDasboard(responseParsed);

        history.push(routes.dashboard.index);
      } else if (responseParsed.status === 204) {
        // console.log('invalid cred');
        setinvalidCred(true);
        console.log(invalidCred);
        // alert(le.errorDescription);
        toast.error(responseParsed.response, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (event) {
      // alert('unknown_error4');
      // alert('Something went wrong! Please, try again later.');
      toast.error('Something went wrong! Please, try again later.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Home>
      <ToastContainer />
      <LoginDashboard
        loading={loading}
        invalidCred={invalidCred}
        setPassword={setPassword}
        setUsername={setUsername}
        password={password}
        username={username}
        handleClick={handleClick}
      />
    </Home>
  );
}

LoginscreenDashboard.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  dispatchLoggedIn: PropTypes.func,
  dispatchUserDasboard: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(LoginscreenDashboard);
