import { gql } from '@apollo/client';

const POST_EVENT_ATTENDEE = gql`
  mutation EventAttendee($input: EventAttendeeInput!) {
    mutateEventAttendee(input: $input) {
      id
      localeFromSelector
    }
  }
`;

const DELETE_NODE = gql`
  mutation DeleteNodeMutation($input: NodeSelectInput!) {
    deleteNode(input: $input) {
      id
    }
  }
`;

const GET_ALL_EVENTS = gql`
  query eventSearch(
    $fromDate: Int!
    $toDate: Int!
    $country: String
    $tags: [KeyValueInput!]
    $coords: CoordsSearch
  ) {
    eventSearch(
      input: {
        fromDate: $fromDate
        toDate: $toDate
        country: $country
        tags: $tags
        coords: $coords
      }
    ) {
      id
      language
      city {
        administrativeAreaLevel1
        administrativeAreaLevel2
        country
        locality
        lat
        lng
      }

      lat
      lng
      attendeeLimit
      attendees {
        _id
      }
      organizers {
        email
      }
      title
      description
      color
      location
      calendar {
        name
        creator {
          id
          name
        }
      }
      tags {
        value
      }
      createdAt
      start
      end
      isOnline
      locationName
      isPathwayEvent
      pathway {
        title
        rootItems {
          id
          event {
            id
            title
          }
        }
        events {
          title
        }
      }
      pathwayItem {
        pathway {
          id
          rootItems {
            event {
              id
              title
            }
          }
        }
      }
      rootEvent
    }
  }
`;

const GET_ORGANIZER_EMAIL_BY_EVENT_ID = gql`
  query GetOrganizerEmail($eventId: ID!) {
    event(id: $eventId) {
      organizers {
        email
      }
    }
  }
`;

const GET_SINGLE_EVENT = gql`
  query event($id: ID!) {
    event(id: $id) {
      id
      title
      start
      end
      city {
        administrativeArea
        administrativeAreaLevel1
        administrativeAreaLevel2
        locality
        country
      }
      isPathwayEvent
      pathway {
        title
        rootItems {
          id
          event {
            id
            title
          }
        }
        events {
          title
        }
      }
      pathwayDescendants {
        id
        language
        city {
          administrativeAreaLevel1
          administrativeAreaLevel2
          country
          locality
          lat
          lng
        }

        lat
        lng
        attendeeLimit
        attendees {
          _id
        }
        organizers {
          email
        }
        title
        description
        color
        location
        calendar {
          name
          creator {
            id
            name
          }
        }
        tags {
          value
        }
        createdAt
        start
        end
        isOnline
        locationName
        pathwayItem {
          pathway {
            id
            rootItems {
              event {
                id
                title
              }
            }
          }
        }
      }
      pathwayItem {
        pathway {
          id
          rootItems {
            event {
              id
              title
            }
          }
        }
      }
      rootEvent
    }
  }
`;

const SEND_EMAIL_ATTENDEE = gql`
  mutation registerEventAttendee($input: RegisterAttendeeInput!) {
    registerEventAttende(input: $input) {
      response
    }
  }
`;

const EVENT_REGISTER_TO_DASHBOARD = gql`
  mutation registerEventToDashboard($input: EventRegisterToDashboardInput!) {
    registerEventToDashboard(input: $input) {
      response
    }
  }
`;

const DELETE_USER_EVENT_REGISTER_TO_DASHBOARD = gql`
  mutation deleteUserEventRegisterToDashboard(
    $input: DeleteUserEventFromDashboard!
  ) {
    deleteUserEventRegisterToDashboard(input: $input) {
      response
    }
  }
`;

const SEND_EMAIL_ATTENDEE_CANCEL_EVENT = gql`
  mutation cancelEventRegistation($input: CancelEventRegistationInput!) {
    cancelEventRegistation(input: $input) {
      response
    }
  }
`;

const GET_CITIES_OF_COUNTRY = gql`
  query cities($input: CitySearchQueryInput!) {
    cities(input: $input) {
      id
      administrativeArea
      administrativeAreaLevel1
      administrativeAreaLevel2
      lat
      lng
      locality
      country
    }
  }
`;

const GET_PATHWAYS = gql`
  query {
    pathways {
      _id
      title
      items {
        _id
        event {
          id
        }
      }
    }
  }
`;

const GET_PATHWAY = gql`
  query pathway($id: ID!) {
    pathway(id: $id) {
      title
    }
  }
`;

//! GET_ORGANIZERS dosnet work beacause permissions you will get an empty array
const GET_ORGANIZERS = gql`
  query {
    organizers {
      id
      name
      branch {
        id
        name
      }
    }
  }
`;

const GET_ORGANIZERS_QUERRY = gql`
  query organizerslocation($input: OrganizerInput!) {
    organizerslocation(input: $input) {
      id
      name
      surname
      email
      location
    }
  }
`;

const UPDATE_USER_LOCALE = gql`
  mutation updateUserLocale($input: UpdateUserLocaleInput!) {
    updateUserLocale(input: $input) {
      response
    }
  }
`;

const Event = {
  GET_SINGLE_EVENT,
  GET_ALL_EVENTS,
  GET_ORGANIZER_EMAIL_BY_EVENT_ID,
  POST_EVENT_ATTENDEE,
  SEND_EMAIL_ATTENDEE,
  SEND_EMAIL_ATTENDEE_CANCEL_EVENT,
  GET_CITIES_OF_COUNTRY,
  GET_PATHWAYS,
  GET_PATHWAY,
  DELETE_NODE,
  GET_ORGANIZERS,
  GET_ORGANIZERS_QUERRY,
  EVENT_REGISTER_TO_DASHBOARD,
  DELETE_USER_EVENT_REGISTER_TO_DASHBOARD,
  UPDATE_USER_LOCALE,
};

export default Event;
