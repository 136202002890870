import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { RightArrowIcon } from '../../important-information/icons/RightArrow';

function Popup({ text, onClick }) {
  const { t } = useTranslation();
  return (
    <div className='items-center justify-center w-full text-center bg-white shadow-3xl md:flex md:h-full '>
      <div className='mb-10 text-lg md:text-2xl text-electric-blue xs:py-12 md:py-0'>
        {text}
      </div>

      <div
        className='flex flex-row items-stretch justify-end py-2 pl-5 pr-8 mt-1 text-2xl font-bold md:absolute md:inset-x-0 md:bottom-0 bg-teal md:pl-0'
        onClick={onClick}
      >
        <div className='self-center pr-4 cursor-pointer text-dark-grey-body'>
          {t('global.continue')}
        </div>
        <div className='self-center '>
          <RightArrowIcon className='w-6 h-6 cursor-pointer text-dark-grey-body' />
        </div>
      </div>
    </div>
  );
}

Popup.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default Popup;
