import PropTypes from 'prop-types';

export default function Error({ children }) {
  return (
    <p
      className="p-5 mt-5 uppercase text-center font-bold bg-primary text-yellow"
    >
      {children}
    </p>
  );
}

Error.propTypes = {
  children: PropTypes.node,
};