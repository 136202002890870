/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, Suspense, useEffect } from 'react';

import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import Text from './inputs/Text';
import Button from '../button/Button';
import {
  COMPANION,
  PRODUCT_FAMILIARITY,
  SHOW_FAMILIARITY_WHEN,
  TECHNICAL_TRAINING_FOR_KIDS_FORM_TYPE,
  SPECIAL_TECHNICAL_INSTRUCTIONS_FORM_TYPE,
} from '../../constants/form';
import ItemList from '../item-list/ItemList';
// eslint-disable-next-line no-unused-vars
import { store } from '../../store/redux';

// import CheckboxValidator from '../material-ui/validators/CheckboxValidator';

const COMPANION_QUANTITY_ARRAY = [
  { id: 1, label: '1' },
  { id: 2, label: '2' },
  { id: 3, label: '3' },
  { id: 4, label: '4' },
];

let name = 'DEFAULT';

//! do not refactor this with `./technicalInstructionsPerCountry/${name}` it will cause sometime error range null...
let ExtraFieldsTechnicalInstructions;

const importModule = () => {
  return import('./technicalInstructionsPerCountry/' + name).catch((error) => {
    return import('./technicalInstructionsPerCountry/DEFAULT.js');
  });
};
ExtraFieldsTechnicalInstructions = React.lazy(importModule);

export default function HoCTechnicalInstructions(
  useProductFamiliarity,
  useSelectCompanion
) {
  name = store.getState().userReducer.user?.country?.alpha2;

  function TechnicalInstructions({ onSubmitForm, user, ...props }) {
    const { products, data, validator, country, formtype } = props;
    const isFormForKids = formtype === TECHNICAL_TRAINING_FOR_KIDS_FORM_TYPE;
    const isSpeacialForm =
      formtype === SPECIAL_TECHNICAL_INSTRUCTIONS_FORM_TYPE;

    const alpha2 = store.getState().userReducer.user
      ? store.getState().userReducer.user
      : { country: { alpha2: 'FAIL' } };

    const [formData, setFormData] = useState({
      prod_type: data.prod_type || '',
      product_familiarity: data.product_familiarity || '',
      contact_by: data.contact_by || '',
      first_name: data.first_name || '',
      family_name: data.family_name || '',
      email: data.email || '',
      city: data.city || '',
      postal_code: data.postal_code || '',
      phone: data.phone || '',
      age: data.age || '',
      companion_quantity: data.companion_quantity || '',
      has_companion: data.has_companion || '',
      message: data.message || '',
      termsAndConditions: data.termsAndConditions || false,
      address: '',
      dateOfBirth: '',
      diabetesCenter: '',
      hcp: '',
      reason: '',
      environmentType: process.env.REACT_APP_ENV,
      countryUser: alpha2.country?.alpha2,
      de_pump_only_question: data.de_pump_only_question || '',
      de_cgm_only_question: data.de_cgm_only_question || '',
      de_health_insurance_company: data.de_health_insurance_company || '',
      uk_pronouns_qestion: data.uk_pronouns_qestion || '',
      uk_hospital: data.uk_hospital || '',
      uk_healthcare_professional: data.uk_healthcare_professional || '',
      uk_hospital_not_in_list_optional:
        data.uk_hospital_not_in_list_optional || '',
      za_sales_representative: data.za_sales_representative || '',
      za_region: data.za_region || '',
      za_experience: data.za_experience || '',
      za_requested_date: data.za_requested_date || '',
      za_organizer: data.za_organizer || '',
      za_organizer_email: data.za_organizer_email || '',
      za_representative_email: data.za_representative_email || '',
    });

    const { t } = useTranslation();
    const ageValidators = ['required', 'matchRegexp:[0-9]', 'minNumber: 0'];
    const ageMessages = [
      t('registration_error_required'),
      t('registration_error_numbers_only'),
      t('registration_error_valid_input'),
    ];

    useEffect(() => {
      ValidatorForm.addValidationRule('isTruthy', (value) => value);
      ValidatorForm.addValidationRule('isDefaultValue', (value) => {
        if (value === 'defaultValue') {
          return false;
        }
        return true;
      });

      if (validator && validator.age) {
        validator.age.forEach((element) => {
          ageValidators.push(element.rule);
          ageMessages.push(element.message);
        });
      }
    }, [validator, formData, ageValidators, ageMessages]);

    const submit = (event) => {
      event.preventDefault();
      onSubmitForm(formData);
    };

    const showProductFamiliarity = () => {
      return (
        SHOW_FAMILIARITY_WHEN.find(
          (allowedToShow) => formData.prod_type === allowedToShow
        ) && useProductFamiliarity
      );
    };

    const setProductFamiliarity = () => {
      if (showProductFamiliarity() && formData.product_familiarity === '') {
        setFormData({ ...formData, product_familiarity: '1' });
        return;
      }

      setFormData({ ...formData, product_familiarity: '' });
    };

    return (
      <div {...props}>
        <ValidatorForm onSubmit={submit} instantValidate={false} className=''>
          <div className='p-8 border-solid bg-atmospheric-white border-1 border-tint-stack-neutral'>
            {/* {alpha2.country?.alpha2 !== 'ZA' &&
              alpha2.country?.alpha2 !== 'GR' && (
                <div>
                  <Text
                    select
                    name='prod_type'
                    validators={['required']}
                    errorMessages={[t('registration_error_required')]}
                    value={formData.prod_type}
                    SelectProps={{
                      native: true,
                    }}
                    onChange={(e) => {
                      setFormData({ ...formData, prod_type: e });
                    }}
                    onBlur={setProductFamiliarity}
                  >
                    <option value=''>
                      {t('cvent.technical.form.product_type.label')} *
                    </option>
                    <ItemList items={products} translate={true} />
                  </Text>
                  <Text
                    name='email'
                    value={formData.email}
                    placeholder={`${t('cvent.technical.form.email')} *`}
                    validators={['required']}
                    errorMessages={[t('registration_error_required')]}
                    disabled={true}
                    onChange={(e) => {
                      setFormData({ ...formData, email: e });
                    }}
                  />
                  <Text
                    name='city'
                    value={formData.city}
                    placeholder={`${t('cvent.technical.form.city')} *`}
                    inputProps={{
                      maxLength: 50,
                    }}
                    validators={['required', 'maxStringLength:50']}
                    errorMessages={[
                      t('registration_error_required'),
                      t('registration_error_too_long'),
                    ]}
                    onChange={(e) => {
                      setFormData({ ...formData, city: e });
                    }}
                  />
                </div>
              )} */}
            {/* {alpha2.country?.alpha2 !== 'ZA' && (
              <div>
                <Text
                  name='postal_code'
                  value={formData.postal_code}
                  placeholder={`${t('cvent.technical.form.zip')} *`}
                  inputProps={{
                    maxLength: 10,
                  }}
                  validators={['required', 'maxStringLength:10']}
                  errorMessages={[
                    t('registration_error_required'),
                    t('registration_error_too_long'),
                  ]}
                  onChange={(e) => {
                    setFormData({ ...formData, postal_code: e });
                  }}
                />
              </div>
            )} */}
            {/* {alpha2.country?.alpha2 !== 'GR' && (
              <div>
                <Text
                  name='contact_by'
                  validators={['required']}
                  errorMessages={[t('registration_error_required')]}
                  value={formData.contact_by}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  onChange={(e) => {
                    setFormData({ ...formData, contact_by: e });
                  }}
                >
                  <option value=''>
                    {t('cvent.technical.form.contact.label')} *
                  </option>
                  <ItemList items={CONTACT_BY} translate={true} />
                </Text>
              </div>
            )} */}
            {/* {alpha2.country?.alpha2 !== 'ZA' &&
              alpha2.country?.alpha2 !== 'DE' && (
                <div>
                  <Text
                    name='age'
                    value={formData.age}
                    placeholder={`${t('cvent.technical.form.age')} *`}
                    inputProps={{
                      maxLength: 3,
                    }}
                    validators={ageValidators}
                    errorMessages={ageMessages}
                    onChange={(e) => {
                      setFormData({ ...formData, age: e });
                    }}
                  />
                </div>
              )} */}

            {showProductFamiliarity() && (
              <div>
                <Text
                  name='product_familiarity'
                  value={formData.product_familiarity}
                  placeholder={`${t(
                    'cvent.technical.form.product_familiarity.label'
                  )} *`}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  onChange={(e) => {
                    setFormData({ ...formData, product_familiarity: e });
                  }}
                >
                  <ItemList items={PRODUCT_FAMILIARITY} translate={true} />
                </Text>
              </div>
            )}
            <div>
              <Text
                name='first_name'
                value={formData.first_name}
                placeholder={`${
                  isFormForKids
                    ? t('cvent.technical.form.first_name_of_kid')
                    : t('cvent.technical.form.first_name')
                } *`}
                inputProps={{
                  maxLength: 50,
                }}
                validators={['required', 'maxStringLength:50']}
                errorMessages={[
                  t('registration_error_required'),
                  t('registration_error_too_long'),
                ]}
                onChange={(e) => {
                  setFormData({ ...formData, first_name: e });
                }}
              />
            </div>
            <div>
              <Text
                name='family_name'
                value={formData.family_name}
                placeholder={`${
                  isFormForKids
                    ? t('cvent.technical.form.family_name_of_kid')
                    : t('cvent.technical.form.family_name')
                } *`}
                inputProps={{
                  maxLength: 50,
                }}
                validators={['required', 'maxStringLength:50']}
                errorMessages={[
                  t('registration_error_required'),
                  t('registration_error_too_long'),
                ]}
                onChange={(e) => {
                  setFormData({ ...formData, family_name: e });
                }}
              />
            </div>
            <div>
              <Text
                name='phone'
                value={formData.phone}
                placeholder={`${t('cvent.technical.form.phone')} *`}
                inputProps={{
                  maxLength: 18,
                }}
                validators={[
                  'required',
                  'matchRegexp:^[+0-9- ]*$',
                  'maxStringLength:18',
                ]}
                errorMessages={[
                  t('registration_error_required'),
                  t('registration_error_valid_input'),
                  t('registration_error_too_long'),
                ]}
                onChange={(e) => {
                  setFormData({ ...formData, phone: e });
                }}
              />
            </div>

            {/* {alpha2.country?.alpha2 === 'ZA' && (
              <TimePicker
                name='za_requested_date'
                label={`${t('cvent.technical.form.za_requested_date.label')} *`}
                selectedDate={formData.za_requested_date}
                InputProps={{ readOnly: true }}
                handleDateChange={(e) => {
                  setFormData({ ...formData, za_requested_date: e.toString().substring(0, 24)});
                }}
              />
            )} */}
            {useSelectCompanion && (
              <div>
                <Text
                  name='companion'
                  value={formData.has_companion}
                  validators={['required']}
                  errorMessages={[t('registration_error_required')]}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  onChange={(e) => {
                    setFormData({ ...formData, has_companion: e });
                  }}
                >
                  <option value=''>
                    {t('cvent.technical.form.has_companion.label')} *
                  </option>
                  <ItemList items={COMPANION} translate={true} />
                </Text>
              </div>
            )}
            {!useSelectCompanion && (
              <div>
                <Text
                  select
                  SelectProps={{
                    native: true,
                  }}
                  name='companion'
                  value={formData.companion_quantity}
                  validators={['required']}
                  errorMessages={[t('registration_error_required')]}
                  onChange={(e) => {
                    setFormData({ ...formData, companion_quantity: e });
                  }}
                >
                  <option value=''>
                    {t('cvent.technical.form.companion_quantity')} *
                  </option>
                  <ItemList items={COMPANION_QUANTITY_ARRAY} translate={true} />
                </Text>
              </div>
            )}

            <Suspense fallback={<div>Loading...</div>}>
              <ExtraFieldsTechnicalInstructions
                store={store}
                setFormData={setFormData}
                formData={formData}
                isSpeacialForm={isSpeacialForm}
                isFormForKids={isFormForKids}
                ageValidators={ageValidators}
                ageMessages={ageMessages}
                setProductFamiliarity={setProductFamiliarity}
                products={products}
              />
            </Suspense>
            {/* {country &&
              country.toLowerCase() ===
                SUPPORTED_COUNTRIES[COUNTRIES.NETHERLANDS] && (
                <Fields
                  fieldData={formData}
                  onChange={(e) =>
                    setFormData({ ...formData, [e.name]: e.value })
                  }
                />
              )} */}
            <div>
              <Text
                multiline
                name='message'
                value={formData.message}
                placeholder={t('cvent.technical.form.message')}
                minRows={5}
                maxRows={5}
                inputProps={{
                  maxLength: 200,
                }}
                validators={['maxStringLength:200']}
                errorMessages={[t('registration_error_too_long')]}
                onChange={(e) => {
                  setFormData({ ...formData, message: e });
                }}
              />
            </div>
            {/* <CheckboxValidator
            label={t('cvent.technical.form.terms_and_conditions')}
            validators={['isTruthy']}
            errorMessages={[t('registration_error_required')]}
            checked={formData.termsAndConditions}
            value={formData.termsAndConditions}
            name="terms_and_conditions"
            color="primary"
            onChange={e => {
              setFormData({
                ...formData,
                termsAndConditions: e.target.checked,
              });
            }}
          /> */}
          </div>

          <div className='flex justify-end mt-4'>
            <Button
              type='submit'
              className='px-4 rounded-full text-dark-grey-body '
            >
              {t('global.review')}
            </Button>
          </div>
        </ValidatorForm>
      </div>
    );
  }

  TechnicalInstructions.propTypes = {
    onSubmitForm: PropTypes.func.isRequired,
    data: PropTypes.object,
    products: PropTypes.array,
    validator: PropTypes.object,
    country: PropTypes.string,
    formtype: PropTypes.string,
    user: PropTypes.object,
  };

  TechnicalInstructions.defaultProps = {
    data: {},
  };

  return TechnicalInstructions;
}
