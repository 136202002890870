import PropTypes from 'prop-types';
import Overlay from '../overlay/Overlay';
// import { CloseIcon } from '../important-information/icons/Close';
import { useOverflowToggler } from '../../hooks/OverflowToggler';

export default function Dialog({
  open,
  //  handleClose,
  render,
}) {
  const [toggled] = useOverflowToggler(open);

  return (
    <Overlay className={`flex  ${toggled ? '' : 'hidden'}`}>
      <div className='w-full mt-5'>{render}</div>
    </Overlay>
  );
}

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  render: PropTypes.node,
  // handleClose: PropTypes.func,
};
