import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function ItemList({ items, translate }) {
  const { t } = useTranslation();

  if (!items) {
    return <></>;
  }

  return items
    .filter((item) => item !== undefined)
    .map((item, index) => {
      return (
        <option key={index} value={item.value}>
          {translate ? t(item.label) : item.label}
        </option>
      );
    });
}

ItemList.propTypes = {
  items: PropTypes.array,
  translate: PropTypes.bool,
};

export default ItemList;
