/* eslint-disable indent */

import {
  SET_USER_DASHBOARD,
  SET_USER_LOGGED_IN_DASHBOARD,
  SET_USER_VERIFIED_DASHBOARD,
} from '../actions/types/userDashboard';

const initialState = () => {
  return {
    user: {},
    loggedIn: false,
    verified: false,
  };
};

export default function userDashboardReducer(state = initialState(), action) {
  switch (action.type) {
    case SET_USER_DASHBOARD:
      return Object.assign({}, state, {
        user: action.payload,
      });

    case SET_USER_LOGGED_IN_DASHBOARD:
      return Object.assign({}, state, {
        loggedIn: action.payload,
      });

    case SET_USER_VERIFIED_DASHBOARD:
      return Object.assign({}, state, {
        verified: action.payload,
      });

    default:
      return state;
  }
}
