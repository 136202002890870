import {
  SET_USER_DASHBOARD,
  SET_USER_LOGGED_IN_DASHBOARD,
  SET_USER_VERIFIED_DASHBOARD,
  FETCH_USER_DASHBOARD,
} from './types/userDashboard';

export const setUserDashboard = (user) => ({
  type: SET_USER_DASHBOARD,
  payload: user,
});

export const setUserLoggedInDashboard = (loggedIn) => ({
  type: SET_USER_LOGGED_IN_DASHBOARD,
  payload: loggedIn,
});

export const setUserVerifiedDashboard = (verified) => ({
  type: SET_USER_VERIFIED_DASHBOARD,
  payload: verified,
});

export const fetchUserDashboard = () => ({
  type: FETCH_USER_DASHBOARD,
});
