/* eslint-disable */

import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { last } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import WorkflowComponentRenderer from '../WorkflowComponentRenderer';
import StepCrumbs from '../steps/StepCrumbs';
import StepsData from '../../data/cventflow';
import * as TagsActions from '../../actions/tags';
import { setUser } from '../../actions/user';
import { SUPPORTED_COUNTRIES } from '../../constants/cvent';
import Label from '../label/Label';

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(TagsActions, dispatch),
    dispatchUser: (user) => dispatch(setUser(user)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
  };
};

export class WorkflowBuilder extends Component {
  state = {
    previousStep: [],
    lang: 'nl',
    allSteps: undefined,
    currentStepData: {},
    selectionSummary: [],
    comingSoon: false,
    customFlow: false,
  };

  constructor(props) {
    super(props);
    this.renderNextComponent = this.renderNextComponent.bind(this);
    this.renderPreviousComponent = this.renderPreviousComponent.bind(this);
    this.setCustomFlow = this.setCustomFlow.bind(this);
  }

  updateFirstStepData() {
    const lang = this.state.lang;
    const supported = SUPPORTED_COUNTRIES[lang] ? true : false;
    this.setState({
      comingSoon: !supported,
    });

    if (supported) {
      this.state.allSteps[lang].map((item) => {
        if (item.stepNumber === '1') {
          return this.setState({
            currentStepData: item,
          });
        }
        return false;
      });
    }
  }

  componentDidUpdate = () => this.buildWorkflow();

  componentDidMount = () => this.buildWorkflow();

  buildWorkflow() {
    if (!this.state.allSteps && this.props.user && this.props.user.country) {
      this.setState(
        {
          lang: this.props.user.country.alpha2.toLowerCase(),
          allSteps: StepsData,
        },
        () => this.updateFirstStepData()
      );
    }
  }

  setCustomFlow(value) {
    this.setState({
      customFlow: value,
    });
  }

  renderNextComponent(obj) {
    const lang = this.state.lang;
    const selectionSummary = {
      stepName: this.state.currentStepData.stepName,
      stepCategory: this.state.currentStepData.stepCategory,
      option: { ...obj },
      customFlow: this.state.customFlow,
    };

    const nextStep = this.state.allSteps[lang].find((item) => {
      if (this.state.customFlow && obj.customFlow !== undefined) {
        obj.to = obj.customFlow;
      }
      return item.stepNumber === obj.to;
    });

    const previousSummary = [...this.state.selectionSummary];
    const stepHistoryArray = [...this.state.previousStep];
    previousSummary.push(selectionSummary);
    stepHistoryArray.push(obj.from);

    if (obj.to === 'Summary') {
      this.setState({
        previousStep: stepHistoryArray,
        currentStepData: {
          componentType: 'Summary',
          stepCategory: 'Summary',
          centered: true,
        },
        selectionSummary: previousSummary,
      });
    } else if (obj.componentType === 'InformativeSelection') {
      this.setState({
        previousStep: stepHistoryArray,
        currentStepData: nextStep,
      });
    } else if (obj.componentType === 'CardSelection') {
      this.setState({
        previousStep: stepHistoryArray,
        currentStepData: nextStep,
      });
    } else {
      this.setState({
        selectionSummary: previousSummary,
        previousStep: stepHistoryArray,
        currentStepData: nextStep,
      });
    }
  }

  renderPreviousComponent() {
    const lang = this.state.lang;
    const prevStep = this.state.allSteps[lang].find((item) => {
      return item.stepNumber === last(this.state.previousStep);
    });

    const stepHistoryArray = [...this.state.previousStep];
    stepHistoryArray.pop();
    const updateSummary = [...this.state.selectionSummary];
    updateSummary.pop();

    this.setState({
      currentStepData: prevStep,
      previousStep: stepHistoryArray,
      selectionSummary: updateSummary,
    });
  }

  render() {
    const { t } = this.props;

    if (this.state.comingSoon) {
      return <Label className='m-auto' label={t('coming_soon')} />;
    }

    return (
      <div
        className={`${
          this.state.currentStepData && this.state.currentStepData.centered
            ? ''
            : ''
        }`}
      >
        {/* {this.state.currentStepData.stepCategory !== 'disable_breadcumbs' && (
          <StepCrumbs currentStep={this.state.currentStepData} />
        )} */}
        {WorkflowComponentRenderer(
          this.state.currentStepData,
          this.renderNextComponent,
          this.renderPreviousComponent,
          this.state.selectionSummary,
          this.props.user,
          this.props.dispatchUser,
          this.setCustomFlow,
          this.state.customFlow,
          this.state.currentStepData
        )}
      </div>
    );
  }
}

WorkflowBuilder.propTypes = {
  user: PropTypes.object,
  dispatchUser: PropTypes.func,
  t: PropTypes.func,
};

export const WorkflowScreenWithTranslation = withTranslation()(WorkflowBuilder);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkflowScreenWithTranslation);
