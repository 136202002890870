import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OutlinedInput, FormControl } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

function TextPassword(props) {
  const [visible, setVisible] = useState();
  const { children, disabled, onChange, name, value, error, ...rest } = props;

  const handleChange = (e) => {
    const value = e.target.value;

    onChange(e.target.name, value);
  };

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <FormControl className='w-full'>
      <OutlinedInput
        disabled={disabled}
        onChange={handleChange}
        value={value}
        name={name}
        error={error}
        type={visible ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton onClick={toggleVisibility} disabled={disabled}>
              {visible ? (
                <Visibility className='text-electric-blue' />
              ) : (
                <VisibilityOff className='text-electric-blue' />
              )}
            </IconButton>
          </InputAdornment>
        }
        {...rest}
      >
        {children}
      </OutlinedInput>
    </FormControl>
  );
}

TextPassword.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.any,
};

export default TextPassword;
