import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import Button from '../components/button/Button';
import Link from '../components/link/Link';
import routes from '../helpers/routes';
import User from '../schema/User';
import Home from '../components/container/Home';
import Text from '../components/forms/inputs/Text';
import BoxShadow from '../components/box-shadow/BoxShadow';

export default function ForgottenPasswordScreen() {
  const { t } = useTranslation();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [resetPassword] = useMutation(User.FORGOT_PASSWORD);
  const [result, setResult] = useState('');
  const [status, setStatus] = useState(false);
  const submit = async function (e) {
    e.preventDefault();
    try {
      const input = {
        input: {
          email,
        },
      };
      const ret = await resetPassword({ variables: input });
      if (ret.data) {
        setEmail('');
        setResult(ret.data.forgotPassword.response);
        setStatus(true);
      }
    } catch (e) {
      setEmail('');
      setResult(t('forgot_insert_valid_email'));
      setStatus(false);
    }
  };

  return (
    <Home>
      <div className='mx-auto xs:w-full md:w-3/4 xs:mt-2'>
        <h1 className='text-4xl text-electric-blue'>{t('login.welcome')}</h1>
        <br />
        <p className='text-lg font-bold text-dark-grey-body lg:w-3/4'>
          {t('login.description')}
        </p>
        <br />
        <BoxShadow>
          <ValidatorForm
            className='flex flex-col justify-start'
            onSubmit={submit}
          >
            <p className={`text-sm text-${status ? 'primary' : 'red'}`}>
              {result}
            </p>
            <div className='mb-5'>
              <Text
                autoFocus
                placeholder={t('email_address')}
                name='email'
                autoComplete='email'
                type='email'
                value={email}
                validators={['required', 'isEmail']}
                errorMessages={[
                  t('registration_error_required'),
                  t('registration_error_valid_email'),
                ]}
                onChange={(e) => setEmail(e)}
              />
            </div>
            <Button
              className='px-4 rounded-full text-dark-grey-body'
              type='submit'
              style={{ alignSelf: 'start' }}
            >
              {t('recover_password')}
            </Button>
            <div className='mt-5'>
              <Link onClick={() => history.push(routes.login)}>
                {t('forgot_existing_account')}
              </Link>
            </div>
          </ValidatorForm>
        </BoxShadow>
      </div>
    </Home>
  );
}
