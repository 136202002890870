import { createStore } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { changeLanguage } from '../i18n';
import reducers from '../reducers';

// eslint-disable-next-line no-undef
const development = process.env.REACT_APP_ENV !== 'production';

const persistConfig = {
  key: 'patient_portal',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const factory = () => {
  const store = createStore(
    persistedReducer,
    development ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : undefined
  );
  const persistor = persistStore(store, null, () => {
    const locale = store.getState().localeReducer.locale;
    changeLanguage(locale);
  });
  return { store, persistor };
};

export const { store, persistor } = factory();

export default factory;
