/* eslint-disable indent */
import {
  ADD_TAGS,
  REMOVE_ALL_TAGS,
  LAST_TRAIN_RT,
  INITIAL_TAGS,
  DEF_FLOWRETURNED,
  LAST_EVENTREG,
} from '../actions/types/tags';

const initialState = {
  tags: [],
};

export default function tagsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TAGS:
      return Object.assign({}, state, {
        tags: action.payload,
      });

    case REMOVE_ALL_TAGS:
      return {
        tags: [],
      };

    case LAST_TRAIN_RT:
      return Object.assign({}, state, {
        last_trainrg: action.payload,
      });

    case INITIAL_TAGS:
      return Object.assign({}, state, {
        initial_tags: action.payload,
      });

    case DEF_FLOWRETURNED:
      return Object.assign({}, state, {
        def_flow: action.payload,
      });

    case LAST_EVENTREG:
      return Object.assign({}, state, {
        last_event: action.payload,
      });

    default:
      return state;
  }
}
