import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';
import routes from './routes';

const ALLOWED_WITHOUT_LOGIN = [
  routes.login,
  routes.forgot_password,
  routes.confirm_password,
  routes.verify_account,
  routes.register,
];
const ALLOWED_WITHOUT_LOGIN_DASHBOARD = [
  routes.login_dashboard,
  routes.dashboard.forgot_password_dashboard,
  routes.dashboard.confirm_password_dashboard,
  routes.register_dashboard,
];

const NOT_ALLOWED_WITH_LOGIN = [
  routes.forgot_password,
  routes.confirm_password,
  routes.register,
];

const NOT_ALLOWED_WITH_LOGIN_DASHBOARD = [
  routes.dashboard.index,
  routes.dashboard.table_dashboard,
  routes.dashboard.settings_dashboard,
  routes.dashboard.statistics_dashboard,
  routes.dashboard.administrate_users_dashboard,
];

const mapStateToProps = (state) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    loggedInDashboard: state.userDashboardReducer.loggedIn,
    locale: state.localeReducer.locale,
  };
};

export function ProtectedRoute({
  component: Component,
  loggedIn,
  loggedInDashboard,
  locale,
  ...props
}) {
  const path = props.path;
  const { t } = useTranslation();

  const COOKIES_DOCUMENTS = {
    no: 'media/documents/Cookies_NO.pdf',
    il: 'media/documents/Cookies_IL.pdf',
    nl: 'media/documents/Cookies_NL.pdf',
    fi: 'media/documents/Cookies_FI.pdf',
    pt: 'media/documents/Cookies_PT.pdf',
    se: 'media/documents/Cookies_SE.pdf',
    de: 'media/documents/Cookies_NL.pdf', //German
    de_ch: 'media/documents/Cookies_NL.pdf', //German
    at: 'media/documents/Cookies_NL.pdf', //German
    en_gb: 'media/documents/Cookies_EN.pdf', //English
    en: 'media/documents/Cookies_EN.pdf', //English
    za: 'media/documents/Cookies_EN.pdf', //English
    es: 'media/documents/Cookies_ES.pdf',
    dk: 'media/documents/Cookies_DK.pdf',
    it_ch: 'media/documents/Cookies_IT_CH.pdf',
    fr_ch: 'media/documents/Cookies_FR_CH.pdf',
    gr: 'media/documents/Cookies_GR.pdf',
  };

  const WrapLayout = () => {
    return (
      <div>
        <Component {...props} />
        <CookieConsent
          location='bottom'
          buttonText={t('cookies.button_ok')}
          cookieName='cookieConsent'
          style={{
            background: '#FFF',
            boxShadow: '5px 5px 10px 2px rgba(0,0,0,.8)',
          }}
          buttonStyle={{
            color: '#1010EB',
            background: 'transparent',
            fontSize: '12px',
            fontWeight: 'bold',
            border: '1px solid #1010EB',
            borderRadius: '2px',
          }}
          contentStyle={{ color: 'black', fontSize: '13px' }}
          expires={60}
        >
          {t('cookies.text')}{' '}
          <a
            href={COOKIES_DOCUMENTS[locale] ? COOKIES_DOCUMENTS[locale] : null}
            style={{ color: '#1010EB', fontWeight: 'bold' }}
          >
            {t('cookies.link')}
          </a>
        </CookieConsent>
      </div>
    );
  };

  if (!loggedIn && ALLOWED_WITHOUT_LOGIN.includes(path)) {
    return <WrapLayout />;
  }

  if (!loggedInDashboard && ALLOWED_WITHOUT_LOGIN_DASHBOARD.includes(path)) {
    return <WrapLayout />;
  }

  if (
    !loggedIn &&
    path !== routes.login &&
    !path.includes(routes.dashboard.index)
  ) {
    return <Redirect push to={routes.login} />;
  }

  if (
    !loggedInDashboard &&
    path !== routes.login_dashboard &&
    path.includes(routes.dashboard.index)
  ) {
    return <Redirect push to={routes.login_dashboard} />;
  }

  if (loggedIn && NOT_ALLOWED_WITH_LOGIN.includes(path)) {
    return <Redirect push to={routes.login} />;
  }

  if (!loggedInDashboard && NOT_ALLOWED_WITH_LOGIN_DASHBOARD.includes(path)) {
    return <Redirect push to={routes.login_dashboard} />;
  }

  if (
    loggedIn &&
    path === routes.login &&
    !path.includes(routes.dashboard.index)
  ) {
    return <Redirect push to={routes.app.index} />;
  }

  if (
    loggedInDashboard &&
    path === routes.login_dashboard &&
    path.includes(routes.dashboard.index)
  ) {
    return <Redirect push to={routes.dashboard.index} />;
  }
  return <WrapLayout />;
}

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  layout: PropTypes.object,
  props: PropTypes.object,
  path: PropTypes.string,
  loggedIn: PropTypes.bool,
  loggedInDashboard: PropTypes.bool,
  locale: PropTypes.string,
};

export default connect(mapStateToProps)(ProtectedRoute);
