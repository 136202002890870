export default [
  {
    stepNumber: '1',
    stepName: 'cvent.steps.boxes',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'CardSelection',
    options: [
      {
        from: '1',
        to: '2',
        title: 'cvent.cards.minimed_step.title',
        label: 'cvent.cards.minimed_step.button',
        image: '/workflow/fi_girl_in_park.jpeg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.minimed_step.description',
        disabled: false,
      },
      {
        from: '1',
        //to: '2',
        title: 'cvent.cards.carelink_step.title',
        label: 'cvent.cards.carelink_step.button',
        image: '/workflow/workshop.jpg',
        device: '/workflow/careLink.png',
        description: 'cvent.cards.carelink_step.description',
        disabled: true,
        skipFlow: true,
        flowTags: [
          {
            key: 'Training',
            value: 'CareLink training',
          },
        ],
      },
      {
        from: '1',
        //to: '2',
        title: 'cvent.cards.follow_up_step.title',
        label: 'cvent.cards.follow_up_step.button',
        image: '/workflow/special-training.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.follow_up_step.description',
        disabled: true,
        skipFlow: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Follow up training',
          },
        ],
      },
      {
        from: '1',
        to: '2',
        title: 'cvent.cards.smartguard_step.title',
        label: 'cvent.cards.smartguard_step.button',
        image: '/workflow/guardian.jpg',
        device: '/workflow/SmartGuard2.jpg',
        description: 'cvent.cards.smartguard_step.description',
        disabled: false,
      },
      /*{
        from: '1',
        //to: '2',
        title: 'cvent.cards.smart_mdi_step.title',
        label: 'cvent.cards.smart_mdi_step.button',
        image: '/workflow/minimed.jpg',
        device: '/workflow/G4S_system.png',
        description: '',
        disabled: false,
        skipFlow: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Smart MDI',
          },
        ],
      },*/
    ],
  },
  {
    stepNumber: '2',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2',
        to: '5',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: '',
      },
      {
        from: '2',
        to: '4',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: '',
      },
    ],
  },
  {
    stepNumber: '4',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '5',
    centered: true,
    stepName: 'cvent.steps.cvent_already_have_experience_with_med_title',
    stepDescription: 'cvent.steps.cvent_already_have_experience_with_med_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '5',
        to: 'Summary',
        title: 'cvent.steps.cvent_already_have_experience_with_med_yes',
        label: 'cvent.steps.cvent_already_have_experience_with_med_yes',
      },
      {
        from: '5',
        to: '4.1',
        title: 'cvent.steps.cvent_already_have_experience_with_med_no',
        label: 'cvent.steps.cvent_already_have_experience_with_med_no',
      },
    ],
  },
  {
    stepNumber: '4.1',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_experience_no',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '7',
    centered: true,
    stepName: 'cvent.steps.cvent_pump_user',
    stepDescription: 'cvent.steps.cvent_pump_user',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '7',
        to: 'Summary',
        title: 'cvent.steps.cvent_17_years',
        label: '',
      },
      {
        from: '7',
        to: 'Summary',
        title: 'cvent.steps.cvent_18_years',
        label: '',
      },
    ],
  },
];
