import ImportantInformationWithOverlay from '../components/important-information/ImportantInformationWithOverlay';
import WorkflowBuilder from '../components/workflow/WorkflowBuilder';

export default function WorkflowScreen() {
  return (
    <div className='relative'>
      <ImportantInformationWithOverlay
        showCloseButton={true}
        showContinueButton={true}
      />
      <WorkflowBuilder />
    </div>
  );
}
