/* eslint-disable */
import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Geocode from 'react-geocode';
import { CloseIcon } from '../important-information/icons/Close';
import Button from '../button/Button';
import Config from '../../config';
import BoxShadow from '../box-shadow/BoxShadow';

Geocode.setApiKey(Config.API_KEY);

const containerStyle = {
  position: 'relative',
  height: '100%',
};

export default function SingleEventModal({
  eventdata,
  google,
  locale,
  handleClose,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const [location, setLocation] = useState();
  const shouldUpdateLocation = eventdata && eventdata.lat && eventdata.lng;

  const center = {
    lat: Number(eventdata.lat),
    lng: Number(eventdata.lng),
  };

  useEffect(() => {
    Geocode.setLanguage(locale);
    Geocode.setRegion(locale);

    if (shouldUpdateLocation) {
      Geocode.fromLatLng(eventdata.lat, eventdata.lng).then(
        (response) => {
          if (response.results[0].formatted_address) {
            const formattedAddressArray =
              response.results[0].formatted_address.split(' ');

            const getCity =
              formattedAddressArray[formattedAddressArray.length - 2];
            const getCountry =
              formattedAddressArray[formattedAddressArray.length - 1];
            eventdata.isOnline === true
              ? setLocation(`${getCountry}`)
              : setLocation(`${getCity} ${getCountry}`);
          }
        },
        (error) => {
          setLocation(eventdata.location);
          console.error(error);
        }
      );
    }
  }, [locale, eventdata]);

  function beautifyEventTime(timeUnit) {
    return String(timeUnit).padStart(2, '0');
  }
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: Config.API_KEY,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(14);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <BoxShadow className='m-auto lg:w-4/5'>
      <div className='relative flex flex-col font-bold text-primary'>
        <div className='absolute top-0 right-0'>
          {handleClose && (
            <CloseIcon
              onClick={handleClose}
              // className='absolute top-0 right-0 w-20 h-20 -mt-16 cursor-pointer '
              className='w-16 h-16 cursor-pointer'
            />
          )}
        </div>

        <div>
          {eventdata.id && (
            <div className='flex justify-center p-3'>
              {eventdata.attendees.length >= eventdata.attendeeLimit ? (
                <p>{t('calendar.no_slots_available')}</p>
              ) : (
                <Button
                  className='mb-2'
                  eventdata={eventdata}
                  onClick={() =>
                    history.push({
                      pathname: `/app/calendar/register/${eventdata.id}`,
                      state: eventdata,
                    })
                  }
                >
                  {t('register_button')}
                </Button>
              )}
            </div>
          )}
          {!eventdata.isOnline && (
            <div>
              <h1 className='text-xl'>{eventdata.title}</h1>
              <div className='flex flex-col items-start mt-4'>
                {eventdata.locationName && (
                  <h1>
                    {' '}
                    {eventdata.locationName},{' '}
                    {beautifyEventTime(eventdata.start.getHours())}:
                    {beautifyEventTime(eventdata.start.getMinutes())}-
                    {beautifyEventTime(eventdata.end.getHours())}:
                    {beautifyEventTime(eventdata.end.getMinutes())}
                  </h1>
                )}
                {!eventdata.locationName && (
                  <h1>
                    {' '}
                    {beautifyEventTime(eventdata.start.getHours())}:
                    {beautifyEventTime(eventdata.start.getMinutes())}-
                    {beautifyEventTime(eventdata.end.getHours())}:
                    {beautifyEventTime(eventdata.end.getMinutes())}
                  </h1>
                )}
                <p>{location ? location : eventdata.location}</p>
                {eventdata.locationName && <span className='mt-4'></span>}
              </div>
            </div>
          )}
          {eventdata.isOnline && (
            <div>
              <h1 className='text-xl'>{eventdata.title}</h1>
              <div className='flex flex-col items-start mt-4'>
                <div>
                  {' '}
                  {beautifyEventTime(eventdata.start.getHours())}:
                  {beautifyEventTime(eventdata.start.getMinutes())}-
                  {beautifyEventTime(eventdata.end.getHours())}:
                  {beautifyEventTime(eventdata.end.getMinutes())}
                </div>
                <p>{eventdata?.locationName}</p>
              </div>
            </div>
          )}
          {eventdata.lat &&
            eventdata.lng &&
            !eventdata.isOnline &&
            isLoaded && (
              <div className='w-full h-48 md:block md:my-4'>
                <GoogleMap
                  google={google}
                  mapContainerStyle={containerStyle}
                  center={center}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                >
                  <Marker
                    position={{
                      lat: Number(eventdata.lat),
                      lng: Number(eventdata.lng),
                    }}
                  />
                </GoogleMap>
              </div>
            )}
        </div>
      </div>
    </BoxShadow>
  );
}

export const Single = SingleEventModal;

SingleEventModal.propTypes = {
  eventdata: PropTypes.object.isRequired,
  google: PropTypes.object.isRequired,
  locale: PropTypes.string,
  handleClose: PropTypes.func,
};

SingleEventModal.defaultProps = {
  eventdata: {},
  google: {},
};
