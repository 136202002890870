export default [
  {
    stepNumber: '1',
    stepName: 'cvent.steps.boxes',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'CardSelection',
    options: [
      {
        from: '1',
        to: '2',
        title: 'cvent.cards.se_first_step.title',
        label: 'cvent.cards.se_first_step.button',
        image: '/workflow/minimed.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.se_first_step.description',
        disabled: false,
      },
      {
        from: '1',
        //to: '2',
        title: 'cvent.cards.se_smartGuard_step.title',
        label: 'cvent.cards.se_smartGuard_step.button',
        image: '/workflow/guardian.jpg',
        device: '/workflow/smartGuard.png',
        description: 'cvent.cards.se_smartGuard_step.description',
        disabled: false,
        skipFlow: true,
        flowTags: [
          {
            key: 'Training',
            value: 'SmartGuard',
          },
        ],
      },
      {
        from: '1',
        //to: '2',
        title: 'cvent.cards.se_carelink_step.title',
        label: 'cvent.cards.se_carelink_step.button',
        image: '/workflow/CGMTraining.jpg',
        device: '/workflow/careLink.png',
        description: 'cvent.cards.se_carelink_step.description',
        disabled: false,
        skipFlow: true,
        flowTags: [
          {
            key: 'Training',
            value: '780G system',
          },
        ],
      },
      {
        from: '1',
        //to: '2',
        title: 'cvent.cards.se_minimed_step.title',
        label: 'cvent.cards.se_minimed_step.button',
        image: '/workflow/minimed-se.png',
        device: '/workflow/careLink.png',
        description: 'cvent.cards.se_minimed_step.description',
        disabled: false,
        skipFlow: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Tips and Tricks',
          },
        ],
      },
      {
        from: '1',
        //to: '2',
        title: 'cvent.cards.pensystem_step.title',
        label: 'cvent.cards.pensystem_step.button',
        image: '/workflow/se_pennysystem.jpg',
        device: '/workflow/smartGuard.png',
        description: 'cvent.cards.pensystem_step.description',
        disabled: false,
        skipFlow: true,
        flowTags: [
          {
            key: 'Training',
            value: 'pennsystem',
          },
        ],
      },
      {
        from: '1',
        //to: '2',
        title: 'cvent.cards.tematraffar_step.title',
        label: 'cvent.cards.tematraffar_step.button',
        image: '/workflow/se_tematraffar.jpg',
        device: '/workflow/smartGuard.png',
        description: 'cvent.cards.tematraffar_step.description',
        disabled: false,
        skipFlow: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Theme',
          },
        ],
      },
      // {
      //   from: '1',
      //   //to: '2',
      //   title: 'cvent.cards.minimed_step.title',
      //   label: 'cvent.cards.minimed_step.button',
      //   image: '/workflow/se_minimed.jpg',
      //   device: '/workflow/smartGuard.png',
      //   description: 'cvent.cards.minimed_step.description',
      //   disabled: false,
      //   skipFlow: true,
      //   flowTags: [
      //     {
      //       key: 'Training',
      //       value: 'Preparation',
      //     },
      //   ],
      // },
      /*{
        from: '1',
        //to: '2',
        title: 'cvent.cards.se_smart_insulin_pen.title',
        label: 'cvent.cards.se_smart_insulin_pen.button',
        image: '/workflow/smartPen.png',
        //device: '/workflow/G4S_system.png',
        description: 'cvent.cards.se_smart_insulin_pen.description',
        disabled: true,
        skipFlow: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Smart Insulin Pen',
          },
        ],
      },*/
    ],
  },
  {
    stepNumber: '2',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2',
        to: '7',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: '',
      },
      {
        from: '2',
        to: '4',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: '',
      },
    ],
  },
  {
    stepNumber: '4',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '7',
    centered: true,
    stepName: 'cvent.steps.medtronic_age_question',
    stepDescription: 'cvent.steps.medtronic_age_question',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '7',
        // to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Pump',
          },
          {
            key: 'Age',
            value: 'Child',
          },
        ],
        title: 'cvent.steps.cvent_check_if_age_under_17',
        label: '',
      },
      {
        from: '7',
        // to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Pump',
          },
          {
            key: 'Age',
            value: 'Adult',
          },
        ],
        title: 'cvent.steps.cvent_check_if_age_above_18',
        label: '',
      },
    ],
  },
];
