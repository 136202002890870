/* eslint-disable indent */
import { SET_FLOW_FORM } from '../actions/types/flowForm';

const initialState = { flowForm: {} };

export default function flowFormReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FLOW_FORM:
      return Object.assign({}, state, {
        flowForm: action.payload,
      });

    default:
      return state;
  }
}
