import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ToastContainer, toast } from 'react-toastify';
import Login from '../components/login/Login';
import Auth from '../helpers/Auth';
import User from '../schema/User';
import Config from '../config';
import routes from '../helpers/routes';
import Loading from '../components/loading-indicator/Loading';
import Home from '../components/container/Home';
import { setUserLoggedIn } from '../actions/user';

const mapDispatchToProps = (dispatch) => ({
  dispatchLoggedIn: (loggedIn) => dispatch(setUserLoggedIn(loggedIn)),
});

export function Loginscreen(props) {
  const data = {
    username: props.username,
    password: props.password,
    clientId: Config.CLIENT_ID,
  };
  const [login] = useMutation(User.LOGIN, { variables: { input: data } });
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [invalidCred, setinvalidCred] = useState(false);

  const history = useHistory();

  const handleClick = async () => {
    if (loading) {
      return <Loading />;
    }

    setLoading(true);
    try {
      const data = {
        username: username,
        password: password,
        clientId: Config.CLIENT_ID,
      };
      const ret = await login({ variables: { input: data } });
      const loginResponse = ret.data && ret.data.loginToken;
      if (loginResponse && loginResponse.__typename === User.TYPE_LOGIN_TOKEN) {
        const lt = loginResponse;
        if (lt.accessToken && lt.refreshToken && lt.expiresIn) {
          const promise1 = setLoading(false);
          const promise2 = await Auth.setTokens(
            lt.accessToken,
            lt.refreshToken,
            lt.rememberMe
          );
          const promise3 = props.dispatchLoggedIn(true);

          Promise.all([promise1, promise2, promise3]).then(() => {
            history.push(routes.app.index);
          });
        } else {
          setLoading(false);
          toast.error('unknown_error1', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      } else if (loginResponse.__typename === User.TYPE_LOGIN_ERROR) {
        const le = loginResponse;
        if (le.error == 'invalid_grant') {
          setinvalidCred(true);
          console.log(invalidCred);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(le.errorDescription, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      } else {
        setLoading(false);
        toast.error('unknown_error2', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (event) {
      setLoading(false);
      toast.error('Something went wrong! Please, try again later.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  return (
    <Home>
      <ToastContainer />
      <Login
        loading={loading}
        invalidCred={invalidCred}
        setPassword={setPassword}
        setUsername={setUsername}
        password={password}
        username={username}
        handleClick={handleClick}
      />
    </Home>
  );
}

Loginscreen.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  dispatchLoggedIn: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(Loginscreen);
