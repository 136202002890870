import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  progressContainer: {
    width: '100%',
    textAlign: 'center'
  },
  progress: {
    fontSize: '8px',
    position: 'relative',
    textAlign: 'center',
    display: 'inline-block',
    width: '5em',
    height: '5em',
    margin: '0 auto',
    textIndent: '999em',
    overflow: 'hidden',
    animation: 'spin 1s infinite steps(8)',
    '&:before, &:after, & > div:before, & > div:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '2.25em', /* (container width - part width)/2  */
      width: '.5em',
      height: '1.5em',
      background: '#eee',
      boxShadow: '0 3.5em #eee', /* container height - part height */
      transformOrigin: '50% 2.5em', /* container height / 2 */
    },
    '&:before': {
      background: '#555'
    },
    '&:after': {
      transform: 'rotate(-45deg)',
      backround: '#777'
    },
    '& > div:before': {
      transform: 'rotate(-90deg)',
      background: '#999'
    },
    '& > div:after': {
      transform: 'rotate(-135deg)',
      background: '#bbb'
    }
  },
  '@keyframes spin': {
    'to': { transform: 'rotate(1turn)' }
  }
}));

export default function Loading({className}) {
  const { t } = useTranslation();
  const classes = useStyles();
  let classNames = ['progress-container', className].join(' ');

  return (
    <div className={classNames}>
      <div className={classes.progress}>
        <div>{t('global.loading')}</div>
      </div>
    </div>
  );
}

Loading.propTypes = {
  className: PropTypes.string
};

export function LoadingFullScreenCentered() {
  return (
    <Loading className="fixed top-1/2 left-1/2" />
  );
}