import { SET_PATHWAY, FETCH_PATHWAY, SET_BOX } from './types/pathway';

export const setPathway = pathway => ({
  type: SET_PATHWAY,
  payload: pathway,
});

export const fetchPathway = () => ({
  type: FETCH_PATHWAY,
});

export const setBox = box => ({
  type: SET_BOX,
  payload: box,
});
