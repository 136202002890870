/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Popup from './popup/Popup';
import Card from '../card/Card';
import Dialog from '../dialog/Dialog';
import DialogFlowBoxAlert from '../dialog/DialogFlowBoxAlert';
import routes from '../../helpers/routes';
import RegisterFollowUp from '../calendar/follow-up-modal/RegisterFollowUp';
import { store } from '../../store/redux';

import { addTags } from '../../actions/tags';
import { setBox } from '../../actions/pathway';

const mapDispatchToProps = (dispatch) => ({
  dispatchTags: (tags) => dispatch(addTags(tags)),
  dispatchBox: (box) => dispatch(setBox(box)),
});

const mapStateToProps = (state) => {
  return {
    boxStore: state.pathwayReducer.box,
    last_trainrg: state.tagsReducer.last_trainrg,
    pathway: state.pathwayReducer.pathway,
  };
};
export function CardSelection({
  componentType,
  handleClick,
  options,
  boxStore,
  last_trainrg,
  pathway,
  dispatchTags,
  dispatchBox,
}) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const handleOpen = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(!open);
  };

  const cardSelected = store.getState().pathwayReducer.box;

  const handleResumePathway = (finalObject, box) => {
    if (
      (pathway?.id.length > 0 && pathway !== null) ||
      (last_trainrg?.length > 0 && last_trainrg !== null)
    ) {
      handleOpen();
    } else {
      if (box?.skipFlow == true) {
        //! from box to calendar directly
        try {
          dispatchTags(box.flowTags);
        } catch (err) {
          console.log('err', err);
        } finally {
          history.push(routes.calendar);
        }
      } else {
        handleClick(box);
      }
    }
  };
  const toCalendar = useCallback(() => {
    history.replace(routes.calendar);
  }, [history]);

  return (
    <div className='md:px-5'>
      <br />
      <div className='text-3xl text-electric-blue'>
        <p>{t('cvent.cards.highlight')}</p>
        <p>{t('cvent.cards.title')}</p>
      </div>
      <br />
      {options.map((box, index) => {
        if (box.alert) {
          return (
            <DialogFlowBoxAlert
              open={open}
              key={index}
              className='shadow-3xl 0'
              handleClose={() => handleOpen()}
              render={
                <Popup
                  text={t(box.alert)}
                  onClick={() => handleClick(cardSelected)}
                />
              }
            />
          );
        }
      })}

      {((pathway?.id.length > 0 && pathway !== null) ||
        (last_trainrg?.length > 0 && last_trainrg !== null)) && (
        <Dialog
          className='justify-center'
          open={open}
          handleClose={handleClose}
          render={
            <RegisterFollowUp
              text={t('register.attendee.pathway_must_continue')}
              textBtn={t('register.attendee.pathway_continue_button')}
              handleNext={toCalendar}
            />
          }
        />
      )}

      <div className='grid grid-cols-1 gap-4 px-4 mx-auto md:grid-cols-2 lg:grid-cols-3'>
        {options.map((box, index) => {
          const finalObject = { componentType: componentType, ...box };

          return (
            <Card
              box={box}
              key={index}
              handleClick={() => {
                dispatchBox(box);
                box.alert
                  ? handleOpen()
                  : handleResumePathway(finalObject, box);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

CardSelection.propTypes = {
  componentType: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  pathway: PropTypes.any,
  last_trainrg: PropTypes.any,
  boxStore: PropTypes.any,
  dispatchTags: PropTypes.func,
  dispatchBox: PropTypes.func,
};

CardSelection.defaultProps = {
  componentType: '',
  options: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(CardSelection);
