import React from 'react';
import PropTypes from 'prop-types';
import HTMLReactParser from 'html-react-parser';

const HIGHLIGHT_MARKER = /\*\*\*(\S[^*]+\S)\*\*\*/g;

const styles = {
  inputField: {
    width: '100%',
  },
};

function Label(props) {
  const { children, label, ...rest } = props;
  return (
    <div style={styles.inputField}>
      <div id='lbl' {...rest}>
        {HTMLReactParser(
          label.replace(
            HIGHLIGHT_MARKER,
            '<span class="text-darkGreen font-normal">$1</span>'
          )
        )}
      </div>
      {children}
    </div>
  );
}

Label.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  styling: PropTypes.object,
};

export default Label;
