import PropTypes from 'prop-types';
import Label from '../label/Label';

export default function FormInformation({
  title,
  description,
  helper,
  required,
}) {
  return (
    <div>
      {title && (
        <Label
          className='text-yellow uppercase font-bold text-2xl leading-tight whitespace-pre-wrap'
          label={title}
        />
      )}
      {description && (
        <Label
          className='text-base leading-tight whitespace-pre-wrap'
          label={description}
        />
      )}
      {helper && <Label className='pt-10 pb-10 font-bold' label={helper} />}
      {required && <Label label={required} />}
    </div>
  );
}

FormInformation.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  helper: PropTypes.string,
  required: PropTypes.string,
};
