import { gql } from '@apollo/client';

const GET_HOSPITALS = gql`
  query {
    hospitals {
      name
      address
      zip
      town
    }
  }
`;

const SEND_FORM_NL = gql`
  mutation formCventFlow($input: CventFormInput!) {
    formCventFlow(input: $input) {
      response
    }
  }
`;

const CventForm = {
  GET_HOSPITALS,
  SEND_FORM_NL,
};

export default CventForm;
