export default {
  verify_account: '/verifyAccount/:token',
  confirm_password: '/confirmPassword/:token',
  forgot_password: '/forgot-password',
  register: '/register',
  login: '/',
  app: {
    index: '/app',
    settings: {
      index: '/app/settings',
    },
  },
  calendar: '/app/calendar',
  calendar_register: '/app/calendar/register/:id',
  important_information: '/app/important-information',
  my_events: '/app/my-events',
  dashboard: {
    index: '/admin',
    table_dashboard: '/admin/table',
    settings_dashboard: '/admin/settings',
    statistics_dashboard: '/admin/statistics',
    jira_dashboard_ticket_create: '/admin/jiraticketcreate',
    jira_dashboard_ticket_list: '/admin/jiraticketlist',
    administrate_users_dashboard: '/admin/administrate_users_dashboard',
    confirm_password_dashboard: '/admin/confirmPassword/:token',
    forgot_password_dashboard: '/admin/forgot-password',
    settings: {
      index: '/admin/settings',
    },
  },
  login_dashboard: '/admin/login',
  register_dashboard: '/admin/register',
};
