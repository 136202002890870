import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LeftArrowIcon } from '../icons/LeftArrow';

export function Layout({ heading, children, handleBack, className }) {
  const { t } = useTranslation();
  return (
    <div className='m-auto mb-10 shadow-3xl xs:w-full md:w-11/12 lg:w-3/4'>
      <div className='grid md:grid-cols-2 md:grid-rows-1 xs:grid-cols-1 '>
        <div className='self-start w-full h-full text-white bg-navy-blue'>
          {/* <div className={'p-5 ' + `${'h-[calc(100vh-74px)]'}`}>{heading}</div> */}
          <div className='p-5 '>{heading}</div>
        </div>
        <div className={['lg:mt-0 ', className].join(' ')}>{children}</div>
      </div>

      <div className='grid grid-cols-1 grid-rows-1 py-3 pl-6 bg-teal xs:w-full md:w-2/4'>
        <button
          onClick={handleBack}
          className='flex items-center cursor-pointer text-dark-grey-body'
        >
          <LeftArrowIcon className='w-6 h-6 mr-5 text-dark-grey-body' />
          {t('previous')}
        </button>
      </div>
    </div>
  );
}

Layout.propTypes = {
  heading: PropTypes.node,
  children: PropTypes.node,
  handleBack: PropTypes.func,
  className: PropTypes.string,
};

export default Layout;
