import PropTypes from 'prop-types';
export function CloseIcon({ className, ...props }) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      className={['fill-current', className].join(' ')}
      {...props}
    >
      <defs>
        <style>
          {
            '.cls-1{fill:#00dcb9;stroke:#ffffff;stroke-miterlimit:10;stroke-width:3px;}.cls-2{fill:#00dcb9; stroke:#rgba(0,0,0,0); stroke-width:1px;}'
          }
        </style>
      </defs>
      <g>
        <title>background</title>
        <rect
          fill='none'
          id='canvas_background'
          height='602'
          width='802'
          y='-1'
          x='-1'
        />
      </g>
      <g>
        <title>Layer 1</title>
        <circle id='svg_1' r='20' cy='25.51' cx='25.51' className='cls-2' />
        <line
          id='svg_2'
          y2='34.08'
          x2='34.08'
          y1='16.94'
          x1='16.94'
          className='cls-1'
        />
        <line
          id='svg_3'
          y2='16.94'
          x2='34.08'
          y1='34.08'
          x1='16.94'
          className='cls-1'
        />
      </g>
    </svg>
  );
}

CloseIcon.propTypes = {
  className: PropTypes.string,
};
