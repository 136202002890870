/* eslint-disable  */

import React, { lazy } from 'react';
import { connect } from 'react-redux';

export function extraFields() {
  return {
    adminDashboard: {
      tableFields: [
        {
          id: 'extraForm.za_whos_your_doctor',
          label: 'Who is her/his doctor',
          minWidth: 170,
        },
      ],
    },
  };
}
