import { useState, useEffect } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import routes from '../helpers/routes';
import Auth from '../helpers/Auth';
import Config from '../config';
// import SnackbarOpenOption from '../components/header/SnackbarOpenOption';
import User from '../schema/User';
import Country from '../components/forms/inputs/Country';
import Loading from '../components/loading-indicator/Loading';
import Text from '../components/forms/inputs/Text';
import Button from '../components/button/Button';
import Link from '../components/link/Link';
import Home from '../components/container/Home';
import BoxShadow from '../components/box-shadow/BoxShadow';
import { setUserLoggedIn } from '../actions/user';
import Checkbox from '../components/material-ui/validators/CheckboxValidator';
import CheckboxWithDocuments from '../components/material-ui/validators/CheckboxWithDocuments';
import Label from '../components/label/Label';

const mapDispatchToProps = (dispatch) => ({
  dispatchLoggedIn: (loggedIn) => dispatch(setUserLoggedIn(loggedIn)),
});

const mapStateToProps = (state) => {
  return {
    locale: state.localeReducer.locale,
  };
};

const PRIVACY_DOCUMENTS = {
  en: 'media/documents/Privacy_Statement_EN.pdf',
  de: 'media/documents/Privacy_Statement_DE_DE.pdf',
  nl: 'media/documents/Privacy_Statement_NL_NL.pdf',
  at: 'media/documents/Privacy_Statement_AT_AT.pdf',
  it_ch: 'media/documents/Privacy_Statement_IT_CH.pdf',
  fr_ch: 'media/documents/Privacy_Statement_FR_CH.pdf',
  de_ch: 'media/documents/Privacy_Statement_DE_CH.pdf',
  en_gb: 'media/documents/Privacy_Statement_GB.pdf',
  es: 'media/documents/Privacy_Statement_ES.pdf',
  fi: 'media/documents/Privacy_Statement_FI.pdf',
  za: 'media/documents/Privacy_Statement_ZA.pdf',
  se: 'media/documents/Privacy_Statement_SE.pdf',
  dk: 'media/documents/Privacy_Statement_DK.pdf',
  pl: 'media/documents/Privacy_Statement_EN.pdf',
  pt: 'media/documents/Privacy_Statement_PT.pdf',
  gr: 'media/documents/Privacy_Statement_EL.pdf',
  il: 'media/documents/Privacy_Statement_EN.pdf',
  no: 'media/documents/Privacy_Statement_NO.pdf',
};

const GDPR_DOCUMENTS = {
  en: 'media/documents/Terms_and_Conditions_EN.pdf',
  de: 'media/documents/Terms_and_conditions_DE.pdf',
  nl: 'media/documents/Terms_and_conditions_NL.pdf',
  at: 'media/documents/Terms_and_conditions_AT.pdf',
  it_ch: 'media/documents/Terms_and_conditions_IT_CH.pdf',
  fr_ch: 'media/documents/Terms_and_conditions_FR_CH.pdf',
  de_ch: 'media/documents/Terms_and_conditions_DE_CH.pdf',
  en_gb: 'media/documents/Terms_and_Conditions_GB.pdf',
  es: 'media/documents/Terms_and_conditions_ES.pdf',
  fi: 'media/documents/Terms_and_conditions_FI.pdf',
  za: 'media/documents/Terms_and_Conditions_ZA.pdf',
  se: 'media/documents/Terms_and_Conditions_SE.pdf',
  dk: 'media/documents/Terms_and_Conditions_DK.pdf',
  pl: 'media/documents/Terms_and_Conditions_EN.pdf',
  pt: 'media/documents/Terms_and_conditions_PT.pdf',
  gr: 'media/documents/Terms_and_Conditions_EL.pdf',
  il: 'media/documents/Terms_and_Conditions_EN.pdf',
  no: 'media/documents/Terms_and_Conditions_NO.pdf',
};

export function RegisterScreen({ locale, dispatchLoggedIn }) {
  const { t } = useTranslation();
  const [dataRegister, setDataRegister] = useState({
    email: '',
    password: '',
    country: {
      name: '',
      alpha2: '',
    },
  });

  const dataLogin = {
    username: '',
    password: '',
    clientId: Config.CLIENT_ID,
  };
  const [register] = useMutation(User.REGISTER);
  const [login] = useMutation(User.LOGIN, { variables: { input: dataLogin } });
  const history = useHistory();
  const [repeatPassword, setRepeatPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [termsCond, setTermsCond] = useState(false);
  const [gdpr, setGDPR] = useState(false);
  const [ageConfirmation, setAgeConfirmation] = useState(false);
  // const [openSnackBar, setOpenSnackBar] = useState(false);
  // const [severity, setSeverity] = useState('error');
  // const [title, setTitle] = useState(t('registration.error'));

  useEffect(() => {
    ValidatorForm.addValidationRule('isTruthy', (value) => value);
    ValidatorForm.addValidationRule('isDefaultValue', (value) => {
      if (value === 'defaultValue') {
        return false;
      }
      return true;
    });
  });

  const handleClick = async (e) => {
    e.preventDefault();

    if (!dataRegister.country.alpha2) {
      return;
    }

    if (loading) {
      return <Loading />;
    }

    setLoading(true);

    try {
      if (repeatPassword === dataRegister.password ? false : true) {
        throw new Error(t('registration.password_not_match'));
      }

      const dataInputRegister = {
        username: dataRegister.email,
        password: dataRegister.password,
        name: dataRegister.name,
        country_code: dataRegister.country.alpha2,
        tzname: Config.DEFAULT_TZ,
      };

      await register({ variables: { input: dataInputRegister } });
      const dataInputLogin = {
        username: dataRegister.email,
        password: dataRegister.password,
        clientId: Config.CLIENT_ID,
      };
      const dataOutputLogin = await login({
        variables: { input: dataInputLogin },
      });
      if (
        !dataOutputLogin.data ||
        dataOutputLogin.data.loginToken.__typename === User.TYPE_LOGIN_ERROR
      ) {
        toast.error(t('login.error'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        // setTitle(t('login.error'));
        // setOpenSnackBar(true);
        return;
      }

      await Auth.setTokens(
        dataOutputLogin.data.loginToken.refreshToken,
        dataOutputLogin.data.loginToken.accessToken,
        dataOutputLogin.data.loginToken.rememberMe
      );

      history.push(routes.app.index);
      console.log('Cloudwatch Register');
      dispatchLoggedIn(true);
    } catch (e) {
      if (e.message.includes('ER_DUP_ENTRY')) {
        // setTitle(t('registration.account_exists'));
        toast.info(t('registration.account_exists'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        // setTitle(e.message);
        toast.error(e.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }

      // setSeverity('warning');
      // setOpenSnackBar(true);
      setLoading(false);
    }
  };

  return (
    <Home>
      <ToastContainer />
      <div className='xs:w-full md:w-3/4  mx-auto xs:mt-2'>
        <h1 className='text-4xl text-electric-blue'>{t('login.welcome')}</h1>
        <br />
        <p className='font-bold text-dark-grey-body text-lg lg:w-3/4'>
          {t('login.description')}
        </p>
        <br />
        <BoxShadow>
          <ValidatorForm
            className='flex flex-col items-center'
            onSubmit={handleClick}
            noValidate
          >
            <Label
              label={t('sign_up')}
              className='font-bold text-primary self-start text-sm'
            />

            <Text
              name='email'
              value={dataRegister.email}
              validators={['required', 'isEmail']}
              errorMessages={[
                t('registration_error_required'),
                t('registration_error_valid_email'),
              ]}
              onChange={(e) => {
                setDataRegister({ ...dataRegister, email: e });
              }}
              disabled={loading}
              placeholder={t('email_address')}
              autoFocus
            />
            <Text
              name='password'
              value={dataRegister.password}
              type='password'
              validators={['required']}
              errorMessages={[t('registration_error_required')]}
              onChange={(e) => {
                setDataRegister({ ...dataRegister, password: e });
              }}
              disabled={loading}
              placeholder={t('password')}
            />
            <Text
              name='passwordRepeat'
              value={repeatPassword}
              type='password'
              onChange={(e) => {
                setRepeatPassword(e);
              }}
              validators={['required']}
              errorMessages={[t('registration_error_required')]}
              error={
                repeatPassword &&
                dataRegister.password &&
                repeatPassword !== dataRegister.password
                  ? true
                  : false
              }
              helperText={
                repeatPassword &&
                dataRegister.password &&
                repeatPassword !== dataRegister.password
                  ? t('registration.password_not_match')
                  : ''
              }
              disabled={loading}
              placeholder={t('repeat_password')}
            />
            <Country
              defaultLabel={true}
              name='country'
              validators={['isDefaultValue', 'required']}
              errorMessages={[t('registration_error_required')]}
              value={dataRegister.country.alpha2}
              onChange={(country) =>
                setDataRegister({
                  ...dataRegister,
                  country: { alpha2: country },
                })
              }
              disabled={loading}
            />
            <div className='w-full flex flex-col justify-start mt-2 mb-14'>
              <CheckboxWithDocuments
                label={t('terms_and_conditions')}
                value={termsCond}
                link={
                  PRIVACY_DOCUMENTS[locale] ? PRIVACY_DOCUMENTS[locale] : null
                }
                onChange={() => {
                  termsCond === true ? setTermsCond(false) : setTermsCond(true);
                }}
                disabled={loading}
                validators={['isTruthy']}
                errorMessages={[t('registration_error_required')]}
              />
              <CheckboxWithDocuments
                label={t('register.gdpr')}
                value={gdpr}
                onClick={() => {
                  gdpr === true ? setGDPR(false) : setGDPR(true);
                }}
                link={GDPR_DOCUMENTS[locale] ? GDPR_DOCUMENTS[locale] : null}
                disabled={loading}
                validators={['isTruthy']}
                errorMessages={[t('registration_error_required')]}
              />
              <Checkbox
                label={t('registration.age_confirmation')}
                value={ageConfirmation}
                onClick={() => {
                  setAgeConfirmation(!ageConfirmation);
                }}
                disabled={loading}
                validators={['isTruthy']}
                errorMessages={[t('registration_error_required')]}
              />
            </div>
            <div className='flex flex-col self-start'>
              <Button
                type='submit'
                className='rounded-full text-dark-grey-body px-4'
                disabled={loading}
              >
                {t('login_register_button')}
              </Button>
              <div className='mt-5'>
                <Link
                  onClick={() => {
                    history.push(routes.login);
                  }}
                >
                  {t('registration_existing_account')}
                </Link>
              </div>
            </div>
          </ValidatorForm>
        </BoxShadow>
      </div>

      {/* {openSnackBar && (
        <SnackbarOpenOption
          open={openSnackBar}
          severity={severity}
          title={title}
          setOpen={setOpenSnackBar}
        />
      )} */}
    </Home>
  );
}

RegisterScreen.propTypes = {
  locale: PropTypes.string,
  dispatchLoggedIn: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterScreen);
