import React from 'react';
import PropTypes from 'prop-types';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const COBALT_BLUE = '#007CBD';
const MEDTRONIC_BLUE = '#021e46';
const LIGHT_GRAY = '#B1B3B3';
const NAVY_BLUE = '#001E46';
const GREEN = '#77BC1F';
const LINKS_BLUE = '#1010eb';

export const theme = createTheme({
  palette: {
    primary: { main: COBALT_BLUE },
    secondary: { main: MEDTRONIC_BLUE },
    lightGray: { main: LIGHT_GRAY },
    navyBlue: NAVY_BLUE,
    linksBlue: LINKS_BLUE,
    green: GREEN,
  },
  typography: {
    fontSize: 11,
    fontFamily: '"EffraRegular", sans-serif',
    fontWeight: '550',
    h1: {
      color: NAVY_BLUE,
      fontSize: '1.5rem',
    },
  },
});

const Theme = function ({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

Theme.propTypes = {
  children: PropTypes.node,
};

export default Theme;
