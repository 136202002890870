/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextFrame from './frames/TextFrame';
import Previous from '../../components/button/Previous';

import atpic1 from '../../assets/at/flow/barcode_apple_inpen.png';
import atpic2 from '../../assets/at/flow/barcode_apple_guardian.png';
import atpic3 from '../../assets/at/flow/barcode_google_inpen.png';
import atpic4 from '../../assets/at/flow/barcode_google_guardian.png';

function InformativeSelection({ options, stepName, handleBack }) {
  const { t } = useTranslation();

  const divWrapperClassesTable = 'border-solid border-6 border-medtronic';
  const imgWrapperClasses = 'object-contain inline-block h-44 w-96';

  //Split func used to separate 2 sentences of the step name to add images between them in AT.
  const v1 = t(stepName).split('000000', 1);
  const v2 = t(stepName).split(v1 + '000000', 2);

  return (
    <div>
      {options.some((e) => e.ATDisplayQRCode !== true) && (
        <TextFrame render={t(stepName)} handleBack={handleBack} />
      )}
      {options.some((e) => e.ATDisplayQRCode === true) && (
        <div
          handleBack={handleBack}
          className='flex flex-col mt-5 bg-sky-blue lg:flex-row lg:mt-10'
        >
          <div className='flex flex-col justify-between w-full p-2 lg:p-5 lg:w-4/4 '>
            <td className={divWrapperClassesTable}>
              <p className='text-base font-thin text-primary lg:text-xl'>
                {v1}
              </p>
              <br />
              <div className='flex space-x-5'>
                <img className={imgWrapperClasses} src={atpic1} alt='atpic1' />
                <img className={imgWrapperClasses} src={atpic2} alt='atpic2' />
                <img className={imgWrapperClasses} src={atpic3} alt='atpic3' />
                <img className={imgWrapperClasses} src={atpic4} alt='atpic4' />
              </div>
              <br />
              <br />
              <p className='text-base font-thin text-primary lg:text-xl'>
                {v2}
              </p>
              <br />
              <div className='self-start'>
                <Previous onClick={handleBack}>{t('previous')}</Previous>
              </div>
            </td>
          </div>
        </div>
      )}
    </div>
  );
}

export default InformativeSelection;

InformativeSelection.propTypes = {
  stepImages: PropTypes.array,
  options: PropTypes.array.isRequired,
  stepName: PropTypes.string.isRequired,
  componentType: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  handleBack: PropTypes.func,
};

InformativeSelection.defaultProps = {
  stepImages: [],
  options: [],
  stepName: '',
  componentType: '',
};
