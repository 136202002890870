import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Entry from './Entry';
import routes from '../../helpers/routes';
import { removeAllTags } from '../../actions/tags';
import Auth from '../../helpers/Auth';
import Container from '../container/Container';
import { setUser, setUserLoggedIn } from '../../actions/user';

const mapDispatchToProps = (dispatch) => ({
  removeAllTags: () => dispatch(removeAllTags()),
  logout: () => dispatch(setUserLoggedIn(false)),
  setUser: () => dispatch(setUser({})),
});

const mapStateToProps = (state) => {
  return {
    last_trainrg: state.tagsReducer.last_trainrg,
    pathway: state.pathwayReducer.pathway,
  };
};
export function Menu({
  removeAllTags,
  logout,
  setUser,
  className,
  last_trainrg,
  pathway,
}) {
  const history = useHistory();
  const { t } = useTranslation();

  const leftItems = [
    {
      route: routes.app.index,
      title: t('navigation.home'),
      onClick: () => {
        history.push(routes.app.index);
      },
      icon: HomeIcon,
    },
  ];

  const rightItems = [
    {
      route: routes.app.settings.index,
      title: t('navigation.profile'),
      onClick: () => {
        history.push(routes.app.settings.index);
      },
      icon: PersonIcon,
    },
    {
      route: routes.my_events,
      title: t('navigation.my_events'),
      onClick: () => {
        history.push(routes.my_events);
      },
      icon: EventNoteIcon,
    },
    {
      title: t('navigation.logout'),
      onClick: () => {
        shutdown();
      },
      icon: ExitToAppIcon,
    },
  ];

  const shutdown = () => {
    Auth.logout();

    if (
      !(
        (pathway?.id.length > 0 && pathway !== null) ||
        (last_trainrg?.length > 0 && last_trainrg !== null)
      )
    ) {
      removeAllTags();
    }
    setUser();
    logout();
    history.replace(routes.login);
  };

  return (
    <div className={`bg-white ${className}`}>
      <Container className='flex items-center justify-between border-t-2 border-b-2 border-gray-very-light lg:px-10 xs:px-3'>
        <div className=''>
          {leftItems.map((listItem, index) => (
            <Entry key={index} {...listItem} />
          ))}
        </div>
        <div className='flex'>
          {rightItems.map((listItem, index) => (
            <Entry
              className='lg:space-x-20 xs:space-x-4'
              key={index}
              {...listItem}
            />
          ))}
        </div>
      </Container>
    </div>
  );
}

Menu.propTypes = {
  removeAllTags: PropTypes.func,
  logout: PropTypes.func,
  setUser: PropTypes.func,
  className: PropTypes.string,
  last_trainrg: PropTypes.any,
  pathway: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
