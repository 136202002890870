import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ImportantInformation from './ImportantInformation';
import { DeliveryIcon } from './icons/Delivery';
import { SupportIcon } from './icons/Support';
import { HelpIcon } from './icons/Help';
import { RightArrowIcon } from './icons/RightArrow';
import { CloseIcon } from './icons/Close';
import Overlay from '../overlay/Overlay';
import { LeftArrowIcon } from '../icons/LeftArrow';

export default function ImportantInformationWithOverlay({
  showContinueButton,
  showCloseButton,
  className,
}) {
  const [display, setDisplay] = useState(true);
  const { t } = useTranslation();
  const ITEMS = [
    {
      guideline: t('guidelines.first_item.guideline'),
      text: t('guidelines.first_item.text'),
      subtext: t('guidelines.first_item.subtext'),
      icon: <DeliveryIcon className='w-20 h-20 text-primary' />,
    },
    {
      guideline: t('guidelines.second_item.guideline'),
      text: t('guidelines.second_item.text'),
      subtext: t('guidelines.second_item.subtext'),
      icon: <SupportIcon className='w-20 h-20 text-primary' />,
    },
    {
      guideline: t('guidelines.third_item.guideline'),
      text: t('guidelines.third_item.text'),
      subtext: t('guidelines.third_item.subtext'),
      icon: <HelpIcon className='w-20 h-20 text-primary' />,
    },
  ];

  const onClose = () => {
    setDisplay(false);
  };

  if (!display) {
    return <></>;
  }

  return (
    <Overlay className={className}>
      <div className="relative flex flex-col mt-5 bg-white shadow-3xl">
        {showCloseButton && (
          <div dir={document.body.dir === 'rtl' ? 'ltr' : 'rtl'} className="bg-navy-blue">
            <CloseIcon className="block w-12 h-12 mt-3 mr-2 cursor-pointer right-1" onClick={onClose} />
          </div>
        )}
        {/* <ImportantInformation
          className='mb-10'
          header={
            <h1 className='p-5 md:pl-0 md:pt-0'>{t('guidelines.title')}</h1>
          }
        /> */}

        {/* <div className='flex flex-row '>/ */}
        <div className="grid md:grid-cols-3 xs:grid-col-1">
          {ITEMS.map((item, key) => (
            <ImportantInformation
              key={key}
              icon={item.icon}
              text={item.text}
              number={key + 1}
              guideline={item.guideline}
              title={item.title}
              subtext={item.subtext}
              last={ITEMS.length === key + 1} // il 3      key 0 1 2
              first={key === 0}
            />
          ))}
        </div>
        <br />
        <div>
          {showContinueButton && (
            <div
              className="flex flex-row items-stretch justify-end py-2 pl-5 pr-8 mt-1 text-2xl font-bold bg-teal md:pl-0"
              onClick={onClose}
            >
              <div className='self-center pr-4 cursor-pointer text-dark-grey-body'>{t('global.continue')}</div>
              {document.body.dir !== 'rtl' && (
                <div className="self-center">
                  <RightArrowIcon className="w-6 h-6 cursor-pointer text-dark-grey-body" />
                </div>
              )}
              {document.body.dir === 'rtl' && (
                <div className="self-center">
                  <LeftArrowIcon className="w-6 h-6 cursor-pointer text-dark-grey-body" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Overlay>
  );
}

ImportantInformationWithOverlay.propTypes = {
  showCloseButton: PropTypes.bool,
  showContinueButton: PropTypes.bool,
  className: PropTypes.string,
};
