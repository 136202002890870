import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert/Alert';
import User from '../schema/User';
import Button from '../components/button/Button';
import routes from '../helpers/routes';
import Home from '../components/container/Home';
import Text from '../components/forms/inputs/Text';
import BoxShadow from '../components/box-shadow/BoxShadow';

const AWAIT_BEFORE_REDIRECT = 4000;
export default function ConfirmNewPasswordScreenDashboard(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [password, setPassword] = useState('');
  const [confirm] = useMutation(User.CONFIRM_PASSWORD_DASHBOARD);
  const token = props.computedMatch.params.token;
  const [openAlert, setOpenAlert] = useState(false);
  const [severity, setSeverity] = useState('error');
  const [title, setTitle] = useState(t('error_has_occured'));

  const handleClose = () => {
    history.replace(routes.login_dashboard);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const submit = async function (e) {
    e.preventDefault();

    if (openAlert) {
      return;
    }

    if (!password || !passwordRepeat) {
      return;
    }

    if (password !== passwordRepeat) {
      setTitle(t('error_different_passwords'));
      setOpenAlert(true);
      return;
    }

    try {
      const input = {
        input: {
          tokenPassw: token,
          newPassword: password,
        },
      };
      const ret = await confirm({ variables: input });
      if (ret.data) {
        setTitle(t('settings.alert.password_changed'));
        setSeverity('success');
        setTimeout(handleClose, AWAIT_BEFORE_REDIRECT);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setOpenAlert(true);
    }
  };

  return (
    <Home>
      <BoxShadow>
        <ValidatorForm onSubmit={submit}>
          <Text
            autoFocus
            id='email'
            placeholder={t('new_password')}
            name='password'
            type='password'
            value={password}
            validators={['required']}
            errorMessages={[t('registration_error_required')]}
            onChange={setPassword}
          />
          <Text
            value={passwordRepeat}
            id='email'
            placeholder={t('repeat_password')}
            name='repeat_password'
            type='password'
            validators={['required']}
            errorMessages={[t('registration_error_required')]}
            onChange={setPasswordRepeat}
          />
          <div className='mt-5'>
            <Button type='submit' disabled={openAlert}>
              {t('change_password')}
            </Button>
          </div>
          <Snackbar
            open={openAlert}
            autoHideDuration={AWAIT_BEFORE_REDIRECT}
            onClose={handleAlertClose}
          >
            <Alert
              variant='filled'
              severity={severity}
              onClose={handleAlertClose}
            >
              {title}
            </Alert>
          </Snackbar>
        </ValidatorForm>
      </BoxShadow>
    </Home>
  );
}

ConfirmNewPasswordScreenDashboard.propTypes = {
  computedMatch: PropTypes.object,
};
