export default [
  {
    stepNumber: '1',
    stepName: 'cvent.steps.boxes',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'CardSelection',
    options: [
      {
        from: '1',
        to: 'yesNoQuestion',
        title: 'cvent.cards.first_step.title',
        label: 'cvent.cards.first_step.button',
        image: '/workflow/minimed.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.first_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: '3buttonSelection',
        title: 'cvent.cards.second_step.title',
        label: 'cvent.cards.second_step.button',
        image: '/workflow/es_fota.jpg',
        device: '/workflow/es_fota.jpg',
        description: 'cvent.cards.second_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: 'sessionSelection',
        title: 'cvent.cards.fifth_step.title',
        label: 'cvent.cards.fifth_step.button',
        image: '/workflow/sport_minimed_780g.jpg',
        device: '/workflow/guardian-cgm.png',
        description: 'cvent.cards.fifth_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: 'sessionSelection2',
        title: 'cvent.cards.six_step.title',
        label: 'cvent.cards.six_step.button',
        image: '/workflow/es_mm780g.jpg',
        device: '/workflow/guardian-cgm.png',
        description: 'cvent.cards.six_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: 'sessionSelection3',
        title: 'cvent.cards.seventh_step.title',
        label: 'cvent.cards.seventh_step.button',
        image: '/workflow/es_boy.jpg',
        device: '/workflow/guardian-cgm.png',
        description: 'cvent.cards.seventh_step.description',
        disabled: false,
      },
    ],
  },
  {
    stepNumber: 'yesNoQuestion',
    centered: true,
    stepName: 'cvent.steps.cvent_yesNoQuestion',
    stepDescription: 'cvent.steps.cvent_yesNoQuestion',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'yesNoQuestion',
        to: 'sessionQuestion',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: 'yesNoQuestion',
        to: 'noAnswerGoBack',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: 'yesNoQuestion2', //!Here should be the ES Images
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'yesNoQuestion2',
        to: 'session1Question',
        ESshouldDisplayImages: true,
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: 'yesNoQuestion2',
        to: 'noAnswerGoBackCard2',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: 'noAnswerGoBack',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDescription: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'sessionQuestion',
    centered: true,
    stepName: 'cvent.steps.sessionQuestion',
    stepDescription: 'cvent.steps.sessionQuestion',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'sessionQuestion',
        to: 'session3toContinue',
        title: 'cvent.steps.session3',
        label: 'cvent.steps.session3',
      },
      {
        from: 'sessionQuestion',
        to: 'Session4YesNoQuestion',
        title: 'cvent.steps.session4',
        label: 'cvent.steps.session4',
      },
    ],
  },
  {
    stepNumber: 'session3toContinue',
    centered: true,
    stepName: 'cvent.steps.session3toContinue',
    stepDescription: 'cvent.steps.session3toContinue',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'session3toContinue',
        //to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Product',
            value: 'Session 3',
          },
        ],
        title: 'cvent.steps.session3ContinueButton',
        label: 'cvent.steps.session3ContinueButton',
      },
    ],
  },
  {
    stepNumber: 'Session4YesNoQuestion',
    centered: true,
    stepName: 'cvent.steps.Session4YesNoQuestion',
    stepDescription: 'cvent.steps.Session4YesNoQuestion',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'Session4YesNoQuestion',
        //to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Product',
            value: 'Session 4',
          },
        ],
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: 'Session4YesNoQuestion',
        to: 'Session4YesNoQuestionNoSelection',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: 'Session4YesNoQuestionNoSelection',
    centered: true,
    stepName: 'cvent.steps.Session4YesNoQuestionNoSelection',
    stepDescription: 'cvent.steps.Session4YesNoQuestionNoSelection',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'session1Question',
    centered: true,
    stepName: 'cvent.steps.session1Question',
    stepDescription: 'cvent.steps.session1Question',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'session1Question',
        to: 'session1QuestionContinue',
        title: 'cvent.steps.session1',
        label: 'cvent.steps.session1',
      },
    ],
  },
  {
    stepNumber: 'sessionSelection',
    centered: true,
    stepName: 'cvent.steps.sessionSelection',
    stepDescription: 'cvent.steps.sessionSelection',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'sessionSelection',
        to: 'session2QuestionContinue',
        title: 'cvent.steps.session2',
        label: 'cvent.steps.session2',
      },
    ],
  },
  {
    stepNumber: 'sessionSelection2',
    centered: true,
    stepName: 'cvent.steps.sessionSelection',
    stepDescription: 'cvent.steps.sessionSelection2',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'sessionSelection',
        to: 'session2toendsummary',
        title: 'cvent.steps.session5',
        label: 'cvent.steps.session5',
      },
    ],
  },
  {
    stepNumber: 'sessionSelection3',
    centered: true,
    stepName: 'cvent.steps.sessionSelection',
    stepDescription: 'cvent.steps.sessionSelection3',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'sessionSelection3',
        to: 'session3toendsummary',
        title: 'cvent.steps.sessionSelection3text',
        label: 'cvent.steps.session5',
      },
    ],
  },
  {
    stepNumber: 'session1QuestionContinue',
    centered: true,
    stepName: 'cvent.steps.session1QuestionContinue',
    stepDescription: 'cvent.steps.session1QuestionContinue',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'session1QuestionContinue',
        //to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Product',
            value: 'Session 1',
          },
        ],
        title: 'cvent.steps.session3ContinueButton',
        label: 'cvent.steps.session3ContinueButton',
      },
    ],
  },
  {
    stepNumber: 'session2QuestionContinue',
    centered: true,
    stepName: 'cvent.steps.session2QuestionContinue',
    stepDescription: 'cvent.steps.session2QuestionContinue',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'session2QuestionContinue',
        //to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Deporte 780G',
          },
        ],
        title: 'cvent.steps.session3ContinueButton',
        label: 'cvent.steps.session3ContinueButton',
      },
    ],
  },
  {
    stepNumber: 'session2toendsummary',
    centered: true,
    stepName: 'cvent.steps.session2toendsummary',
    stepDescription: 'cvent.steps.session2toendsummary',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'sessionSelection2',
        to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Situaciones especiales 780G',
          },
        ],
        title: 'cvent.steps.session3ContinueButton',
        label: 'cvent.steps.session3ContinueButton',
      },
    ],
  },
  {
    stepNumber: 'session3toendsummary',
    centered: true,
    stepName: 'cvent.steps.session3toendsummary',
    stepDescription: 'cvent.steps.session3toendsummary',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'sessionSelection2',
        to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Dudas 780G',
          },
        ],
        title: 'cvent.steps.session3ContinueButton',
        label: 'cvent.steps.session3ContinueButton',
      },
    ],
  },
  {
    stepNumber: '3buttonSelection',
    centered: true,
    stepName: 'cvent.steps.cvent_3buttonSelection_title',
    stepDescription: 'cvent.steps.cvent_3buttonSelection_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '3buttonSelection',
        to: '3buttonSelectionContinue',
        title: 'cvent.steps.cvent_3buttonSelection1',
        label: '',
      },
      {
        from: '3buttonSelection',
        to: '3buttonSelectionContinue',
        title: 'cvent.steps.cvent_3buttonSelection2',
        label: '',
      },
      {
        from: '3buttonSelection',
        to: '3buttonSelectionContinue',
        title: 'cvent.steps.cvent_3buttonSelection3',
        label: '',
      },
    ],
  },
  {
    stepNumber: '3buttonSelectionContinue',
    centered: true,
    stepName: 'cvent.steps.cvent_3buttonSelectionContinue_title',
    stepDescription: 'cvent.steps.cvent_3buttonSelectionContinue_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '3buttonSelectionContinue',
        to: 'Summary',
        title: 'cvent.steps.cvent_3buttonSelectionContinue_button',
        label: '',
      },
    ],
  },
];
