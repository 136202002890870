import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import SnackbarOpenOption from './SnackbarOpenOption';
import Container from '../container/Container';
import routes from '../../helpers/routes';

export function AlertPTH() {
  const history = useHistory();

  const classes = useStyles();
  const { t } = useTranslation();
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const goCalendar = () => {
    history.push(routes.calendar);
  };

  return (
    <>
      <div className="w-full bg-bannercontinuereg">
        <Container className={classes.container}>
          <ErrorOutlineIcon />
          <Typography variant="subtitle1">
            {t('register.attendee.pathway_must_continue')}
          </Typography>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={goCalendar}
          >
            {t('register.attendee.pathway_continue_button')}
          </Button>
        </Container>
      </div>

      {openSnackBar && (
        <SnackbarOpenOption open={openSnackBar} setOpen={setOpenSnackBar} />
      )}
    </>
  );
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
});

AlertPTH.propTypes = {
  user: PropTypes.object,
};
export default AlertPTH;
