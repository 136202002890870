import React from 'react';
import PropTypes from 'prop-types';

function OverlayFlowBoxAlert(props) {
  const { children, className, ...rest } = props;

  return (
    <div
      {...rest}
      className={[
        'overflow-auto absolute top-0 left-0 right-0 z-40 min-h-full bg-fixed bg-no-repeat bg-cover bg-white ',
        className,
      ].join(' ')}
    >
      {children}
    </div>
  );
}

OverlayFlowBoxAlert.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default OverlayFlowBoxAlert;
