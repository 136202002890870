import { SET_FLOW_FORM, FETCH_FLOW_FORM } from './types/flowForm';

export const setFlowForm = (form) => ({
  type: SET_FLOW_FORM,
  payload: form,
});

export const fetchFlowForm = () => ({
  type: FETCH_FLOW_FORM,
});
