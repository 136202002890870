import React from 'react';
import PropTypes from 'prop-types';
import SingleEventModal from './SingleEventModal';
import Dialog from '../dialog/Dialog';

export default function EventModal(props) {
  return (
    <Dialog
      open={props.open}
      handleClose={props.handleClose}
      render={
        <SingleEventModal
          eventdata={props.eventData}
          locale={props.locale}
          handleClose={props.handleClose}
        />
      }
    />
  );
}

EventModal.propTypes = {
  eventData: PropTypes.object,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  locale: PropTypes.string,
};

EventModal.defaultProps = {
  open: false,
};
