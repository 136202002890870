/* eslint-disable  */

import React, { lazy } from 'react';
import { connect } from 'react-redux';

export function extraFields() {
  return {
    adminDashboard: {
      tableFields: [
        {
          id: 'extraForm.uk_hospital',
          label: 'Hospital',
          minWidth: 170,
        },
        {
          id: 'extraForm.uk_hospital_not_in_list_optional',
          label: 'Hospital Optional',
          minWidth: 170,
        },
        {
          id: 'extraForm.uk_healthcare_professional',
          label: 'Healthcare profesional',
          minWidth: 170,
        },
      ],
    },
  };
}
