import {
  SET_LOCALE,
  FETCH_LOCALE,
  SET_NOTIFICATIONLANGUAGE,
} from './types/locale';

export const setLocale = locale => ({
  type: SET_LOCALE,
  payload: locale,
});

export const setNotificationLanguage = notificationLanguage => ({
  type: SET_NOTIFICATIONLANGUAGE,
  payload: notificationLanguage,
});

export const fetchLocale = () => ({
  type: FETCH_LOCALE,
});
