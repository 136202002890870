import React from 'react';
import PropTypes from 'prop-types';

export default function Container({ children, className, ...props }) {
  return (
    <div
      // className={['m-auto px-2 sm:px-5 md:px-10 lg:container', className].join(

      className={['', className].join(' ')}
      {...props}
    >
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
