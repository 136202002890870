import PropTypes from 'prop-types';
export function LeftArrowIcon({ className, ...props }) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.000000 512.000000"
      className={['fill-current', className].join(' ')}
      {...props}>
      <g transform="rotate(-179.91500854492188 256.0733947753907,262.04916381835943) translate(0,512) scale(0.10000000149011612,-0.10000000149011612) ">
        <path d="m3235,4284c-83,-43 -119,-124 -95,-215c10,-40 74,-107 678,-711l667,-668l-2155,0c-1258,0 -2171,-4 -2194,-10c-86,-19 -129,-74 -134,-168c-2,-55 1,-74 20,-106c28,-48 63,-75 114,-86c23,-6 936,-10 2194,-10l2155,0l-648,-641c-397,-393 -658,-659 -674,-687c-21,-36 -28,-59 -27,-102c0,-66 21,-109 70,-147c47,-35 142,-44 195,-18c46,23 1675,1650 1700,1697c11,23 19,57 19,88c0,31 -8,65 -19,88c-24,46 -1654,1674 -1698,1696c-41,20 -129,20 -168,0z" />
      </g>
    </svg>
  );
}

LeftArrowIcon.propTypes = {
  className: PropTypes.string,
};