import PropTypes from 'prop-types';
import { LeftArrowIcon } from '../icons/LeftArrow';
import { RightArrowIcon } from '../important-information/icons/RightArrow';

export default function Previous({ onClick, className, children, ...rest }) {
  return (
    <div className='flex bg-teal py-3 pl-6' onClick={onClick}>
      {document.body.dir !== 'rtl' && (
        <LeftArrowIcon className='w-6 h-6 mr-5 text-dark-grey-body cursor-pointer' />
      )}
      {document.body.dir === 'rtl' && (
        <RightArrowIcon className='w-6 h-6 mr-5 text-dark-grey-body cursor-pointer' />
      )}
      <button
        className={[
          'flex items-center text-dark-grey-body cursor-pointer',
          className,
        ].join(' ')}
        {...rest}
      >
        {children}
      </button>
    </div>
  );
}

Previous.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};
