import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AWAIT_BEFORE_CLOSE = 4000;

export default function SnackbarOpenOption({
  open,
  severity,
  title,
  path,
  setOpen,
  onClose
}) {
  const history = useHistory();
  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (onClose) {
      onClose(event, reason);
    }

    setOpen(false);

    if (path !== '') {
      history.push(path);
    }
  };

  if (open) {
    return (
      <Snackbar open={open} autoHideDuration={AWAIT_BEFORE_CLOSE} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {t(title)}
        </Alert>
      </Snackbar>
    );
  }
  return <></>;
}

SnackbarOpenOption.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  severity: PropTypes.string,
  path: PropTypes.string,
  setOpen: PropTypes.func,
  onClose: PropTypes.func,
};

SnackbarOpenOption.defaultProps = {
  open: true,
  title: 'something_went_wrong',
  severity: 'error',
  path: '',
};
