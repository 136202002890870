import PropTypes from 'prop-types';
export function RightArrowIcon({ className, ...props }) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512.000000 512.000000'
      className={['fill-current', className].join(' ')}
      {...props}
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        stroke='none'
      >
        <path
          d='M3235 4344 c-83 -43 -119 -124 -95 -215 10 -40 74 -107 678 -711
l667 -668 -2155 0 c-1258 0 -2171 -4 -2194 -10 -86 -19 -129 -74 -134 -168 -2
-55 1 -74 20 -106 28 -48 63 -75 114 -86 23 -6 936 -10 2194 -10 l2155 0 -648
-641 c-397 -393 -658 -659 -674 -687 -21 -36 -28 -59 -27 -102 0 -66 21 -109
70 -147 47 -35 142 -44 195 -18 46 23 1675 1650 1700 1697 11 23 19 57 19 88
0 31 -8 65 -19 88 -24 46 -1654 1674 -1698 1696 -41 20 -129 20 -168 0z'
        />
      </g>
    </svg>
  );
}

RightArrowIcon.propTypes = {
  className: PropTypes.string,
};
