import { gql } from '@apollo/client';

const GET_ALL_EVENTS_FROM_DASHBOARD = gql`
  query {
    getAllEventForDashboard {
      id
      eventTitle
      eventStart
      eventEnd
      eventID
      userAlpha2
      userCountryName
      userUsername
      extraForm
      extraEvent
      extraUser
    }
  }
`;

const GET_EVENTFROMDASHBOARD = gql`
  query getEventForDashboard($input: GetEventsFromDashboardInput!) {
    getEventForDashboard(input: $input) {
      id
      eventTitle
      eventStart
      eventEnd
      eventID
      userAlpha2
      userCountryName
      userUsername
      extraForm
      extraEvent
      extraUser
    }
  }
`;

const GET_EVENTFROMDASHBOARDFROMTODATE = gql`
  query getEventForDashboardDateInterval(
    $input: GetEventsFromDashboardFromToInput!
  ) {
    getEventForDashboardDateInterval(input: $input) {
      id
      eventTitle
      eventStart
      eventEnd
      eventID
      userAlpha2
      userCountryName
      userUsername
      extraForm
      extraEvent
      extraUser
    }
  }
`;

const EventsDashboard = {
  GET_EVENTFROMDASHBOARD,
  GET_ALL_EVENTS_FROM_DASHBOARD,
  GET_EVENTFROMDASHBOARDFROMTODATE,
};

export default EventsDashboard;
