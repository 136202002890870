import {
  ADD_TAGS,
  FETCH_TAGS,
  REMOVE_ALL_TAGS,
  LAST_TRAIN_RT,
  INITIAL_TAGS,
  DEF_FLOWRETURNED,
  LAST_EVENTREG,
} from './types/tags';

export const addTags = tags => ({
  type: ADD_TAGS,
  payload: tags,
});

export const fetchTags = () => ({
  type: FETCH_TAGS,
});

export const removeAllTags = () => ({
  type: REMOVE_ALL_TAGS,
});

export const lastTrainRG = last_trainrg => ({
  type: LAST_TRAIN_RT,
  payload: last_trainrg,
});

export const initialTags = initial_tags => ({
  type: INITIAL_TAGS,
  payload: initial_tags,
});

export const defFlowRet = def_flow => ({
  type: DEF_FLOWRETURNED,
  payload: def_flow,
});

export const setLastEventReg = last_event => ({
  type: LAST_EVENTREG,
  payload: last_event,
});
