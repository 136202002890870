export default [
  {
    stepNumber: '1',
    stepName: 'cvent.steps.boxes',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'CardSelection',
    options: [
      {
        from: '1',
        to: '2',
        title: 'cvent.cards.first_step.title', //! Imporant look at CardSelection.js its used in form flow
        label: 'cvent.cards.first_step.button',
        image: '/workflow/minimed.jpg',
        device: '/workflow/G4S_system.png',
        // alert: 'cvent.steps.za.alert_title',
        description: 'cvent.cards.first_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: 'Summary',
        title: 'cvent.cards.second_step.title', //! Imporant look at CardSelection.js its used in form flow
        label: 'cvent.cards.second_step.button',
        image: '/workflow/fi_girl_in_park.jpeg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.second_step.description',
        //alert: 'cvent.steps.za.alert_title',
        disabled: false,
      },
      {
        from: '1',
        to: '6',
        title: 'cvent.cards.third_step.title', //! Imporant look at CardSelection.js its used in form flow
        label: 'cvent.cards.third_step.button',
        image: '/workflow/fi_fourthBox.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.third_step.description',
        //alert: 'cvent.steps.za.alert_title',
        disabled: false,
      },
    ],
  },
  {
    stepNumber: '2',
    centered: true,
    stepName: 'cvent.steps.za.za_representative',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_entry_level',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: '2',
        to: '4',
        title: 'cvent.steps.za.insuling_pump_therapy',
        label: '',
      },
      {
        from: '2',
        to: '4',
        title: 'cvent.steps.za.standalone_cgm_and_inpen',
        label: '',
      },
    ],
  },
  {
    stepNumber: '4',
    centered: true,
    stepName: 'cvent.steps.za.medtronic_pump_question',
    stepDescription: 'cvent.steps.za.medtronic_pump_question',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '4',
        to: 'Summary',
        title: 'cvent.steps.cvent_select_group',
        label: 'cvent.steps.cvent_select_group',
      },
      {
        from: '4',
        to: 'Summary',
        title: 'cvent.steps.cvent_select_one_to_one',
        label: 'cvent.steps.cvent_select_one_to_one',
      },
    ],
  },
  {
    stepNumber: '5',
    centered: true,
    stepName: 'cvent.steps.za.form_title',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: {},
    componentType: 'FormSelection',
    options: [
      {
        from: '5',
        to: 'Summary',
        title: 'cvent.steps.za.form_completed',
        label: 'cvent.steps.za.form_completed',
      },
    ],
  },
  {
    stepNumber: '6',
    stepName: 'Special Technical Instructions',
    stepDescription: 'Form Technical instructions',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'FirstForm',
    options: [
      {
        from: '1',
        to: '1',
        title: 'OK',
        label: '',
      },
    ],
  },
  {
    stepNumber: '6.1',
    stepName: 'Special Technical Instructions',
    stepDescription: 'Form Special Technical instructions',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'ThirdForm',
    options: [
      {
        from: '1',
        to: '1',
        title: 'OK',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'secBoxYesNo',
    centered: true,
    stepName: 'cvent.steps.za.cvent_secondbox_question',
    stepDescription: 'cvent.steps.za.cvent_secondbox_question',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'secBoxYesNo',
        to: 'secBoxTherapy',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: 'secBoxYesNo',
        to: 'secBoxNoAnswer',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: 'secBoxNoAnswer',
    centered: true,
    stepName: 'cvent.steps.za.cvent_secondbox_no_answer',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'secBoxTherapy',
    centered: true,
    stepName: 'cvent.steps.za.cvent_secondbox_therapy',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.za.cvent_secondbox_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: 'secBoxTherapy',
        to: 'secBoxTrainedOn',
        title: 'cvent.steps.za.cvent_insulim_pump_therapy',
        label: '',
      },
      {
        from: 'secBoxTherapy',
        to: 'secBoxTrainedOn2',
        title: 'cvent.steps.za.standalone_cgm_and_inpen',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'secBoxTrainedOn',
    centered: true,
    stepName: 'cvent.steps.za.cvent_secondbox_trained_on',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.za.cvent_secondbox_trained_on',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: 'secBoxTrainedOn',
        to: 'secBoxPumpExp',
        title: 'cvent.steps.za.cvent_mm_780g',
        label: '',
      },
      {
        from: 'secBoxTrainedOn',
        to: 'secBoxPumpExp',
        title: 'cvent.steps.za.cvent_mm_640g',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'secBoxPumpExp',
    centered: true,
    stepName: 'cvent.steps.za.cvent_secondbox_pump_experience',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.za.cvent_secondbox_trained_on',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: 'secBoxPumpExp',
        to: 'secBoxSensorExp',
        title: 'cvent.steps.za.cvent_mm_640g_670g_780g',
        label: '',
      },
      {
        from: 'secBoxPumpExp',
        to: 'secBoxForm',
        title: 'cvent.steps.za.cvent_other_pump',
        label: '',
      },
      {
        from: 'secBoxPumpExp',
        to: 'secBoxForm',
        title: 'cvent.steps.za.cvent_no_experience',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'secBoxSensorExp',
    centered: true,
    stepName: 'cvent.steps.za.cvent_secondbox_sensor_experience',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.za.cvent_secondbox_sensor_experience',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: 'secBoxSensorExp',
        to: 'secBoxForm',
        title: 'cvent.steps.za.cvent_medtronic_sensor',
        label: '',
      },
      {
        from: 'secBoxSensorExp',
        to: 'secBoxForm',
        title: 'cvent.steps.za.cvent_other_cgm',
        label: '',
      },
      {
        from: 'secBoxSensorExp',
        to: 'secBoxForm',
        title: 'cvent.steps.za.cvent_no_experience_2',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'secBoxForm',
    centered: true,
    stepName: 'cvent.steps.za.form_title',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: {},
    componentType: 'FormSelection',
    options: [
      {
        from: 'secBoxForm',
        to: 'Summary',
        title: 'cvent.steps.za.form_completed',
        label: 'cvent.steps.za.form_completed',
      },
    ],
  },
  {
    stepNumber: 'secBoxTrainedOn2',
    centered: true,
    stepName: 'cvent.steps.za.cvent_secondbox_trained_on_2',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.za.cvent_secondbox_trained_on_2',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: 'secBoxTrainedOn2',
        to: 'Summary',
        title: 'cvent.steps.za.cvent_guardian_connect',
        label: '',
      },
      {
        from: 'secBoxTrainedOn2',
        to: 'Summary',
        title: 'cvent.steps.za.cvent_guardian_4_system_only',
        label: '',
      },
      {
        from: 'secBoxTrainedOn2',
        to: 'Summary',
        title: 'cvent.steps.za.cvent_guardian_4_system_and_inpen',
        label: '',
      },
      {
        from: 'secBoxTrainedOn2',
        to: 'Summary',
        title: 'cvent.steps.za.cvent_inpen',
        label: '',
      },
    ],
  },
];
