const tagExclusion = {};

const tagSystem = {
  Boxes: {
    'cvent.cards.sixth_step.title': {
      key: 'Therapy',
      value: 'SmartMDI',
    },
  },
  'cvent.steps.cvent_will_be_trained_on_pump_title': {
    'cvent.steps.cvent_M670G': {
      key: 'Product',
      value: 'MM670G',
    },
    'cvent.steps.cvent_M780G': {
      key: 'Product',
      value: 'MM780G',
    },
  },
  'cvent.steps.cvent_will_be_trained_on_system_title_670g': {
    'cvent.steps.cvent_will_be_trained_on_system_pump_only_670g': {
      key: 'Therapy',
      value: 'Pump',
    },
    'cvent.steps.cvent_will_be_trained_on_system_cgm_for_pump_670g': {
      key: 'Therapy',
      value: 'CGM',
    },
    'cvent.steps.cvent_will_be_trained_on_system_pump_and_cgm_670g': {
      key: 'Therapy',
      value: 'Pump & CGM',
    },
  },
  'cvent.steps.cvent_will_be_trained_on_system_title_770g': {
    'cvent.steps.cvent_will_be_trained_on_system_pump_only_770g': {
      key: 'Therapy',
      value: 'Pump',
    },
    'cvent.steps.cvent_will_be_trained_on_system_pump_only_770g_2': {
      key: 'Therapy',
      value: 'GS4',
    },
    'cvent.steps.cvent_will_be_trained_on_system_cgm_for_pump_770g': {
      key: 'Therapy',
      value: 'CGM',
    },
    'cvent.steps.cvent_will_be_trained_on_system_pump_and_cgm_770g': {
      key: 'Therapy',
      value: 'Pump & CGM',
    },
    'cvent.steps.cvent_will_be_trained_on_smart_guard_automodus': {
      key: 'Therapy',
      value: 'AM',
    },
  },
  'cvent.steps.cvent_will_be_trained_on_system_title_770g_2': {
    'cvent.steps.cvent_will_be_trained_on_system_pump_only_770g': {
      key: 'Therapy',
      value: 'Pump',
    },
    'cvent.steps.cvent_will_be_trained_on_system_pump_only_770g_2': {
      key: 'Therapy',
      value: 'GS4',
    },
    'cvent.steps.cvent_will_be_trained_on_system_cgm_for_pump_770g': {
      key: 'Therapy',
      value: 'CGM',
    },
    'cvent.steps.cvent_will_be_trained_on_smart_guard_automodus': {
      key: 'Therapy',
      value: 'AM',
    },
  },
};

export function flow(summary) {
  const finalTags = [];
  const filtered = [];

  for (const userTags of summary) {
    const stepNamesToRemove = tagExclusion[userTags.option.title] || [];
    if (stepNamesToRemove) {
      for (const stepName of stepNamesToRemove) {
        filtered.push(stepName);
      }
    }
  }
  const chosenTags = summary
    .filter((step) => {
      for (const exclusion of filtered) {
        if (exclusion === step.stepName) {
          return false;
        }
      }
      return true;
    })
    .map((choice) => {
      if (tagSystem[choice.stepName]) {
        return tagSystem[choice.stepName][choice.option.title];
      }
      return undefined;
    });
  for (const choice of chosenTags) {
    if (choice !== undefined) {
      finalTags.push(choice);
    }
  }
  return finalTags;
}
