import React from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import LoginScreen from './screens/Loginscreen';
import LoginscreenDashboard from './screens/LoginscreenDashboard';
import Register from './screens/RegisterScreen';
import RegisterScreenDashboard from './screens/RegisterScreenDashboard';
import TableDashboard from './screens/TableDashboard';
import StatisticsDashboard from './screens/StatisticsDashboard';
import JiraDashboardTicketCreate from './screens/JiraDashboardTicketCreate';
import JiraDashboardTicketList from './screens/JiraDashboardTicketList';
import client from './client';
import routes from './helpers/routes';
import Theme from './helpers/theme';
import ProtectedRoute from './helpers/ProtectedRoute';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import ForgotPasswordScreenDashboard from './screens/ForgotPasswordScreenDashboard';
import ConfirmNewPasswordScreen from './screens/ConfirmNewPasswordScreen';
import ConfirmNewPasswordScreenDashboard from './screens/ConfirmNewPasswordScreenDashboard';
import VerifyAccountScreen from './screens/VerifyAccountScreen';
import RegisterAttendeeScreen from './screens/RegisterAttendeeScreen';
import WorkflowScreen from './screens/WorkflowScreen';
import CalendarScreen from './screens/CalendarScreen';
import NotFoundScreen from './screens/NotFoundScreen';
import SettingsScreen from './screens/SettingsScreen';
import SettingsScreenDashboard from './screens/SettingsScreenDashboard';
import AdministrateUsersDashboard from './screens/AdministrateUsersDashboard';
import ImportantInformationScreen from './screens/ImportantInformationScreen';
import MyEventsScreen from './screens/MyEventsScreen';
import Main from './components/layout/Main';
import { store, persistor } from './store/redux';
import { LoadingFullScreenCentered } from './components/loading-indicator/Loading';
import IndexDashboard from './screens/IndexDashboard';

function App() {
  return (
    <Provider store={store}>
      <PersistGate
        loading={<LoadingFullScreenCentered />}
        persistor={persistor}
      >
        <Theme>
          <ApolloProvider client={client}>
            <Router>
              <Main>
                <TransitionGroup className='switch-wrapper'>
                  <CSSTransition
                    key={location.key}
                    classNames='fade'
                    timeout={300}
                  >
                    <Switch location={location}>
                      <ProtectedRoute
                        exact
                        path={routes.login}
                        component={LoginScreen}
                      />
                      <ProtectedRoute
                        exact
                        path={routes.register}
                        component={Register}
                      />
                      <ProtectedRoute
                        exact
                        path={routes.app.index}
                        component={WorkflowScreen}
                      />
                      <ProtectedRoute
                        exact
                        path={routes.important_information}
                        component={ImportantInformationScreen}
                      />
                      <ProtectedRoute
                        exact
                        path={routes.calendar}
                        component={CalendarScreen}
                      />
                      <ProtectedRoute
                        exact
                        path={routes.calendar_register}
                        component={RegisterAttendeeScreen}
                      />
                      <ProtectedRoute
                        exact
                        path={routes.app.settings.index}
                        component={SettingsScreen}
                      />
                      <ProtectedRoute
                        exact
                        path={routes.my_events}
                        component={MyEventsScreen}
                      />
                      <ProtectedRoute
                        path={routes.confirm_password}
                        component={ConfirmNewPasswordScreen}
                      />
                      <ProtectedRoute
                        path={routes.dashboard.confirm_password_dashboard}
                        component={ConfirmNewPasswordScreenDashboard}
                      />
                      <ProtectedRoute
                        path={routes.forgot_password}
                        component={ForgotPasswordScreen}
                      />
                      <ProtectedRoute
                        path={routes.dashboard.forgot_password_dashboard}
                        component={ForgotPasswordScreenDashboard}
                      />
                      <ProtectedRoute
                        path={routes.verify_account}
                        component={VerifyAccountScreen}
                      />
                      <ProtectedRoute
                        path={routes.register_dashboard}
                        component={RegisterScreenDashboard}
                      />
                      <ProtectedRoute
                        path={routes.login_dashboard}
                        component={LoginscreenDashboard}
                      />
                      <ProtectedRoute
                        exact
                        path={routes.dashboard.table_dashboard}
                        component={TableDashboard}
                      />
                      <ProtectedRoute
                        exact
                        path={routes.dashboard.statistics_dashboard}
                        component={StatisticsDashboard}
                      />
                      <ProtectedRoute
                        exact
                        path={routes.dashboard.settings_dashboard}
                        component={SettingsScreenDashboard}
                      />
                      <ProtectedRoute
                        exact
                        path={routes.dashboard.jira_dashboard_ticket_create}
                        component={JiraDashboardTicketCreate}
                      />
                      <ProtectedRoute
                        exact
                        path={routes.dashboard.jira_dashboard_ticket_list}
                        component={JiraDashboardTicketList}
                      />
                      <ProtectedRoute
                        exact
                        path={routes.dashboard.administrate_users_dashboard}
                        component={AdministrateUsersDashboard}
                      />
                      <ProtectedRoute
                        exact
                        path={routes.dashboard.index}
                        component={IndexDashboard}
                      />
                      <Route path='*' component={NotFoundScreen} />
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              </Main>
            </Router>
          </ApolloProvider>
        </Theme>
      </PersistGate>
    </Provider>
  );
}

export default App;
