export default [
  {
    stepNumber: '1',
    stepName: 'cvent.steps.boxes',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'CardSelection',
    options: [
      {
        from: '1',
        to: '2',
        title: 'cvent.cards.first_step.title',
        label: 'cvent.cards.first_step.button',
        image: '/workflow/minimed.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.first_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: '3',
        title: 'cvent.cards.second_step.title',
        label: 'cvent.cards.second_step.button',
        image: '/workflow/guardian.jpg',
        device: '/workflow/smartGuard.png',
        description: 'cvent.cards.second_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: '2.1',
        title: 'cvent.cards.third_step.title',
        label: 'cvent.cards.third_step.button',
        image: '/workflow/smart-mdi.jpg',
        device: '/workflow/smartGuard.png',
        description: 'cvent.cards.third_step.description',
        disabled: false,
      },
    ],
  },
  {
    stepNumber: '2',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2',
        to: '7',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: '',
      },
      {
        from: '2',
        to: '4',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: '',
      },
    ],
  },
  {
    stepNumber: '2.1',
    centered: true,
    stepName: 'cvent.steps.medtronic_age_question',
    stepDescription: 'cvent.steps.medtronic_age_question',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2.1',
        to: '2.2',
        title: 'cvent.steps.cvent_check_if_age_under_17',
        label: '',
      },
      {
        from: '8',
        to: '10',
        title: 'cvent.steps.cvent_check_if_age_above_18',
        label: '',
      },
    ],
  },
  {
    stepNumber: '2.2',
    centered: true,
    stepName: 'cvent.steps.cvent_check_guardian_u18_yes',
    stepDescription: 'cvent.steps.cvent_check_guardian_u18_yes',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2.2',
        to: '10',
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: '3',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_arrived_title2',
    stepDescription: 'cvent.steps.cvent_check_if_product_arrived_title2',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '3',
        to: '5',
        title: 'cvent.steps.cvent_check_if_product_arrived_title2_yes',
        label: '',
      },
      {
        from: '3',
        to: '4.1',
        title: 'cvent.steps.cvent_check_if_product_arrived_title2_no_start',
        label: '',
      },
      {
        from: '3',
        to: '4.1',
        title: 'cvent.steps.cvent_check_if_product_arrived_title2_no_approval',
        label: '',
      },
    ],
  },
  {
    stepNumber: '4',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '4.1',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '7',
    centered: true,
    stepName: 'cvent.steps.cvent_check_disclaimer_minimed780_yes',
    stepDescription: 'cvent.steps.cvent_check_disclaimer_minimed780_yes',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '7',
        to: '8',
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: '5',
    centered: true,
    stepName: 'cvent.steps.cvent_check_disclaimer_minimed780_yes',
    stepDescription: 'cvent.steps.cvent_check_disclaimer_minimed780_yes',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '5',
        to: '6',
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: '6',
    centered: true,
    stepName: 'cvent.steps.medtronic_age_question',
    stepDescription: 'cvent.steps.medtronic_age_question',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '6',
        to: '6.1',
        title: 'cvent.steps.cvent_check_if_age_under_17',
        label: '',
      },
      {
        from: '6',
        to: '6.2',
        title: 'cvent.steps.cvent_check_if_age_above_18',
        label: '',
      },
    ],
  },
  {
    stepNumber: '6.1',
    centered: true,
    stepName: 'cvent.steps.cvent_check_guardian_u18_yes',
    stepDescription: 'cvent.steps.cvent_check_guardian_u18_yes',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '6.1',
        to: '6.2',
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: '6.2',
    centered: true,
    stepName: 'cvent.steps.cvent_second_training_with_exp_title',
    stepDescription: 'cvent.steps.cvent_second_training_with_exp_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '6.2',
        to: '9.1',
        title: 'cvent.steps.cvent_second_training_with_exp_yes',
        label: '',
      },
      {
        from: '6.2',
        to: '6.3',
        title: 'cvent.steps.cvent_second_training_with_exp_no',
        label: '',
      },
    ],
  },
  {
    stepNumber: '6.3',
    centered: true,
    stepName: 'cvent.steps.cvent_check_info',
    stepDescription: 'cvent.steps.cvent_check_info',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '6.3',
        to: '6.4',
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: '6.4',
    centered: true,
    stepName: 'cvent.steps.cvent_registration',
    stepDescription: 'cvent.steps.cvent_registration',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '6.4',
        to: '10',
        title: 'cvent.steps.cvent_second_training_with_exp_yes',
        label: '',
      },
      {
        from: '6.4',
        to: '10.2',
        title: 'cvent.steps.cvent_second_training_with_exp_no',
        label: '',
      },
    ],
  },
  {
    stepNumber: '8',
    centered: true,
    stepName: 'cvent.steps.medtronic_age_question',
    stepDescription: 'cvent.steps.medtronic_age_question',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '8',
        to: '8.1',
        title: 'cvent.steps.cvent_check_if_age_under_17',
        label: '',
      },
      {
        from: '8',
        to: '9',
        title: 'cvent.steps.cvent_check_if_age_above_18',
        label: '',
      },
    ],
  },
  {
    stepNumber: '8.1',
    centered: true,
    stepName: 'cvent.steps.cvent_check_guardian_u18_yes',
    stepDescription: 'cvent.steps.cvent_check_guardian_u18_yes',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '8.1',
        to: '9',
        title: 'cvent.steps.cvent_check_continue_flow',
        label: 'cvent.steps.cvent_check_continue_flow',
      },
    ],
  },
  {
    stepNumber: '9',
    centered: true,
    stepName: 'cvent.steps.cvent_first_training_with_exp_title',
    stepDescription: 'cvent.steps.cvent_first_training_with_exp_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '9',
        to: '9.1',
        title: 'cvent.steps.cvent_first_training_with_exp_yes',
        label: '',
      },
      {
        from: '9',
        to: '9.2',
        title: 'cvent.steps.cvent_first_training_with_exp_no',
        label: '',
      },
    ],
  },
  {
    stepNumber: '9.1',
    centered: true,
    stepName: 'cvent.steps.training_format',
    stepDescription: 'cvent.steps.training_format',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '9.1',
        to: '10',
        title: 'cvent.steps.cvent_select_training_virtual',
        label: '',
      },
      {
        from: '9.1',
        to: 'Summary',
        title: 'cvent.steps.cvent_select_training_f2f',
        label: '',
      },
    ],
  },
  {
    stepNumber: '9.2',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '10',
    centered: true,
    stepName: 'cvent.steps.cvent_cam_mic_permission',
    stepDescription: 'cvent.steps.cvent_cam_mic_permission',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '10',
        to: '11',
        title: 'cvent.steps.cvent_cam_mic_permission_yes',
        label: '',
      },
      {
        from: '10',
        to: '10.2',
        title: 'cvent.steps.cvent_cam_mic_permission_no',
        label: '',
      },
    ],
  },
  {
    stepNumber: '10.2',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_exit',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '11',
    centered: true,
    stepName: 'cvent.steps.cvent_privacy_permission',
    stepDescription: 'cvent.steps.cvent_privacy_permission',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '11',
        to: 'Summary',
        title: 'cvent.steps.cvent_privacy_permission_yes',
        label: '',
      },
      {
        from: '11',
        to: '10.2',
        title: 'cvent.steps.cvent_privacy_permission_no',
        label: '',
      },
    ],
  },
];
