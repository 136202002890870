import PropTypes from 'prop-types';
import OverlayFlowBoxAlert from '../overlay/OverlayFlowBoxAlert';
import { useOverflowToggler } from '../../hooks/OverflowToggler';

export default function DialogFlowBoxAlert({ open, render }) {
  const [toggled] = useOverflowToggler(open);

  return (
    <OverlayFlowBoxAlert className={`flex  ${toggled ? '' : 'hidden'}`}>
      <div className='w-full mt-5 '>{render}</div>
    </OverlayFlowBoxAlert>
  );
}

DialogFlowBoxAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  render: PropTypes.node,
};
