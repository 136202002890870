export const DISTANCES = [
  {
    id: '10',
    label: '10 km',
  },
  {
    id: '20',
    label: '20 km',
  },
  {
    id: '30',
    label: '30 km',
  },
  {
    id: '50',
    label: '50 km',
  },
  {
    id: '75',
    label: '75 km',
  },
  {
    id: '100',
    label: '100 km',
  },
];