import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FormManager from '../FormManager';
import { SPECIAL_TECHNICAL_INSTRUCTIONS_FORM_TYPE, SPECIAL_TECHNICAL_INSTRUCTIONS_COUNTRIES, NOT_SUPPORTED } from '../../../constants/form';
import TechnicalBriefingSchema from '../../../schema/forms/TechnicalBriefing';
import FormInformation from '../FormInformation';

export function Heading() {
  const { t } = useTranslation();
  return (
    <FormInformation
      title={t('cvent.technical.special_instructions.title')}
      description={t('cvent.technical.special_instructions.description')}
      helper={t('cvent.technical.special_instructions.helper')}
      required={t('cvent.technical.form.required_fields')}
    />
  );
}

function SpecialTechnicalInstructions(props) {
  const { user, ...rest } = props;
  const products = SPECIAL_TECHNICAL_INSTRUCTIONS_COUNTRIES[user.country.alpha2] ? SPECIAL_TECHNICAL_INSTRUCTIONS_COUNTRIES[user.country.alpha2] : NOT_SUPPORTED;
  const { t } = useTranslation();
  return (
    <FormManager
      {...rest}
      heading={<Heading />}
      products={products}
      user={user}
      formType={SPECIAL_TECHNICAL_INSTRUCTIONS_FORM_TYPE}
      productFamiliarity={true}
      selectCompanion={true}
      mutation={TechnicalBriefingSchema.DATA_MUTATION}
      validator={{
        age: [
          {
            rule: 'minNumber: 10',
            message: t('registration_error_age_under_ten_years_old'),
          }
        ],
      }}
    />
  );
}

SpecialTechnicalInstructions.propTypes = {
  user: PropTypes.object
};

export default SpecialTechnicalInstructions;