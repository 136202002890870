import PropTypes from 'prop-types';
import CheckboxValidator from './CheckboxValidator';

export function CheckboxWithDocuments(props) {
  const { link, label, ...rest } = props;

  return (
    <CheckboxValidator
      label={link ?
        <a href={`./${link}`} target="_blank" rel="noreferrer" className="underline text-cobalt">{label}</a>
        :
        label
      }
      {...rest}
    />
  );
}

CheckboxWithDocuments.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
  rest: PropTypes.any
};

export default CheckboxWithDocuments;