import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import routes from '../../helpers/routes';
import Label from '../label/Label';
import Button from '../button/Button';

function NoEventsFound({ className, ...rest }) {
  const history = useHistory();
  const { t } = useTranslation();

  const goTo = () => {
    history.push(routes.app.index);
  };

  return (
    <div
      className={[
        'bg-transparent-dark-gray p-5 flex flex-col justify-center items-center',
        className,
      ].join(' ')}
      {...rest}
    >
      <div className='flex flex-col items-center text-center'>
        <Label
          label={t('feedback.no_events')}
          className='text-yellow text-lg'
        />
        <Label
          label={t('feedback.invalid_search_description')}
          className='text-yellow mb-10'
        />
        <Button onClick={goTo}>{t('feedback.home')}</Button>
      </div>
    </div>
  );
}

NoEventsFound.propTypes = {
  className: PropTypes.string,
};

export default NoEventsFound;
