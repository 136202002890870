import {
  SET_USER, SET_USER_LOGGED_IN, SET_USER_VERIFIED
} from '../actions/types/user';

const initialState = () => {
  return {
    user: {},
    loggedIn: false,
    verified: false,
  };
};

export default function userReducer(state = initialState(), action) {
  switch (action.type) {
  case SET_USER:
    return Object.assign({}, state, {
      user: action.payload
    });

  case SET_USER_LOGGED_IN:
    return Object.assign({}, state, {
      loggedIn: action.payload
    });

  case SET_USER_VERIFIED:
    return Object.assign({}, state, {
      verified: action.payload
    });

  default:
    return state;
  }
}
