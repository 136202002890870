import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import config, { AVAILABLE_ENVIRONMENTS } from './config';
import * as serviceWorker from './serviceWorker';
import './i18n';

import './index.css';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
// if (config.ENVIRONMENT === AVAILABLE_ENVIRONMENTS.DEVELOPMENT) {
//   serviceWorker.unregister();
// } else {
//   serviceWorker.register();
// }
