/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import EventModal from './EventModal';
import CustomMapView from './views/CustomMapView';
import CustomAgendaView from './views/CustomAgendaView';
import RBCToolbar from './views/CustomToolbar';
import { ENGLISH } from '../../helpers/SupportedLanguages';

const MomentLanguage = {
  en: 'en-gb',
  'en-gb': 'en-gb',
  de: 'de',
  at: 'de-at',
  de_at: 'de-at',
  nl: 'nl',
  de_ch: 'de-ch',
  it_ch: 'it-ch',
  fr_ch: 'fr-ch',
  es: 'es',
  fi: 'fi',
  za: 'en-gb',
  se: 'sv',
  dk: 'da',
  pt: 'pt',
  pl: 'pl',
  gr: 'el',
  il: 'he',
  no: 'nn',
};

const mapStateToProps = (state) => {
  require(`moment/locale/${
    MomentLanguage[state.localeReducer.locale.toLocaleLowerCase()] || 'en-gb'
  }.js`);

  return {
    locale:
      MomentLanguage[state.localeReducer.locale.toLocaleLowerCase()] || 'en-gb',
    // locale: state.localeReducer.locale,
  };
};

const MONDAY = 1;
const today = new Date();
const localizer = momentLocalizer(moment);

const components = {
  agenda: {
    event: CustomAgendaView,
  },
  map: CustomMapView,
  toolbar: RBCToolbar,
};

function BigCalendar({
  events,
  view,
  onViewChange,
  onRangeChange,
  locale,
  date,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [eventData, setEventData] = useState({});

  const [datez, setDate] = useState(new Date());

  const formats = {
    eventTimeRangeFormat: () => {
      return '';
    },
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (event) => {
    setOpen(true);
    setEventData(event);
  };
  const convertToDateObject = (unixTimestamp) => {
    return new Date(unixTimestamp * 1000);
  };

  const transformToDate = events.map((currentEvent) => {
    const copyObject = Object.assign({}, currentEvent);
    copyObject.start = convertToDateObject(currentEvent.start);
    copyObject.end = convertToDateObject(currentEvent.end);
    return copyObject;
  });

  const fetchEventBackground = (event) => {
    return {
      style: {
        borderRadius: '0px',
        padding: '6px 0px 6px 0px',
        fontSize: '15px',
        margin: '0px',
        // backgroundColor: '#ffffff',
      },
      className:
        event.attendees.length >= event.attendeeLimit
          ? 'bg-gray'
          : !event.pathwayItem
          ? event.isOnline
            ? 'bg-electric-blue'
            : 'bg-navy-blue'
          : 'bg-navy-blue',
    };
  };

  return (
    <>
      {eventData && eventData.start && (
        <EventModal
          handleClose={handleClose}
          open={open}
          eventData={eventData}
          locale={locale}
        />
      )}
      <Calendar
        popup
        className='color'
        formats={formats}
        culture={
          locale === ENGLISH
            ? moment.locale(ENGLISH, { week: { dow: MONDAY } })
            : locale
        }
        defaultView={view}
        localizer={localizer}
        events={transformToDate}
        components={components}
        date={date}
        onNavigate={(date) => {
          setDate(date);
        }}
        views={{
          month: true,
          week: true,
          agenda: CustomAgendaView,
          map: CustomMapView,
        }}
        min={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8)
        }
        max={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 20)
        }
        messages={{
          month: t('calendar.month_view'),
          week: t('calendar.week_view'),
          agenda: t('calendar.agenda_view'),
          map: t('calendar.map_view'),
          next: t('global.next'),
          previous: t('global.previous'),
          today: t('global.today'),
        }}
        tooltipAccessor=''
        onSelectEvent={handleOpen}
        onView={onViewChange ? onViewChange : null}
        onRangeChange={onRangeChange ? onRangeChange : null}
        eventPropGetter={fetchEventBackground}
      />
    </>
  );
}

BigCalendar.propTypes = {
  events: PropTypes.array,
  view: PropTypes.string,
  onViewChange: PropTypes.func,
  onRangeChange: PropTypes.func,
  startDate: PropTypes.number,
  locale: PropTypes.string,
  date: PropTypes.any,
};

BigCalendar.defaultProps = {
  events: [],
};

export default connect(mapStateToProps)(BigCalendar);
