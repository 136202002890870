import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import Button from '../button/Button';
import routes from '../../helpers/routes';
import User from '../../schema/User';
import Loading from '../loading-indicator/Loading';

export function VerifyAccount({ computedMatch, dispatchVerified }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [verifyAccountMutation] = useMutation(User.VERIFY_ACCOUNT);
  const dataToken = {
    verifyAccountToken: computedMatch.params.token,
  };

  const { loading, error } = useQuery(User.IS_TOKEN_EXPIRED, {
    variables: { input: dataToken },
  });

  if (loading) {
    return <Loading />;
  }

  function goLogIn() {
    history.replace(routes.login);
  }

  const submit = async function (e) {
    e.preventDefault();

    try {
      const ret = await verifyAccountMutation({
        variables: { input: dataToken },
      });

      if (ret.data) {
        dispatchVerified(true);
        goLogIn();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form onSubmit={submit} className='flex flex-col items-center'>
      {!loading && error && <p className='mb-5'>{t('something_went_wrong')}</p>}
      <Button type='submit'>{t('click_to_activate_account')}</Button>
    </form>
  );
}

VerifyAccount.propTypes = {
  computedMatch: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool,
  dispatchVerified: PropTypes.func,
};

export default VerifyAccount;
