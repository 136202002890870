/* eslint-disable */

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import HTMLReactParser from 'html-react-parser';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import SelectionFrame from './frames/SelectionFrame';
import Label from '../label/Label';
import deGs4 from '../../assets/de/flow/gs4.jpg';
import deMs from '../../assets/de/flow/ms.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    alignContent: 'center',
    fontWeight: 'bold',
    color: '#170f5f',
  },
  imgWrapperClasses: {
    display: 'block',
    margin: '0 auto',
    width: '40%',
  },
}));

const HIGHLIGHT_MARKER = /\*\*\*(\S[^*]+\S)\*\*\*/g;
export default function RadioSelection(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <SelectionFrame
      handleBack={props.handleBack}
      currentStepData={props.currentStepData}
      renderHeader={
        <>
          <h1 className='p-2 text-3xl text-electric-blue md:p-5'>
            {props?.stepName.length ? (
              <span>
                {HTMLReactParser(
                  t(props.stepName).replace(
                    HIGHLIGHT_MARKER,
                    '<span class="text-green uppercase font-normal">$1</span>'
                  )
                )}
              </span>
            ) : (
              <span>{t(props.stepName)}</span>
            )}
          </h1>
          {props?.DEshouldDisplayImages === true && (
            <div className={classes.root}>
              <Grid container spacing={1}>
                <Grid item xs>
                  <p className={classes.paper}>
                    {t('cvent.steps.cvent_image_flow_gs4')}
                  </p>
                </Grid>
                <Grid item xs>
                  <p className={classes.paper}>
                    {t('cvent.steps.cvent_image_flow_ms')}
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs>
                  <Paper className={classes.paper}>
                    <img
                      className={classes.imgWrapperClasses}
                      src={deGs4}
                      alt='deGs4'
                    />
                  </Paper>
                </Grid>
                <Grid item xs>
                  <Paper className={classes.paper}>
                    <img
                      className={classes.imgWrapperClasses}
                      src={deMs}
                      alt='deMs'
                    />
                  </Paper>
                </Grid>
              </Grid>
            </div>
          )}

          {props.stepDescription && <Label label={t(props.stepDescription)} />}
        </>
      }
      renderContent={
        <>
          {props.options.map((item, k) => (
            <div
              key={k}
              onClick={() => (item.to ? props.handleClick(item) : null)}
              className={`p-2 text-dark-grey-body bg-atmospheric-white ${
                k < props.options.length - 1 ? 'mb-1' : ''
              }  ${
                !item.to ? 'bg-pale-gray' : 'cursor-pointer hover:bg-teal'
              } md:p-5`}
              style={{
                border: '3px solid #D0D0D0',
              }}
              disabled={!item.to}
            >
              {HTMLReactParser(
                t(item.title).replace(
                  HIGHLIGHT_MARKER,
                  '<span class="text-green uppercase font-normal">$1</span>'
                )
              )}
            </div>
          ))}
        </>
      }
      renderFooter={
        <>
          {props.stepDisclaimer && (
            <Label
              label={t(props.stepDisclaimer)}
              className='mt-2 mb-2 text-black border-2 border-solid border-medtronic'
            />
          )}
        </>
      }
    />
  );
}

RadioSelection.propTypes = {
  options: PropTypes.array.isRequired,
  stepName: PropTypes.string.isRequired,
  stepDescription: PropTypes.string,
  stepDisclaimer: PropTypes.string,
  handleClick: PropTypes.func,
  handleBack: PropTypes.func,
};

RadioSelection.defaultProps = {
  options: [],
  stepName: '',
};
