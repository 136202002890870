import { gql } from '@apollo/client';
const TYPE_LOGIN_ERROR = 'LoginError';
const TYPE_LOGIN_TOKEN = 'LoginToken';

const LOGIN = gql`
  mutation loginMutation($input: LoginInput!) {
    loginToken(input: $input) {
      ... on LoginToken {
        accessToken
        refreshToken
        expiresIn
      }
      ... on LoginError {
        error
        errorDescription
      }
    }
  }
`;

const LOGINDASHBOARD = gql`
  mutation loginTokenDashboard($input: LoginResponseDashboardInput!) {
    loginTokenDashboard(input: $input) {
      response
    }
  }
`;

const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      response
    }
  }
`;

const FORGOT_PASSWORD_DASHBOARD = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPasswordDashboard(input: $input) {
      response
    }
  }
`;

const CONFIRM_PASSWORD = gql`
  mutation ConfirmPassword($input: ConfirmPasswordInput!) {
    confirmPassword(input: $input) {
      response
    }
  }
`;

const CONFIRM_PASSWORD_DASHBOARD = gql`
  mutation ConfirmPassword($input: ConfirmPasswordInput!) {
    confirmPasswordDashboard(input: $input) {
      response
    }
  }
`;

const REFRESH = gql`
  mutation RefreshMutation($input: RefreshInput!) {
    refreshToken(input: $input) {
      ... on LoginToken {
        accessToken
        refreshToken
        expiresIn
      }
      ... on LoginError {
        error
        errorDescription
      }
    }
  }
`;
const REGISTER = gql`
  mutation createUserMutation($input: NewUserInput!) {
    createUser(input: $input) {
      username
    }
  }
`;

const REGISTER_DASHBOARD = gql`
  mutation createUserMutation($input: NewUserInput!) {
    createUserDashboard(input: $input) {
      response
    }
  }
`;

const GET_PROFILE_INFO = gql`
  query {
    getProfileData {
      name
      username
      city
      country {
        name
        alpha2
      }
    }
  }
`;

const GET_PROFILE_DASHBOARD_INFO = gql`
  query {
    getProfileDashboardData {
      name
      username
      city
      country {
        name
        alpha2
      }
    }
  }
`;
const GET_USERS_DASHBOARD = gql`
  query {
    getUsersDashboardData {
      id
      username
      name
      active
      role
      verified
      country_id
    }
  }
`;

const VERIFY_ACCOUNT = gql`
  mutation verifyAccount($input: VerifyAccountInput!) {
    verifyAccount(input: $input) {
      response
    }
  }
`;

const IS_VERIFIED = gql`
  query isVerifiedQuery($input: IsVerifiedInput!) {
    isVerified(input: $input) {
      response
    }
  }
`;

const SEND_EMAIL_VERIFY_ACCOUNT = gql`
  query verifyAccountSendEmailAgainQuery($input: IsVerifiedInput!) {
    verifyAccountSendEmailAgain(input: $input) {
      response
    }
  }
`;

const UPDATE_USER_DATA = gql`
  mutation updateUserProfile($input: ProfileInput!) {
    updateUserProfile(input: $input) {
      city
      username
    }
  }
`;

const UPDATE_USERS_ADMINISTRATION = gql`
  mutation updateUsersProfile($input: ProfileInputDashboard!) {
    updateUsersProfile(input: $input) {
      response
    }
  }
`;

const CHANGE_PASWORD = gql`
  mutation updatePassword($input: PasswordInput!) {
    updatePassword(input: $input) {
      response
    }
  }
`;

const CHANGE_PASWORD_DASHBOARD = gql`
  mutation updatePasswordDashboard($input: PasswordInputDashboard!) {
    updatePasswordDashboard(input: $input) {
      response
    }
  }
`;

const IS_TOKEN_EXPIRED = gql`
  query isVerifyAccountTokenExpiredQuery($input: VerifyAccountInput!) {
    isVerifyAccountTokenExpired(input: $input) {
      response
    }
  }
`;

const GET_USER_EVENTS = gql`
  query {
    SCHviewer {
      attendeeTo {
        id
        event {
          id
          title
          location
          locationName
          start
          end
          description
          color
          city {
            administrativeAreaLevel1
            administrativeAreaLevel2
            country
            locality
            lat
            lng
          }
          attendeeLimit
          isOnline
          attendees {
            _id
          }
          organizers {
            email
          }
          lat
          lng
        }
      }
    }
  }
`;

const DELETE_ACCOUNT = gql`
  mutation {
    deleteAccount {
      response
    }
  }
`;

const DELETE_ACCOUNT_DASHBOARD = gql`
  mutation deleteAccountDashboard($input: UserDashboardDeleteInput!) {
    deleteAccountDashboard(input: $input) {
      response
    }
  }
`;

const User = {
  LOGIN,
  LOGINDASHBOARD,
  REFRESH,
  REGISTER,
  REGISTER_DASHBOARD,
  TYPE_LOGIN_ERROR,
  TYPE_LOGIN_TOKEN,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_DASHBOARD,
  CONFIRM_PASSWORD,
  CONFIRM_PASSWORD_DASHBOARD,
  GET_PROFILE_INFO,
  GET_PROFILE_DASHBOARD_INFO,
  GET_USERS_DASHBOARD,
  SEND_EMAIL_VERIFY_ACCOUNT,
  IS_VERIFIED,
  VERIFY_ACCOUNT,
  UPDATE_USER_DATA,
  UPDATE_USERS_ADMINISTRATION,
  CHANGE_PASWORD,
  CHANGE_PASWORD_DASHBOARD,
  IS_TOKEN_EXPIRED,
  GET_USER_EVENTS,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_DASHBOARD,
};

export default User;
