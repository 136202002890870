import * as jwt from 'jsonwebtoken';
import { TokenExpiredError } from 'jsonwebtoken';
import Cookies from 'js-cookie';
import Config from '../config';
import Login from '../schema/User';
import client from '../client';
export var LoginState;
(function(LoginState) {
  LoginState[(LoginState['LOADING'] = 0)] = 'LOADING';
  LoginState[(LoginState['LOGGED_IN'] = 1)] = 'LOGGED_IN';
  LoginState[(LoginState['NOT_LOGGED_IN'] = 2)] = 'NOT_LOGGED_IN';
})(LoginState || (LoginState = {}));
let jwtData = null;
let jwtRefreshData = null;
let sessionCookie = null;
export default class Auth {
  static async getCertFromUrl(url) {
    const response = await fetch(url);
    if (response && !response.ok && response.body)
      throw new Error(
        `fetch ${url}: unexpected response ${response.statusText}`
      );
    return response.text();
  }
  static async getCert(issuer) {
    if (issuer in Config.VALID_ISSUERS) {
      if (issuer in Config.LOCAL_CERTS) {
        return Config.LOCAL_CERTS[issuer];
      } else {
        let cert = Auth.getCachedCert(issuer);

        const issuerUrl = Config.VALID_ISSUERS[issuer] + '/oauth/public-key';

        cert = await Auth.getCertFromUrl(issuerUrl);
        if (cert) {
          Auth.cacheCert(issuer, cert);
        }

        if (cert) {
          return cert;
        }

        alert('Invalid issuer');
        throw new Error(`Invalid issuer '${issuer}'`);
      }
    }
  }
  static getCachedCert(issuer) {
    return localStorage.getItem('cert-' + issuer);
  }
  static cacheCert(issuer, cert) {
    localStorage.setItem('cert-' + issuer, cert);
  }
  static async parseJwtToken(token) {
    const jwtToken = jwt.decode(token);
    if (!jwtToken || typeof jwtToken === 'string') {
      throw new Error('Invalid token: access token is invalid');
    }

    const cert = await Auth.getCert(jwtToken.iss);
    if (!cert) {
      throw new Error('Invalid token: can\'t load issuer public key');
    }

    try {
      if (jwt.verify(token, cert, { algorithms: ['RS256'] })) {
        return jwtToken;
      }
    } catch (e) {
      if (e instanceof TokenExpiredError) {
        return jwtToken;
      }
      throw e;
    }
    throw new Error('Invalid token: access token is invalid');
  }
  static getSessionCookie() {
    if (!sessionCookie) {
      sessionCookie = Cookies.getJSON(Config.LOGIN.COOKIE_NAME);

      Auth.setSessionCookie(sessionCookie || {});
    }
    return sessionCookie || {};
  }
  static setSessionCookie(data) {
    sessionCookie = data || {};
    const extra = {};

    if (data[Config.LOGIN.STAY_LOGGED]) {
      extra.expires = 30;
    }
    Cookies.set(Config.LOGIN.COOKIE_NAME, data, extra);

    return sessionCookie;
  }
  static getAccessToken() {
    return Auth.getSessionCookie()[Config.LOGIN.ACCESS_TOKEN]; //localStorage.getItem(Config.LOGIN.ACCESS_TOKEN)
  }
  static getRefreshToken() {
    return Auth.getSessionCookie()[Config.LOGIN.REFRESH_TOKEN]; //localStorage.getItem(Config.LOGIN.REFRESH_TOKEN)
  }
  static getStayLogged() {
    return !!Auth.getSessionCookie()[Config.LOGIN.STAY_LOGGED]; //localStorage.getItem(Config.LOGIN.STAY_LOGGED) === '1'
  }
  static async setTokens(accessToken, refreshToken, persistent = false) {
    const c = Auth.getSessionCookie();
    let ret = false;
    c[Config.LOGIN.ACCESS_TOKEN] = accessToken;
    c[Config.LOGIN.REFRESH_TOKEN] = refreshToken;
    if (persistent) {
      c[Config.LOGIN.STAY_LOGGED] = '1';
    } else {
      delete c[Config.LOGIN.STAY_LOGGED];
    }
    jwtData = await Auth.parseJwtToken(accessToken);
    if (jwtData) {
      c[Config.LOGIN.DATA] = JSON.stringify(jwtData);
      jwtRefreshData = await Auth.parseJwtToken(refreshToken);
      if (jwtRefreshData) {
        c[Config.LOGIN.REFRESH_DATA] = JSON.stringify(jwtRefreshData);
        ret = true;
      }
    }
    Auth.setSessionCookie(c);
    localStorage.setItem(Config.LOGIN.LOGIN_STORAGE_KEY, JSON.stringify(c));

    return ret;
  }
  static getJwtData() {
    if (!jwtData) {
      const d = Auth.getSessionCookie()[Config.LOGIN.DATA];
      if (d) {
        jwtData = JSON.parse(d);
      }
    }
    return jwtData;
  }
  static getJwtRefreshData() {
    if (!jwtRefreshData) {
      const d = Auth.getSessionCookie()[Config.LOGIN.REFRESH_DATA];
      if (d) {
        jwtRefreshData = JSON.parse(d);
      }
    }
    return jwtRefreshData;
  }
  static logout() {
    Auth.clearTokens();
    localStorage.clear();
    localStorage.setItem(Config.LOGIN.LOGOUT_STORAGE_KEY, '1');
    Cookies.remove(Config.LOGIN.COOKIE_NAME);
    Auth.setSessionCookie({});
    client.resetStore();
  }
  static clearTokens() {
    jwtData = null;
    jwtRefreshData = null;
    const c = Auth.getSessionCookie();
    delete c[Config.LOGIN.ACCESS_TOKEN];
    delete c[Config.LOGIN.REFRESH_TOKEN];
    Auth.setSessionCookie(c);
  }
  static getLoadingState() {
    try {
      const jd = Auth.getJwtData();

      if (jd) {
        if (jd.exp * 1000 <= Date.now()) {
          const refreshToken = Auth.getRefreshToken();
          if (refreshToken) {
            const refreshJwtData = Auth.getJwtRefreshData();
            if (refreshJwtData) {
              if (refreshJwtData.exp * 1000 > Date.now()) {
                Auth.doRefreshToken(refreshToken);
                return LoginState.LOADING;
              }
              return LoginState.NOT_LOGGED_IN;
            }
          }

          return LoginState.NOT_LOGGED_IN;
        }

        return LoginState.LOGGED_IN;
      }
    } catch (e) {
      console.error(e);
    }

    return LoginState.NOT_LOGGED_IN;
  }
  static async isLoggedIn() {
    try {
      const jd = Auth.getJwtData();

      if (jd) {
        if (jd.exp * 1000 <= Date.now()) {
          const refreshToken = Auth.getRefreshToken();
          if (refreshToken) {
            const refreshJwtData = Auth.getJwtRefreshData();
            if (refreshJwtData) {
              if (refreshJwtData.exp * 1000 > Date.now()) {
                await Auth.doRefreshToken(refreshToken);
                return true;
              }
              return false;
            }
          }
          return false;
        }
        return true;
      }
    } catch (e) {
      console.log(e);
    }
    return false;
  }
  static async doRefreshToken(refreshToken) {
    try {
      Auth.clearTokens();
      const clientId = Config.CLIENT_ID;
      const ret = await client.mutate({
        mutation: Login.REFRESH,
        variables: {
          input: {
            refreshToken,
            clientId
          }
        }
      });

      const loginResponse = ret.data && ret.data.refreshToken;

      if (loginResponse) {
        if (loginResponse.__typename === Login.TYPE_LOGIN_TOKEN) {
          const lt = loginResponse;
          if (lt.accessToken && lt.refreshToken && lt.expiresIn) {
            await Auth.setTokens(
              lt.accessToken,
              lt.refreshToken,
              Auth.getStayLogged()
            );
            return true;
          }
        } else if (loginResponse.__typename === Login.TYPE_LOGIN_ERROR) {
          const le = loginResponse;
          console.error(le.errorDescription);
        } else {
          console.error('unknown error');
        }
      } else {
        console.error('unknown error');
      }
    } catch (e) {
      console.error(e);
    }

    Auth.logout();
    return false;
  }
}
