/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { store } from '../../store/redux';
import Button from '../button/Button';
import BoxShadow from '../box-shadow/BoxShadow';
import Event from '../../schema/Event';
import Text from '../../components/forms/inputs/Text';
import { TimeWindow } from '../../helpers/CalendarEventWindowTime';

export default function CancelRezervationModal({
  eventdata,
  google,
  locale,
  handleClose,
}) {
  const { t } = useTranslation();
  const [mutateDelete] = useMutation(Event.DELETE_NODE);
  const [deleteUserEventFromDashboard] = useMutation(
    Event.DELETE_USER_EVENT_REGISTER_TO_DASHBOARD
  );

  const [sendMailAtendeeCancelEvent] = useMutation(
    Event.SEND_EMAIL_ATTENDEE_CANCEL_EVENT
  );
  const alpha2 = store.getState().userReducer.user
    ? store.getState().userReducer.user
    : { country: { alpha2: 'FAIL' } };

  const timeFrameWindow = TimeWindow[alpha2.country.alpha2] || [
    { myEventTimeBeforeCancel: 0 },
  ];

  const [name, setName] = useState('');
  async function submit(event) {
    const timeNow = Math.round(new Date().getTime() / 1000);

    const allowToUnregister =
      eventdata.start.getTime() / 1000 - timeNow <
      timeFrameWindow[0].myEventTimeBeforeCancel
        ? true
        : false;

    event.preventDefault();
    if (!allowToUnregister) {
      try {
        const res = await mutateDelete({
          variables: {
            input: {
              id: eventdata.atendeeEventId,
            },
          },
        }).then(async () => {
          await deleteUserEventFromDashboard({
            variables: {
              input: {
                eventID: eventdata.eventStored.id,
                userUsername: eventdata.userStorage.username,
              },
            },
          });
        });

        if (res && res.data) {
          await sendMailAtendeeCancelEvent({
            variables: {
              input: {
                eventTitle: eventdata.title,
                eventDate: moment(eventdata.start).format(
                  'MMMM Do YYYY, h:mm A'
                ),
                name: name,
                eventLocation: eventdata.location,
                email: eventdata.userStorage.username,
                organizerEmail: '',
                environmentType: process.env.REACT_APP_ENV,
                countryUser: alpha2.country?.alpha2,
              },
            },
          });
          if (eventdata.organizers && eventdata.organizers.length > 0) {
            for (const organizer of eventdata.organizers) {
              if (organizer.email) {
                await sendMailAtendeeCancelEvent({
                  variables: {
                    input: {
                      eventTitle: eventdata.title,
                      eventDate: moment(eventdata.start).format(
                        'MMMM Do YYYY, h:mm A'
                      ),
                      name: name,
                      eventLocation: eventdata.location,
                      email: eventdata.userStorage.username,
                      organizerEmail: organizer.email,
                      environmentType: process.env.REACT_APP_ENV,
                      countryUser: alpha2.country?.alpha2,
                    },
                  },
                });
              }
            }
          }
        }
      } catch (e) {
        console.log('Error' + JSON.stringify(e));
      } finally {
        handleClose();
      }
    } else {
      alert('Not allowed because time passed');
    }
  }

  return (
    <BoxShadow className='m-auto lg:w-4/5'>
      <div className='flex flex-col text-primary font-bold items-center justify-center'>
        <ValidatorForm onSubmit={submit} className='flex flex-col items-center'>
          <div>
            <h1 className='text-xl'>
              {t('cancel_rezervation_message_p1')}
              {eventdata.title}
              {t('cancel_rezervation_message_p2')}
            </h1>

            <Text
              validators={['required']}
              errorMessages={[t('registration_error_required')]}
              value={name}
              onChange={(value) => setName(value)}
              placeholder={t('register.attendee.first_and_last_name')}
              autoFocus
            />
          </div>
          <div className='flex-auto flex space-x-6'>
            <Button
              className='flex items-center justify-center rounded-md px-14 mt-1'
              eventdata={eventdata}
              type='submit'
            >
              {t('confirm_button')}
            </Button>
            <Button
              type='button'
              className=' flex items-center justify-center rounded-md px-14 mt-1'
              eventdata={eventdata}
              onClick={handleClose}
            >
              {t('cancel_button')}
            </Button>
          </div>
        </ValidatorForm>
      </div>
    </BoxShadow>
  );
}

export const Single = CancelRezervationModal;

CancelRezervationModal.propTypes = {
  eventdata: PropTypes.object.isRequired,
  google: PropTypes.object.isRequired,
  locale: PropTypes.string,
  handleClose: PropTypes.func,
};

CancelRezervationModal.defaultProps = {
  eventdata: {},
  google: {},
};
