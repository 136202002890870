/* eslint-disable */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProSidebarProvider, Menu, MenuItem } from 'react-pro-sidebar';
import { Typography } from '@material-ui/core';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import routes from '../../helpers/routes';
import {
  setUserLoggedInDashboard,
  setUserDashboard,
} from '../../actions/userDashboard';

const mapDispatchToProps = (dispatch) => ({
  dispatchLoggedInDashboard: (loggedIn) =>
    dispatch(setUserLoggedInDashboard(loggedIn)),
  dispatchUserDasboard: (loggedUser) => dispatch(setUserDashboard(loggedUser)),
});

const mapStateToProps = (state) => {
  return {
    loggedInDashboard: state.userDashboardReducer.loggedIn,
    userDashboard: state.userDashboardReducer.user,
  };
};

const MenuDashboard = (props) => {
  // const [isCollapsed, setIsCollapsed] = useState(false);
  // const [selected, setSelected] = useState('Dashboard');
  const history = useHistory();

  const shutdown = () => {
    props.dispatchLoggedInDashboard(false);
    props.dispatchUserDasboard({});
    history.replace(routes.login_dashboard);
  };

  const goTablePage = () => {
    history.push(routes.dashboard.table_dashboard);
  };

  const goAdminPage = () => {
    history.push(routes.dashboard.index);
  };

  const goSettingsPage = () => {
    history.push(routes.dashboard.settings_dashboard);
  };

  const goStatisticsPage = () => {
    history.push(routes.dashboard.statistics_dashboard);
  };
  const goJiraTicketCreate = () => {
    history.push(routes.dashboard.jira_dashboard_ticket_create);
  };
  const goJiraTicketList = () => {
    history.push(routes.dashboard.jira_dashboard_ticket_list);
  };

  const goAdministrateUsers = () => {
    history.push(routes.dashboard.administrate_users_dashboard);
  };

  return (
    <ProSidebarProvider breakPoint='lg'>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ flex: 1, marginBottom: '32px' }}>
          <Menu>
            <MenuItem
              active={true}
              icon={<HomeOutlinedIcon />}
              onClick={goAdminPage}
            >
              {' '}
              Admin
            </MenuItem>
          </Menu>

          <div style={{ padding: '0 24px', marginBottom: '8px' }}>
            <Typography
              variant='body2'
              fontWeight={600}
              // style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' }}
            >
              General
            </Typography>
          </div>
          <Menu>
            {/* <SubMenu
              defaultOpen={true}
              label='Charts'
              icon={<MenuOutlinedIcon />} */}
            <MenuItem icon={<TableChartOutlinedIcon />} onClick={goTablePage}>
              Table
            </MenuItem>
            <MenuItem
              icon={<BarChartOutlinedIcon />}
              onClick={goStatisticsPage}
            >
              Statistics
            </MenuItem>
            {/* <MenuItem
              icon={<PlaylistAddCheckOutlinedIcon />}
              onClick={goJiraTicketList}
            >
              Jira Ticket List
            </MenuItem>
            <MenuItem
              icon={<PlaylistAddOutlinedIcon />}
              onClick={goJiraTicketCreate}
            >
              Jira Ticket Create
            </MenuItem> */}

            {/* </SubMenu> */}
          </Menu>

          <div
            style={{
              padding: '0 24px',
              // marginBottom: '8px',
              // marginTop: '32px',
            }}
          >
            <Typography
              variant='body2'
              fontWeight={600}
              // style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' }}
            >
              Extra
            </Typography>
          </div>

          <Menu>
            <MenuItem
              icon={<PersonOutlineOutlinedIcon />}
              onClick={goSettingsPage}
            >
              Profile
            </MenuItem>
            {props.userDashboard?.role === 'admin' && (
              <MenuItem
                icon={<SupervisorAccountOutlinedIcon />}
                onClick={goAdministrateUsers}
              >
                Administrate Users
              </MenuItem>
            )}
            <MenuItem onClick={shutdown} icon={<ExitToAppOutlinedIcon />}>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
    </ProSidebarProvider>
  );
};

MenuDashboard.propTypes = {
  setUser: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuDashboard);
