/* eslint-disable */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { ToastContainer, toast } from 'react-toastify';
import AccountDeletionDialog from './AccountDeletionDialog';
import TextPassword from '../forms/inputs/TextPassword';
import { Button, ButtonGreen } from '../button/Button';
import Dialog from '../dialog/Dialog';
import User from '../../schema/User';
import routes from '../../helpers/routes';
import {
  setUserDashboard,
  setUserLoggedInDashboard,
} from '../../actions/userDashboard';

const mapStateToProps = (state) => {
  return {
    loggedInDashboard: state.userDashboardReducer.loggedIn,
    userDashboard: state.userDashboardReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(setUserLoggedInDashboard(false)),
  setUserDashboard: () => dispatch(setUserDashboard({})),
});

const AWAIT_BEFORE_LOGOUT = 4000;

export function PasswordCardDashboard({
  handleSnackbar,
  userDashboard,
  logout,
  setUserDashboard,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [deleteAccountWarn, setDeleteAccountWarn] = useState(false);
  const [updateData] = useMutation(User.CHANGE_PASWORD_DASHBOARD);
  const [deleteUserAccount] = useMutation(User.DELETE_ACCOUNT_DASHBOARD);
  const [state, setState] = useState({
    oldPassword: '',
    newPassword: '',
    repeatPassword: '',
  });

  const shutdown = () => {
    setUserDashboard();
    logout();
    history.replace(routes.login_dashboard);
  };

  const handleChange = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const handleCloseDeletionWarn = (e) => {
    e.preventDefault();
    setDeleteAccountWarn(false);
  };

  const deleteAccount = async (e) => {
    e.preventDefault();

    try {
      const res = await deleteUserAccount({
        variables: {
          input: {
            email: userDashboard.username,
          },
        },
      });

      if (res && res.data) {
        toast.success(res.data.deleteAccountDashboard.response, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setDeleteAccountWarn(false);
      }
    } catch (e) {
      toast.error(e, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }

    setTimeout(() => shutdown(), AWAIT_BEFORE_LOGOUT);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsDisabled(true);
    setIsSubmitted(true);

    if (state.newPassword === state.repeatPassword) {
      try {
        const res = await updateData({
          variables: {
            input: {
              old_password: state.oldPassword,
              new_password: state.newPassword,
              username: userDashboard.username,
            },
          },
        });

        if (res && res.data) {
          handleSnackbar(t('settings.alert.password_changed'), 'success');
        }
      } catch (e) {
        handleSnackbar(t('settings.alert.incorrect_password'), 'warning');
      } finally {
        setIsDisabled(false);
      }
    } else {
      toast.error(t('registration.password_not_match'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setIsDisabled(false);
    }
  };

  return (
    <>
      <ToastContainer />

      <ValidatorForm onSubmit={handleSubmit}>
        <TextPassword
          disabled={isDisabled}
          name='oldPassword'
          placeholder={t('settings.password.current_password')}
          validators={['required']}
          value={state.oldPassword}
          onChange={handleChange}
          error={isSubmitted && state.oldPassword === '' ? true : false}
          className='mt-5 bg-atmospheric-white text-tint-stack-neutral'
        />
        <TextPassword
          disabled={isDisabled}
          name='newPassword'
          placeholder={t('settings.password.new_password')}
          validators={['required']}
          value={state.newPassword}
          onChange={handleChange}
          error={
            isSubmitted && state.newPassword !== state.repeatPassword
              ? true
              : false
          }
          className='mt-5 bg-atmospheric-white text-tint-stack-neutral'
        />
        <TextPassword
          disabled={isDisabled}
          name='repeatPassword'
          validators={['required']}
          placeholder={t('settings.password.repeat_password')}
          value={state.repeatPassword}
          onChange={handleChange}
          error={
            isSubmitted && state.newPassword !== state.repeatPassword
              ? true
              : false
          }
          className='mt-5 bg-atmospheric-white text-tint-stack-neutral'
        />
        <div className='justify-between flex'>
          <ButtonGreen type='submit' className='mt-5 rounded-full px-4'>
            {t('settings.password.change_password')}
          </ButtonGreen>
          <Button
            type='button'
            className='mt-5 bg-red rounded-full px-4'
            onClick={() => setDeleteAccountWarn(true)}
          >
            {t('settings.password.delete_account')}
          </Button>
        </div>
        <Dialog
          className='justify-center'
          open={deleteAccountWarn}
          handleClose={handleCloseDeletionWarn}
          render={
            <AccountDeletionDialog
              text={t('settings.password.delete_account_acknowledgement')}
              handleNext={deleteAccount}
              handleBack={handleCloseDeletionWarn}
            />
          }
        />
      </ValidatorForm>
    </>
  );
}
PasswordCardDashboard.propTypes = {
  handleSnackbar: PropTypes.func,
  logout: PropTypes.func,
  setUserDashboard: PropTypes.func,
  userDashboard: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordCardDashboard);
