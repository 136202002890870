import React from 'react';
// import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export default function Home({ children, className }) {
  // const { t } = useTranslation();
  return (
    <div
      className={[
        'w-full relative flex flex-col justify-center',
        className,
      ].join(' ')}
    >
      <div className='flex items-center bg-atmospheric-white '>
        <div className='lg:w-2/4 xs:mx-auto xs:px-5'>{children}</div>

        <div className='hidden w-2/4 bg-no-repeat bg-cover h-content bg-login bg-30 lg:block'></div>
      </div>

      {/* <div className='flex md:mt-0 lg:absolute'>
        {children}
        <div className='self-center hidden w-3/5 p-5 lg:block'>
          <div className='w-2/5 lg:w-2/4 '>
            <h1 className='text-3xl font-bold uppercase text-primary'>
              {t('login.welcome')}
            </h1>
            <p className='text-xl text-primary'>{t('login.description')}</p>
          </div>
        </div>
      </div>
      <div className='flex'>
        <div className='hidden w-1/4 lg:block'></div>
        <div className='hidden w-3/4 bg-no-repeat bg-cover h-content bg-login bg-30 lg:block'></div>
      </div> */}
    </div>
  );
}

Home.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
