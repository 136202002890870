/* eslint-disable  */

import React, { lazy } from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    loggedInDashboard: state.userDashboardReducer.loggedIn,
    userDashboard: state.userDashboardReducer.user,
  };
};

export function JiraDashboardTicketList(props) {
  return <div>Hello JD LIST</div>;
}

export default connect(mapStateToProps, null)(JiraDashboardTicketList);
