import React from 'react';
import PropTypes from 'prop-types';
import Text from './Text';

const THREE_ROWS = 3;

function TextArea(props) {
  const { children, rows, ...rest } = props;

  return (
    <Text multiline minRows={!rows ? THREE_ROWS : rows} {...rest}>
      {children}
    </Text>
  );
}

TextArea.propTypes = {
  children: PropTypes.node,
  rows: PropTypes.number,
};
export default TextArea;
