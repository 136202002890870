const tagSystem = {
  'cvent.steps.medtronic_age_question': {
    'cvent.steps.cvent_check_if_age_under_17': {
      key: 'Age',
      value: 'Child',
    },
    'cvent.steps.cvent_check_if_age_above_18': {
      key: 'Age',
      value: 'Adult',
    },
  },
};

const tagsExclusinCases = {
  child: [
    {
      key: 'Age',
      value: 'Child',
    },
  ],
  adult: [
    {
      key: 'Age',
      value: 'Adult',
    },
  ],
};
export function flow(summary) {
  const finalTags = [];
  const finalTagsEdit = [];

  for (const choice of summary) {
    if (tagSystem[choice.stepName]) {
      finalTags.push(tagSystem[choice.stepName][choice.option.title]);
    }
  }
  if (
    finalTags.some((e) => e.key === 'Age' && e.value === 'Child')
  ) {
    finalTagsEdit.push(...tagsExclusinCases.child);
  }
  else {
    finalTagsEdit.push(...tagsExclusinCases.adult);
  }
  return finalTagsEdit;
}
