/* eslint-disable no-unused-vars */
import {
  KeyboardDatePicker as DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    width: '100%',
    marginTop: '10px',
  },
});

const INLINE_DIALOG = 'inline';
const DISABLE_FUTURE_DATES = true;

class DatePickerValidator extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      errorMessages,
      validators,
      requiredError,
      helperText,
      validatorListener,
      ...rest
    } = this.props;
    const { isValid } = this.state;
    const { classes } = this.props;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          {...rest}
          autoOk
          error={!isValid}
          helperText={(!isValid && this.getErrorMessage()) || helperText}
          className={classes.root}
          disableFuture={DISABLE_FUTURE_DATES}
          InputAdornmentProps={{ position: 'start' }}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(DatePickerValidator);
