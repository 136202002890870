import { combineReducers } from 'redux';
import tagsReducer from './tagsReducer';
import userReducer from './userReducer';
import userDashboardReducer from './userDashboardReducer';
import localeReducer from './localeReducer';
import pathwayReducer from './pathwayReducer';
import flowFormReducer from './flowFormReducer';

const rootReducer = combineReducers({
  tagsReducer,
  userReducer,
  userDashboardReducer,
  localeReducer,
  pathwayReducer,
  flowFormReducer,
});

export default rootReducer;
