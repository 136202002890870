/* eslint-disable  */

import React, { lazy } from 'react';
import { connect } from 'react-redux';

export function extraFields() {
  return {
    adminDashboard: {
      tableFields: [
        {
          id: 'extraForm.fi_treatment_place',
          label: 'Treatment Place',
          minWidth: 170,
        },
        {
          id: 'extraForm.fi_pump_number',
          label: 'Pump Serial',
          minWidth: 170,
        },
      ],
    },
  };
}
