import PropTypes from 'prop-types';

export const BubbleFlow = ({ children, className, active, ...props }) => (
  <div
    className={`w-20 h-20 border-2 rounded-full border-navy-blue bg-white text-center flex justify-center items-center ${className}`}
    {...props}
  >
    <span
      className={`text-4xl  ${active ? 'text-navy-blue' : 'text-pale-gray'}`}
    >
      {children}
    </span>
  </div>
);

BubbleFlow.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  className: PropTypes.string,
};

export default BubbleFlow;
