/* eslint-disable  */

import React, { lazy } from 'react';
import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import * as XLSX from 'xlsx';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@material-ui/icons/GetApp';
import GetEventForDashboard from '../schema/GetEventForDashboard';
import Loading from '../components/loading-indicator/Loading';

const mapStateToProps = (state) => {
  return {
    loggedInDashboard: state.userDashboardReducer.loggedIn,
    userDashboard: state.userDashboardReducer.user,
  };
};

export function TableDashboard(props) {
  let extraFields;

  try {
    extraFields =
      require(`./dashboardImports/tableimports/${props?.userDashboard?.country?.alpha2}.js`).extraFields();
  } catch (e) {
    extraFields =
      require(`./dashboardImports/tableimports/DEFAULT.js`).extraFields();
  }

  const columns = [
    // { id: 'idInc', label: 'ID', width: 90 },
    { id: 'userUsername', label: 'Email', minWidth: 170 },
    { id: 'eventTitle', label: 'Event Title', minWidth: 170 },
    {
      id: 'eventStart',
      label: 'Event Start Date',
      minWidth: 170,
      format: true,
    },
  ];

  columns.push(...extraFields.adminDashboard.tableFields);

  const [eventsData, setEventsData] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [selectedFromDate, setSelectedFromDate] = React.useState(
    moment().subtract(1, 'month')
  );

  const handleFromDateChange = (date) => {
    setSelectedFromDate(date);
  };

  const [selectedToDate, setSelectedToDate] = React.useState(moment());

  const handleToDateChange = (date) => {
    setSelectedToDate(date);
  };

  const [
    getEvents,
    {
      data: event_data,
      loading: event_loading,
      error: event_error,
      fetchMore: event_fertchMore,
    },
  ] = useLazyQuery(GetEventForDashboard.GET_EVENTFROMDASHBOARD, {
    onCompleted: (data) => {
      // events.forEach(async (event) => {
      //   let storeEvent = event;
      //   storeEvent.extraEvent = JSON.parse(event.extraEvent);
      //   storeEvent.extraForm = JSON.parse(event.extraForm);
      //   storeEvent.extraUser = JSON.parse(event.extraUser);
      //   newEvents.push(storeEvent);
      // });

      const newArray = data.getEventForDashboard.map((event) => {
        const extraEventParsed = JSON.parse(event?.extraEvent);
        const extraFormParsed = JSON.parse(event?.extraForm);
        const extraUserParsed = JSON.parse(event?.extraUser);

        return {
          ...event,
          eventStart: moment(event?.eventStart * 1000).format('Do MMMM, HH:mm'),
          extraEvent: extraEventParsed,
          extraForm: {
            ...extraFormParsed,
            flowForm: {
              ...extraFormParsed?.flowForm,
              date_of_birth: extraFormParsed?.flowForm?.date_of_birth
                ? moment(extraFormParsed?.flowForm?.date_of_birth).format(
                    'Do MMMM YYYY'
                  )
                : '',
            },
          },
          extraUser: extraUserParsed,
        };
      });

      setEventsData(newArray);
      // data.getEventForDashboard
    },
  });
  const [
    getEventsInterval,
    {
      data: interval_data,
      loading: interval_loading,
      error: error_data,
      fetchMore: interval_fetchMore,
    },
  ] = useLazyQuery(GetEventForDashboard.GET_EVENTFROMDASHBOARDFROMTODATE, {
    onCompleted: (data) => {
      const newArray = data.getEventForDashboardDateInterval.map((event) => {
        const extraEventParsed = JSON.parse(event?.extraEvent);
        const extraFormParsed = JSON.parse(event?.extraForm);
        const extraUserParsed = JSON.parse(event?.extraUser);

        return {
          ...event,
          eventStart: moment(event?.eventStart * 1000).format('Do MMMM, HH:mm'),
          extraEvent: extraEventParsed,
          extraForm: {
            ...extraFormParsed,
            flowForm: {
              ...extraFormParsed?.flowForm,
              date_of_birth: extraFormParsed?.flowForm?.date_of_birth
                ? moment(extraFormParsed?.flowForm?.date_of_birth).format(
                    'Do MMMM YYYY'
                  )
                : '',
            },
          },
          extraUser: extraUserParsed,
        };
      });

      setEventsData(newArray);
      // data.getEventForDashboardDateInterval
    },
  });

  useEffect(async () => {
    getEvents({
      variables: {
        input: {
          userCountryName: props.userDashboard.country.name,
        },
      },
    });

    if (event_loading) {
      setEventsData([]);
    }

    if (event_error) {
      toast.warn('Error has occured', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  }, []);

  const getLatestUpdates = async () => {
    getEvents({
      variables: {
        input: {
          userCountryName: props.userDashboard.country.name,
        },
      },
    });
  };

  const getFromToDateQuery = async () => {
    getEventsInterval({
      variables: {
        input: {
          userCountryName: props.userDashboard.country.name,
          fromDate: selectedFromDate,
          toDate: selectedToDate,
        },
      },
    });
  };

  const downloadData = async () => {
    const fields = [];
    const dataPassed = [];

    columns.forEach((col) => {
      fields.push(col.label);
      dataPassed.push(col.id);
    });

    const rows = [fields];
    eventsData.forEach((e) => {
      // const eventData = [
      //   e.userUsername,
      //   e.eventTitle,
      //   e.eventStart
      //     ? moment(e.eventStart).format('Do MMMM, HH:mm')
      //     : e.eventStart,
      // ];

      const eventData = [];
      dataPassed.forEach((x) => {
        if (x.indexOf('.') > -1) {
          // var pathSplit = x.split('.');
          // eventData.push(e[pathSplit[0]][pathSplit[1]][pathSplit[2]]);

          const getObjectValueForPath = (obj, path) =>
            path.split('.').reduce((a, v) => (a ? a[v] : undefined), obj);

          eventData.push(getObjectValueForPath(e, x));
        } else {
          eventData.push(e[x]);
        }
      });
      rows.push(eventData);
    });

    const ws = XLSX.utils.aoa_to_sheet(rows, { cellDates: true });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Events');
    XLSX.writeFile(
      wb,
      `${'Admin Table ' + moment().format('Do MMMM HH mm') + '.xlsx'}`
    );
  };

  if (event_loading) {
    return <Loading />;
  }

  if (!event_data) {
    return <></>;
  }

  return (
    <div>
      <div className='grid grid-cols-4 gap-2 place-content-center'>
        <div className='grid place-content-center'>
          <Button
            variant='contained'
            color='primary'
            onClick={getLatestUpdates}
          >
            <RefreshIcon />
          </Button>
        </div>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant='inline'
            format='MM/dd/yyyy'
            margin='normal'
            id='date-picker-inline'
            label='From Date of Event'
            value={selectedFromDate}
            onChange={handleFromDateChange}
            autoOk={true}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />

          <KeyboardDatePicker
            disableToolbar
            variant='inline'
            format='MM/dd/yyyy'
            margin='normal'
            id='date-picker-inline-two'
            label='To Date of Event'
            value={selectedToDate}
            onChange={handleToDateChange}
            autoOk={true}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <div className='grid grid-cols-2 gap-2 place-content-center'>
            <Button
              className='justify-self-center'
              variant='contained'
              color='primary'
              onClick={getFromToDateQuery}
            >
              <SearchIcon />
            </Button>
            <Button
              className='justify-self-center'
              variant='contained'
              color='primary'
              onClick={downloadData}
            >
              <GetAppIcon />
            </Button>
          </div>
        </MuiPickersUtilsProvider>
      </div>

      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {eventsData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        // var editedRow = { ...row, idInc: index + 1 };
                        var editedRow = { ...row };
                        let value;

                        if (column.id.indexOf('.') > -1) {
                          // var pathSplit = column.id.split('.');
                          // value =
                          //   editedRow[pathSplit[0]][pathSplit[1]][pathSplit[2]];

                          //! V1 This fails for cases where the target path is null
                          // var deep_value = function (obj, path) {
                          //   for (
                          //     var i = 0,
                          //       path = path.split('.'),
                          //       len = path.length;
                          //     i < len;
                          //     i++
                          //   ) {
                          //     obj = obj[path[i]];
                          //   }
                          //   return obj;
                          // };

                          //! V2 ES6
                          const getObjectValueForPath = (obj, path) =>
                            path
                              .split('.')
                              .reduce((a, v) => (a ? a[v] : undefined), obj);

                          value = getObjectValueForPath(editedRow, column.id);
                        } else {
                          value = editedRow[column.id];
                        }
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {/* {column.id === 'eventStart'
                              ? moment(value).format('Do MMMM, HH:mm')
                              : value} */}
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={eventsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default connect(mapStateToProps, null)(TableDashboard);
