/* eslint-disable  */

import React, { lazy } from 'react';
import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import DateFnsUtils from '@date-io/date-fns';
import { ResponsiveBar } from '@nivo/bar';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import GetEventForDashboard from '../schema/GetEventForDashboard';
import Loading from '../components/loading-indicator/Loading';

const mapStateToProps = (state) => {
  return {
    loggedInDashboard: state.userDashboardReducer.loggedIn,
    userDashboard: state.userDashboardReducer.user,
  };
};

export function StatisticsDashboard(props) {
  const [eventsData, setEventsData] = useState([]);
  const [eventsNumberofEvents, setNumberofEvents] = useState(0);
  const [eventsNumberofPacients, seteventsNumberofPacients] = useState(0);
  const [avgPatientsPerEvent, setavgPatientsPerEvent] = useState(0);
  const [barGraph, setbarGraph] = useState([]);

  const [selectedFromDate, setSelectedFromDate] = React.useState(
    moment().subtract(1, 'month')
  );

  const handleFromDateChange = (date) => {
    setSelectedFromDate(date);
  };

  const [selectedToDate, setSelectedToDate] = React.useState(moment());

  const handleToDateChange = (date) => {
    setSelectedToDate(date);
  };

  const [
    getEvents,
    {
      data: event_data,
      loading: event_loading,
      error: event_error,
      fetchMore: event_fertchMore,
    },
  ] = useLazyQuery(GetEventForDashboard.GET_EVENTFROMDASHBOARD, {
    onCompleted: (data) => {
      const newArray = data.getEventForDashboard.map((event) => {
        return {
          ...event,
          eventStartMiliseconds: event?.eventStart,
          eventStart: moment(event?.eventStart).format('Do MMMM, HH:mm'),
          extraEvent: JSON.parse(event?.extraEvent),
          extraForm: JSON.parse(event?.extraForm),
          extraUser: JSON.parse(event?.extraUser),
        };
      });
      doMultipleThings(newArray);
    },
  });
  const [
    getEventsInterval,
    {
      data: interval_data,
      loading: interval_loading,
      error: error_data,
      fetchMore: interval_fetchMore,
    },
  ] = useLazyQuery(GetEventForDashboard.GET_EVENTFROMDASHBOARDFROMTODATE, {
    onCompleted: (data) => {
      const newArray = data.getEventForDashboardDateInterval.map((event) => {
        return {
          ...event,
          eventStartMiliseconds: event?.eventStart,
          eventStart: moment(event?.eventStart).format('Do MMMM, HH:mm'),
          extraEvent: JSON.parse(event?.extraEvent),
          extraForm: JSON.parse(event?.extraForm),
          extraUser: JSON.parse(event?.extraUser),
        };
      });

      doMultipleThings(newArray);
    },
  });

  useEffect(async () => {
    getEvents({
      variables: {
        input: {
          userCountryName: props.userDashboard.country.name,
        },
      },
    });

    if (event_loading) {
      setEventsData([]);
    }

    if (event_error) {
      toast.warn('Error has occured', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  }, []);
  function doMultipleThings(newArray) {
    setEventsData(newArray);

    const eventsSortAndCountPerID =
      newArray.length > 0 ? countBy(newArray, 'eventID') : {};

    setNumberofEvents(Object.keys(eventsSortAndCountPerID).length);
    seteventsNumberofPacients(newArray.length);
    const patientsAddedUp = sumObjectValues(eventsSortAndCountPerID);

    setavgPatientsPerEvent(
      patientsAddedUp / Object.keys(eventsSortAndCountPerID).length
    );

    setbarGraph(countEventsByMonth(newArray));
  }

  const getLatestUpdates = async () => {
    getEvents({
      variables: {
        input: {
          userCountryName: props.userDashboard.country.name,
        },
      },
    });
  };

  const getFromToDateQuery = async () => {
    getEventsInterval({
      variables: {
        input: {
          userCountryName: props.userDashboard.country.name,
          fromDate: selectedFromDate,
          toDate: selectedToDate,
        },
      },
    });
  };

  const countBy = (arr, prop) =>
    arr.reduce(
      (prev, curr) => ((prev[curr[prop]] = ++prev[curr[prop]] || 1), prev),
      {}
    );

  const sumObjectValues = (obj) =>
    Object.values(obj).reduce(
      (acc, val) => acc + (typeof val === 'number' ? val : 0),
      0
    );

  const structurePerValue = (arr) => {
    return arr.reduce((acc, obj) => {
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          // Recursively count nested objects
          acc[key] = structurePerValue([obj[key]], acc[key]);
        } else {
          if (!acc[key]) {
            acc[key] = {};
          }
          acc[key][obj[key]] = (acc[key][obj[key]] || 0) + 1;
        }
      });
      return acc;
    }, {});
  };

  function groupByMonth(arr) {
    const sortedArr = arr.sort(function (a, b) {
      return (
        new Date(a.eventStartMiliseconds) - new Date(b.eventStartMiliseconds)
      );
    });

    const groups = {};

    sortedArr.forEach(function (obj) {
      const date = new Date(obj.eventStartMiliseconds);
      const month = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear().toString();

      const key = month + ' ' + year;
      if (!groups[key]) {
        groups[key] = [];
      }

      groups[key].push(obj);
    });

    return groups;
  }

  function countEventsByMonth(events) {
    // Create an object to store the count of events by month
    const countByMonth = {};
    const otherData = [];

    // Loop through each event in the array
    events.forEach((event) => {
      // Parse the month from the eventStart property
      const month = moment(event.eventStartMiliseconds * 1000).format('MMM');

      // If the month already exists in the countByMonth object, increment the count
      if (countByMonth.hasOwnProperty(month)) {
        countByMonth[month]++;
        let findotherDataIndex = otherData.findIndex((e) => e.month === month);
        otherData[findotherDataIndex].totalPatientsRegPerCurrentMonth++;

        //! This is for finding and adding uniqe events per month totalEventsPerCurrentMonthList
        if (
          otherData[findotherDataIndex].totalEventsPerCurrentMonthList[
            event.eventID
          ]
        ) {
          otherData[findotherDataIndex].totalEventsPerCurrentMonthList[
            event.eventID
          ]++;
        } else {
          otherData[findotherDataIndex].totalEventsPerCurrentMonthList = {
            ...otherData[findotherDataIndex].totalEventsPerCurrentMonthList,
            [event.eventID]: 1,
          };
          otherData[findotherDataIndex].totalUniqueEventsPerCurrentMonth++;
        }
      } else {
        // Otherwise, initialize the count to 1
        countByMonth[month] = 1;
        otherData.push({
          month: month,
          totalPatientsRegPerCurrentMonth: 1,
          totalEventsPerCurrentMonthList: { [event.eventID]: 1 },
          totalUniqueEventsPerCurrentMonth: 1,
        });
      }
    });

    // Sort the countByMonth object by month
    const sortedCount = Object.entries(countByMonth).sort(
      (a, b) => a[0] - b[0]
    );
    // Convert the sorted count object into an array of objects with the specified property names
    const result = sortedCount.map((entry) => {
      const [month, count] = entry;
      const obj = {};

      let findMonthIndex = otherData.findIndex((e) => e.month === entry[0]);

      obj['month'] = month;
      obj['totalPacients'] = count;
      obj['totalUniqueEventsPerCurrentMonth'] =
        otherData[findMonthIndex].totalUniqueEventsPerCurrentMonth;
      obj['avgNumbofRegPerEvent'] =
        otherData[findMonthIndex].totalPatientsRegPerCurrentMonth /
        otherData[findMonthIndex].totalUniqueEventsPerCurrentMonth;

      // obj['isOnline'] = 22;
      // obj['isFaceToFace'] = 5;

      // obj['hot dog'] = 154;
      // obj['hot dogColor'] = 'hsl(70, 70%, 50%)';
      // obj['burger'] = 67;
      // obj['burgerColor'] = 'hsl(285, 70%, 50%)';
      // obj['sandwich'] = 133;
      // obj['sandwichColor'] = 'hsl(93, 70%, 50%)';
      // obj['kebab'] = 35;
      // obj['kebabColor'] = 'hsl(336, 70%, 50%)';
      // obj['fries'] = 155;
      // obj['friesColor'] = 'hsl(60, 70%, 50%)';
      // obj['donut'] = 134;
      // obj['donutColor'] = 'hsl(271, 70%, 50%)';

      return obj;
    });

    // Return the result array
    return result;
  }

  if (event_loading) {
    return <Loading />;
  }

  if (!event_data) {
    return <></>;
  }

  return (
    <div>
      <div className='grid grid-cols-4 gap-2 place-content-center'>
        <div className='grid place-content-center'>
          <Button
            variant='contained'
            color='primary'
            onClick={getLatestUpdates}
          >
            <RefreshIcon />
          </Button>
        </div>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant='inline'
            format='MM/dd/yyyy'
            margin='normal'
            id='date-picker-inline'
            label='From Date of Event'
            value={selectedFromDate}
            onChange={handleFromDateChange}
            autoOk={true}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />

          <KeyboardDatePicker
            disableToolbar
            variant='inline'
            format='MM/dd/yyyy'
            margin='normal'
            id='date-picker-inline-two'
            label='To Date of Event'
            value={selectedToDate}
            onChange={handleToDateChange}
            autoOk={true}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <div className='grid grid-cols-2 gap-2 flex place-content-center'>
            <Button
              className='justify-self-center'
              variant='contained'
              color='primary'
              onClick={getFromToDateQuery}
            >
              <SearchIcon />
            </Button>
          </div>
        </MuiPickersUtilsProvider>
      </div>

      <div>Total of events: {eventsNumberofEvents}</div>
      <div>Total number of registrations: {eventsNumberofPacients}</div>
      <div>
        Average number of registrations per event: {avgPatientsPerEvent}
      </div>
      <div style={{ height: 600 }}>
        <ResponsiveBar
          data={barGraph}
          keys={[
            'totalPacients',
            'totalUniqueEventsPerCurrentMonth',
            'avgNumbofRegPerEvent',
          ]}
          indexBy='month'
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={{ scheme: 'category10' }}
          padding={0.6}
          groupMode='grouped'
          isFocusable={true}
          tooltip={({ id, value, color }) => (
            <div
              style={{
                padding: 10,
                background: '#ffffff',
                border: '1px solid #000000',
                borderRadius: '6px',
              }}
            >
              {/* <span>Look, I'm custom :)</span> */}
              <strong>
                {id === 'totalPacients'
                  ? 'Registrations'
                  : id === 'totalUniqueEventsPerCurrentMonth'
                  ? 'Events per Month'
                  : id === 'avgNumbofRegPerEvent'
                  ? 'Avg registrations/event'
                  : ''}
                : {value}
              </strong>
            </div>
          )}
          // defs={[
          //   {
          //     id: 'dots',
          //     type: 'patternDots',
          //     background: 'inherit',
          //     color: '#38bcb2',
          //     size: 4,
          //     padding: 1,
          //     stagger: true,
          //   },
          //   {
          //     id: 'lines',
          //     type: 'patternLines',
          //     background: 'inherit',
          //     color: '#eed312',
          //     rotation: -45,
          //     lineWidth: 6,
          //     spacing: 10,
          //   },
          // ]}
          // fill={[
          //   {
          //     match: {
          //       id: 'fries',
          //     },
          //     id: 'dots',
          //   },
          //   {
          //     match: {
          //       id: 'sandwich',
          //     },
          //     id: 'lines',
          //   },
          // ]}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Month',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Registrations',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          // legendLabel={(datum) => {
          //   return `${datum.indexValue} - ${datum.value}`;
          // }}
          // legends={[
          //   {
          //     dataFrom: 'barGraph',
          //     anchor: 'bottom-right',
          //     direction: 'column',
          //     justify: false,
          //     translateX: 120,
          //     translateY: 0,
          //     itemsSpacing: 2,
          //     itemWidth: 100,
          //     itemHeight: 20,
          //     itemDirection: 'left-to-right',
          //     itemOpacity: 0.85,
          //     symbolSize: 20,
          //     effects: [
          //       {
          //         on: 'hover',
          //         style: {
          //           itemOpacity: 1,
          //         },
          //       },
          //     ],
          //   },
          // ]}
          role='application'
        />
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(StatisticsDashboard);
