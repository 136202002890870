import { SET_USER, FETCH_USER, SET_USER_LOGGED_IN, SET_USER_VERIFIED } from './types/user';

export const setUser = user => ({
  type: SET_USER,
  payload: user
});

export const setUserLoggedIn = loggedIn => ({
  type: SET_USER_LOGGED_IN,
  payload: loggedIn
});

export const setUserVerified = verified => ({
  type: SET_USER_VERIFIED,
  payload: verified
});

export const fetchUser = () => ({
  type: FETCH_USER
});