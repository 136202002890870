/* eslint-disable  */

import React, { lazy } from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Select from '@material-ui/core/Select';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Button from '../components/button/Button';
import routes from '../helpers/routes';

const mapStateToProps = (state) => {
  return {
    loggedInDashboard: state.userDashboardReducer.loggedIn,
    userDashboard: state.userDashboardReducer.user,
  };
};

const optionsToSelect = [
  {
    id: '',
    label: 'Please select an option',
  },
  {
    id: 'bugs',
    label: 'Bugs',
  },
  { id: 'tasks', label: 'Task' },
];

export function JiraDashboardTicketCreate(props) {
  const history = useHistory();

  const [form, setForm] = useState({
    jiraOptionSelected: optionsToSelect[0],
    descriptionJiraTask: '',
  });
  const [jiraOptionSelected, setjiraOptionSelected] = useState(false);
  const [descriptionJiraTask, setdescriptionJiraTask] = useState(false);

  // useEffect(() => {
  //   const data = {
  //     jiraOptionSelected: optionsToSelect[0],
  //     descriptionJiraTask: '',
  //   };
  //   setForm(data);
  // }, []);

  function handleFormInput(key, value) {
    setForm({ ...form, [key]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    // if ((await isDisabled()) === false) {
    //   toast.success('Jira Ticket created succesfully, you will be redirected', {
    //     position: 'top-right',
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: 'colored',
    //   });
    // }
    // await submit({ ...form, formTitle: box.title });
  }

  async function isDisabled() {
    form.jiraOptionSelected.id === optionsToSelect[0].id
      ? setjiraOptionSelected(true)
      : setjiraOptionSelected(false);

    form.descriptionJiraTask === ''
      ? setdescriptionJiraTask(true)
      : setdescriptionJiraTask(false);

    if (jiraOptionSelected && descriptionJiraTask) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <ToastContainer />
      <div className='w-2/4'>
        <h1>Create a ticket for Jira</h1>
        <br />

        <ValidatorForm
          onSubmit={handleSubmit}
          instantValidate={false}
          noValidate
          // className='grid justify-items-center '
        >
          <div>
            <Select
              labelId='select-jira-option'
              id='select-jira-option'
              fullWidth
              required
              error={jiraOptionSelected}
              value={form.jiraOptionSelected}
              onChange={(value) => {
                handleFormInput('jiraOptionSelected', value.target.value);
              }}
            >
              {optionsToSelect.map((option, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={option}
                    disabled={i == 0 ? true : false}
                  >
                    {`${option.label}`}
                  </MenuItem>
                );
              })}
            </Select>
          </div>

          <div className='py-3'>
            <TextField
              id='outlined-full-width'
              required
              error={descriptionJiraTask}
              onChange={(value) =>
                handleFormInput('descriptionJiraTask', value.target.value)
              }
              label='Description'
              // style={{ margin: 8 }}
              placeholder='Placeholder'
              helperText='Include a detailed description!'
              multiline
              rows={10}
              fullWidth
              margin='normal'
              InputLabelProps={{
                shrink: true,
              }}
              variant='outlined'
            />
          </div>

          <div className='flex justify-end mt-4'>
            <Button
              type='submit'
              className='rounded-full text-dark-grey-body px-4 '
            >
              Submit to Jira
            </Button>
          </div>
        </ValidatorForm>
      </div>
    </>
  );
}

export default connect(mapStateToProps, null)(JiraDashboardTicketCreate);
