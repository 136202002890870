const tagSystem = {
  'cvent.steps.radio_first_question': {
    'cvent.steps.mm780gA': {
      key: 'Training',
      value: 'MiniMed™ 780G (Μέρος Α)',
    },
    'cvent.steps.mm780gB': {
      key: 'Training',
      value: 'MiniMed™ 780G (Μέρος Β)',
    },
    'cvent.steps.mm780gC': {
      key: 'Training',
      value: 'MiniMed™ 780G (Αναβάθμιση)',
    },
    'cvent.steps.smartguard': {
      key: 'Training',
      value: 'Λειτουργία SmartGuard™ (Ενεργοποίηση)',
    },
  },
  'cvent.steps.radio_second_question': {
    'cvent.steps.secondDialogOpt1': {
      key: 'Training',
      value: 'Κεντρική/Νότια Ελλάδα',
    },
    'cvent.steps.secondDialogOpt2': {
      key: 'Training',
      value: 'Βόρεια Ελλάδα',
    },
    'cvent.steps.secondDialogOpt3': {
      key: 'Training',
      value: 'Αλλο',
    },
  },
};

export function flow(summary) {
  const finalTagsEdit = [];

  for (const choice of summary) {
    if (tagSystem[choice.stepName]) {
      finalTagsEdit.push(tagSystem[choice.stepName][choice.option.title]);
    }
  }
  return finalTagsEdit;
}
