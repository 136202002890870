import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import Link from '../link/Link';
import Button from '../button/Button';
import routes from '../../helpers/routes';
import Text from '../forms/inputs/Text';
import BoxShadow from '../box-shadow/BoxShadow';

function LoginDashboard({
  loading,
  invalidCred,
  username,
  password,
  setPassword,
  setUsername,
  handleClick,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars

  function goForgotPassword() {
    history.push(routes.dashboard.forgot_password_dashboard);
  }

  function goRegister() {
    history.push(routes.register_dashboard);
  }

  function submit(event) {
    event.preventDefault();
    handleClick(event);
  }

  return (
    <div className='mx-auto xs:w-full md:w-3/4 xs:mt-2'>
      <h1 className='text-4xl text-electric-blue'>
        {t('admin_dashboard.login.welcome')}
      </h1>
      <br />
      <p className='text-lg font-bold text-dark-grey-body lg:w-3/4'>
        {t('admin_dashboard.login.description')}
      </p>
      <br />

      <BoxShadow>
        <ValidatorForm onSubmit={submit} className='flex flex-col'>
          <p className='pb-2 text-sm font-bold tint-stack-neutral'>
            {t('admin_dashboard.login.login')}
          </p>

          {invalidCred && (
            <p className='text-red-600'>
              {t('registration.incorrect_email_andor_pass')}
            </p>
          )}

          <Text
            validators={['required']}
            errorMessages={[t('registration_error_required')]}
            name='username'
            autoComplete='email'
            value={username}
            onChange={(value) => setUsername(value)}
            disabled={loading}
            label={t('email_address')}
            autoFocus
          />
          <Text
            validators={['required']}
            errorMessages={[t('registration_error_required')]}
            name='password'
            label={t('password')}
            type='password'
            autoComplete='current-password'
            value={password}
            onChange={(value) => setPassword(value)}
            disabled={loading}
          />
          <FormControlLabel
            className='self-start mb-20'
            disabled={loading}
            control={<Checkbox value='remember' color='primary' />}
            label={t('remember_me')}
          />
          <Button
            type='submit'
            disabled={loading}
            className='px-4 rounded-full text-dark-grey-body'
            style={{ alignSelf: 'start' }}
          >
            {t('login.sign_in')}
          </Button>
          <div className='flex flex-col w-full mt-5 xs:flex-row xs:justify-between'>
            <Link className='text-electric-blue' onClick={goRegister}>
              {t('dont_have_account_sign_up')}
            </Link>

            <Link className='text-electric-blue' onClick={goForgotPassword}>
              {t('forgot_password')}
            </Link>
          </div>
        </ValidatorForm>
      </BoxShadow>
    </div>
  );
}

LoginDashboard.propTypes = {
  loading: PropTypes.bool,
  invalidCred: PropTypes.bool,
  password: PropTypes.string,
  setPassword: PropTypes.func,
  username: PropTypes.string,
  setUsername: PropTypes.func,
  handleClick: PropTypes.func,
};

export default LoginDashboard;
