import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Label from '../components/label/Label';
import HeaderMedtronic from '../components/header/HeaderMedtronic';
import Button from '../components/button/Button';
import routes from '../helpers/routes';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    textAlign: 'center',
    margin: '100px 0'
  },
  title: {
    textSize: '4rem',
    color: theme.palette.secondary.main
  },
  description: {
    fontSize: '2rem',
    color: theme.palette.primary.main
  },
  button: {
    margin: '20px 0'
  }
}));

function NotFoundScreen() {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const goHome = () => {
    history.push(routes.app.index);
  };

  return (
    <div>
      <HeaderMedtronic />
      <div className={classes.contentContainer}>
        <Label label={t('not_found')} variant="h1" className={classes.title} />
        <Label label={t('not_found_helper')} variant="caption" className={classes.description} />
        <Button variant="contained" onClick={goHome} className={classes.button}>{t('global.go_home')}</Button>
      </div>
    </div>
  );
}

export default NotFoundScreen;