/* eslint-disable  */

import React, { lazy } from 'react';
import { connect } from 'react-redux';

export function extraFields() {
  return {
    adminDashboard: {
      tableFields: [
        {
          id: 'extraForm.flowForm.hospital',
          label: 'Hospital',
          minWidth: 170,
        },
        {
          id: 'extraForm.flowForm.date_of_birth',
          label: 'Date of Birth',
          minWidth: 170,
        },
      ],
    },
  };
}
