// import { type } from 'os';
import React from 'react';
import PropTypes from 'prop-types';
import { TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

function InputText(props) {
  const styles = {
    inputField: {
      width: '100%',
      // marginTop: '10px',
    },
    input: {
      '&:-webkit-autofill': {
        transitionDelay: '9999s',
        transitionProperty: 'background-color, color',
      },
    },
    root: {
      fontWeight: '600',
    },
  };

  // if (type === 'date') {
  //   styles.underline = {
  //     border: 'none',
  //     '&:hover:not(.Mui-disabled):before': {
  //       borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
  //     },
  //     '&:focused': {
  //       border: 'none',
  //     },
  //   };
  // }

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const {
    children,
    label,
    name,
    validators,
    errorMessages,
    value,
    onChange,
    maxLength,
    displayMode,
    ...rest
  } = props;

  return (
    <div className={classes.inputField}>
      {displayMode !== DISPLAY_MODE.LABELESS && (
        <Typography variant='subtitle1'>{label}</Typography>
      )}
      <TextValidator
        fullWidth
        focused={false}
        InputProps={{
          classes: {
            root: classes.root,
            input: classes.input,
            underline: classes.underline,
          },
        }}
        classes={{
          root: classes.root,
        }}
        size='medium'
        name={name}
        value={value}
        maxLength={!maxLength || maxLength === ' ' ? '50' : maxLength}
        validators={validators}
        errorMessages={errorMessages}
        onChange={(e) => onChange(e.target.value)}
        {...rest}
      >
        {children}
      </TextValidator>
    </div>
  );
}

export const DISPLAY_MODE = {
  LABELESS: 1,
};

InputText.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.string,
  validators: PropTypes.array,
  errorMessages: PropTypes.array,
  onChange: PropTypes.func,
  displayMode: PropTypes.number,
};

export default InputText;
