/* eslint-disable  */

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';

import React from 'react';

import Previous from '../../button/Previous';

const HIGHLIGHT_MARKER = /\*\*\*(\S[^*]+\S)\*\*\*/g;

function TextFrame({ handleBack, render, children }) {
  const { t } = useTranslation();

  return (
    <div className='shadow-3xl'>
      <div className='flex grid items-center justify-center mt-5 bg-white md:grid-cols-2 xs:py-10 md:py-0'>
        <div className='text-lg text-electric-blue '>
          <p className='p-2 text-center text-electric-blue lg:text-xl lg:p-5'>
            {render?.length ? (
              <span>
                {HTMLReactParser(
                  render.replace(
                    HIGHLIGHT_MARKER,
                    '<span class="text-electric-blue uppercase font-normal">$1</span>'
                  )
                )}
              </span>
            ) : (
              { render }
            )}
          </p>

          <div className='flex justify-center'>{children}</div>
        </div>

        <div className='hidden w-full bg-no-repeat bg-cover h-calendar bg-login bg-30 md:block'></div>
      </div>
      <div className=''>
        {/* <div className='absolute inset-x-0 bottom-0 xs:p-8 '> */}
        <Previous className='cursor-pointer' onClick={handleBack}>
          {t('previous')}
        </Previous>
      </div>
    </div>
  );
}

TextFrame.propTypes = {
  children: PropTypes.node,
  render: PropTypes.node,
  handleBack: PropTypes.func,
};

export default TextFrame;
