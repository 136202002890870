import PropTypes from 'prop-types';
export function SupportIcon({ className, ...props }) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      className={['fill-current', className].join(' ')}
      {...props}>
      <g>
        <polygon points="62.91 62.91 51.09 62.91 51.09 60.62 60.62 60.62 60.62 10.25 10.24 10.25 10.24 20.67 7.95 20.66 7.95 7.96 62.91 7.95 62.91 62.91" />
        <path d="m45.31,60.58l-35,0l0,-10.1c0,-2.92 3.13,-4.83 6.43,-6l0,3.3a4.53,4.53 0 0 0 -3.34,4.36l0,4.82l2.32,0l0,-4.87a2.2,2.2 0 1 1 4.4,0l0,4.82l2.32,0l0,-4.82a4.52,4.52 0 0 0 -3.44,-4.37l0,-4c0.6,-0.17 1.18,-0.32 1.73,-0.44l7.57,10.72l6.2,-10.77c0.75,0.16 1.56,0.36 2.39,0.6l0,4.17a5,5 0 1 0 2.33,0l0,-3.44c3.19,1.22 6.06,3.1 6.09,5.9l0,10.12zm-4.56,-7.68a2.7,2.7 0 1 1 -2.69,-2.7a2.7,2.7 0 0 1 2.69,2.7m-1.53,-10.82l0,0a39.33,39.33 0 0 0 -5.07,-1.35l-0.82,-0.16l-5.23,9.06l-6.32,-9l-0.75,0.16c-8.49,1.8 -13.03,5.13 -13.03,9.67l0,12.45l39.64,0l0,-12.46c0,-3.62 -2.94,-6.47 -8.42,-8.37"  />
        <path d="m28,18.81a8.74,8.74 0 1 0 8.73,8.73a8.75,8.75 0 0 0 -8.73,-8.73m0,19.68a10.95,10.95 0 1 1 11,-10.95a11,11 0 0 1 -11,10.95" />
        <path d="m18.76,38.92a1.15,1.15 0 0 1 -0.77,-0.3c-0.2,-0.18 -0.39,-0.37 -0.57,-0.56s-0.45,-0.47 -0.66,-0.71a14.93,14.93 0 1 1 26.24,-9.75a1.15,1.15 0 1 1 -2.29,0a12.64,12.64 0 1 0 -22.21,8.25c0.18,0.21 0.37,0.41 0.56,0.61l0.48,0.47a1.14,1.14 0 0 1 0.08,1.61a1.16,1.16 0 0 1 -0.85,0.38" />
        <path d="m18.48,37.85a0.12,0.12 0 0 0 -0.12,0.12a0.11,0.11 0 0 0 0.12,0.11a0.11,0.11 0 0 0 0.11,-0.11a0.12,0.12 0 0 0 -0.11,-0.12m0,2.52a2.41,2.41 0 1 1 2.4,-2.4a2.41,2.41 0 0 1 -2.4,2.4" />
        <path d="m42.27,30.73a1.72,1.72 0 0 1 -1.72,-1.73l0,-4.85a1.72,1.72 0 1 1 3.44,0l0,4.85a1.72,1.72 0 0 1 -1.72,1.71" />
        <path d="m12.7,30.73a1.72,1.72 0 0 1 -1.7,-1.73l0,-4.85a1.72,1.72 0 0 1 3.44,0l0,4.85a1.72,1.72 0 0 1 -1.72,1.71" />
      </g>
    </svg>
  );
}

SupportIcon.propTypes = {
  className: PropTypes.string,
};