import React from 'react';
import PropTypes from 'prop-types';

export default function BoxShadow({ children, ...props }) {
  const { className, ...rest } = props;

  return (
    <div
      //Shadow looks more good with 3xl
      className={[
        'shadow-rebrand-shadow-box p-4 w-full bg-white',
        className,
      ].join(' ')}
      {...rest}
    >
      {children}
    </div>
  );
}

BoxShadow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
