import React from 'react';
import PropTypes from 'prop-types';

function Overlay(props) {
  const { children, className, ...rest } = props;

  return (
    <div
      {...rest}
      className={[
        'min-h-screen absolute  top-0 bottom-0 left-0 right-0 z-40 bg-fixed bg-no-repeat bg-cover bg-main ',
        className,
      ].join(' ')}
    >
      {children}
    </div>
  );
}

Overlay.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Overlay;
