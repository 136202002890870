import { gql } from '@apollo/client';

const DATA_MUTATION = gql`
  mutation FormTechInstr($input: TechInstInput!) {
    formTechInstr(input: $input) {
      response
    }
  }
`;

const TechnicalBriefing = {
  DATA_MUTATION,
};

export default TechnicalBriefing;
