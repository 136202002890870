export default [
  {
    stepNumber: '1',
    stepName: 'cvent.steps.boxes',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'CardSelection',
    options: [
      {
        from: '1',
        to: '2',
        title: 'cvent.cards.first_step.title',
        label: 'cvent.cards.first_step.button',
        image: '/workflow/minimed.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.first_step.description',
        disabled: false,
        skipFlow: false,
        flowTags: [
          {
            key: 'Training',
            value: 'Pre-Training 1',
          },
        ],
      },
      {
        from: '1',
        to: '7.1',
        title: 'cvent.cards.third_step.title',
        label: 'cvent.cards.third_step.button',
        image: '/workflow/fi_image.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.third_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: '7',
        title: 'cvent.cards.fourth_step.title',
        label: 'cvent.cards.fourth_step.button',
        image: '/workflow/fi_fourthBox.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.fourth_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: '2.1',
        title: 'cvent.cards.second_step.title',
        label: 'cvent.cards.second_step.button',
        image: '/workflow/fi_girl_in_park.jpeg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.second_step.description',
        disabled: false,
        skipFlow: false,
        flowTags: [
          {
            key: 'Training',
            value: 'Pre-Training 2',
          },
        ],
      },
    ],
  },
  {
    stepNumber: '2',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2',
        // to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Pre-Training 1',
          },
        ],
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '2',
        to: '4',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '2.1',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_arrived_title_smartguard',
    stepDescription: 'cvent.steps.cvent_check_if_product_arrived_title_smartguard',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2.1',
        // to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Pre-Training 2',
          },
        ],
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '2.1',
        to: '4',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '4',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '5',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued_2',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '6',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_exist_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_exist_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '6',
        // to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Pump',
          },
        ],
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '6',
        to: '5',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '7',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_exist_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_exist_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '7',
        to: '8',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '7',
        to: '5',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '7.1',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_exist_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_exist_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '7.1',
        to: '8.1',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '7.1',
        to: '5',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '8',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_setting_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_setting_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '8',
        to: '9',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '8',
        to: '5',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '8.1',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_setting_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_setting_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '8.1',
        to: '9.1',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '8.1',
        to: '5',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '9',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_get_in_touch_title',
    stepDescription: 'cvent.steps.cvent_check_if_get_in_touch_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '9',
        to: '10',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '9',
        to: '5',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '9.1',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_get_in_touch_title',
    stepDescription: 'cvent.steps.cvent_check_if_get_in_touch_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '9.1',
        to: '10.1',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '9.1',
        to: '5',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '10',
    centered: true,
    stepName: 'cvent.steps.cvent_check_device_title',
    stepDescription: 'cvent.steps.cvent_check_device_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '10',
        //to: '10',
        skipSummary: true,
        flowTags: [
          {
            key: 'Training',
            value: '780G',
          },
        ],
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '10',
        to: '5',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '10.1',
    centered: true,
    stepName: 'cvent.steps.cvent_check_device_title',
    stepDescription: 'cvent.steps.cvent_check_device_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '10.1',
        //to: '10',
        skipSummary: true,
        flowTags: [
          {
            key: 'Training',
            value: 'Pump',
          },
        ],
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '10.1',
        to: '5',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
];
