import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BoxShadow from '../box-shadow/BoxShadow';
import Button from '../button/Button';
import Label from '../label/Label';

export default function AccountDeletionDialog({handleBack, handleNext, text }) {
  const { t } = useTranslation();

  return (
    <BoxShadow className="w-full m-auto md:w-2/3 md:container">
      <div className="flex items-center text-center flex-col">
        <Label label={text} className="whitespace-pre-wrap"/>
        <div className="mt-4">
          <Button onClick={handleNext} className='mx-4 px-6'>{t('register.attendee.yes')}</Button>
          <Button onClick={handleBack} className='mx-4 px-6'>{t('register.attendee.no')}</Button>
        </div>
      </div>
    </BoxShadow>
  );
}

AccountDeletionDialog.propTypes = {
  text: PropTypes.string,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func
};