import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  tooltipPlacementRight: {
    fontSize: '14px',
  },
  listItemStyle: {
    justifyContent: 'center',
    padding: '10px',
  }
}));

function PatientPortalListItem(props) {
  const classes = useStyles();
  const { children, onClick, title, ...rest } = props;

  return (
    <div
      title={title}
      className={classes.listItemStyle}
      onClick={onClick}
      {...rest}
    >
      {children}
    </div>
  );
}

PatientPortalListItem.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
};

export default PatientPortalListItem;