import de from './countries/de';
import nl from './countries/nl';
import ch from './countries/ch';
import gb from './countries/gb';
import at from './countries/at';
import es from './countries/es';
import fi from './countries/fi';
import za from './countries/za';
import se from './countries/se';
import dk from './countries/dk';
import pt from './countries/pt';
import pl from './countries/pl';
import gr from './countries/gr';
import il from './countries/il';
import no from './countries/no';

export default {
  de,
  nl,
  ch,
  at,
  gb,
  es,
  fi,
  za,
  se,
  dk,
  pt,
  pl,
  gr,
  il,
  no,
};
