import PropTypes from 'prop-types';
import BoxShadow from '../../box-shadow/BoxShadow';
import Button from '../../button/Button';
import Label from '../../label/Label';

export default function RegisterFollowUp({ text, handleNext, textBtn }) {
  return (
    <BoxShadow className="w-full m-auto md:w-2/3 md:container">
      <div className="flex items-center text-center flex-col">
        <Label label={text} className="whitespace-pre-wrap" />
        <div className="mt-4">
          <Button onClick={handleNext} className="mx-4 px-6">
            {textBtn}
          </Button>
        </div>
      </div>
    </BoxShadow>
  );
}

RegisterFollowUp.propTypes = {
  text: PropTypes.string,
  textBtn: PropTypes.string,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
