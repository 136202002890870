/* eslint-disable no-unused-vars */
import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ValidatorComponent } from 'react-material-ui-form-validator';

const checkboxStyle = {
  display: 'flex',
  flexDirection: 'column',
};

class CheckboxValidator extends ValidatorComponent {
  renderValidatorComponent() {
    // eslint-disable-next-line no-unused-vars
    const {
      label,
      errorMessages,
      requiredError,
      validatorListener,
      ...rest
    } = this.props;

    return (
      <div style={checkboxStyle}>
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              {...rest}
              ref={r => {
                this.input = r;
              }}
            />
          }
        />
        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <FormHelperText error={true}>{this.getErrorMessage()}</FormHelperText>
    );
  }
}

export default CheckboxValidator;
