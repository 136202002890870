/* eslint-disable indent */
import { SET_LOCALE, SET_NOTIFICATIONLANGUAGE } from '../actions/types/locale';
import { ENGLISH } from '../helpers/SupportedLanguages';

const initialState = {
  locale: ENGLISH,
  notificationLanguage: {
    name: 'English',
    code: ENGLISH,
    notificationLanguage: 'en',
    notificationAcceptLanguage: 'en-US',
  },
};

export default function localeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOCALE:
      return Object.assign({}, state, {
        locale: action.payload,
      });

    case SET_NOTIFICATIONLANGUAGE:
      return Object.assign({}, state, {
        notificationLanguage: action.payload,
      });

    default:
      return state;
  }
}
