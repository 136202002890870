/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import MenuItem from '@material-ui/core/MenuItem';
import Text from './Text';
import Countries from '../../../schema/Countries';

export default function Country({ value, onChange, defaultLabel, ...props }) {
  const { t } = useTranslation();
  const { data, loading } = useQuery(Countries.GET_COUNTRIES);

  // eslint-disable-next-line no-undef
  const isProd = process.env.REACT_APP_ENV === 'production';

  if (loading || !data) {
    return <></>;
  }

  return (
    <Text
      select
      value={!value ? 'defaultValue' : value}
      onChange={(event) => {
        if (event === false) {
          return;
        }
        onChange(event);
      }}
      {...props}
    >
      {defaultLabel && (
        <MenuItem aria-label='None' value='defaultValue' default disabled>
          {t('global.country')}
        </MenuItem>
      )}
      {data.countries.map((country, i) => {
        return (
          <MenuItem
            key={i}
            value={country.alpha2}
            name={t(`countries.${country.name}`)}
            disabled={isProd && country.name === 'Poland'}
          >
            {t(`countries.${country.name}`)}
          </MenuItem>
        );
      })}
    </Text>
  );
}

Country.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  defaultLabel: PropTypes.any,
};
