const tagExclusion = {};

const tagSystem = {
  'cvent.steps.boxes': {
    'cvent.cards.first_step.title': {
      key: 'Product',
      value: 'Full System',
    },
    'cvent.cards.second_step.title': {
      key: 'Product',
      value: 'Pump + CGM',
    },
    'cvent.cards.third_step.title': {
      key: 'Product',
      value: 'AM',
    },
    'cvent.cards.fourth_step.title': {
      key: 'Product',
      value: 'Carelink',
    },
    'cvent.cards.fifth_step.title': {
      key: 'Product',
      value: 'Guardian',
    },
    'cvent.cards.sixth_step.title': {
      key: 'Product',
      value: 'FOTA',
    },
  },
};

export function flow(summary) {
  const finalTags = [];
  const filtered = [];

  for (const userTags of summary) {
    const stepNamesToRemove = tagExclusion[userTags.option.title] || [];

    if (stepNamesToRemove) {
      for (const stepName of stepNamesToRemove) {
        filtered.push(stepName);
      }
    }
  }
  const chosenTags = summary
    .filter(step => {
      for (const exclusion of filtered) {
        if (exclusion === step.stepName) {
          return false;
        }
      }
      return true;
    })
    .map(choice => {
      if (tagSystem[choice.stepName]) {
        return tagSystem[choice.stepName][choice.option.title];
      }
      return undefined;
    });
  for (const choice of chosenTags) {
    if (choice !== undefined) {
      finalTags.push(choice);
    }
  }
  return finalTags;
}
