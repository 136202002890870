export default [
  {
    stepNumber: '1',
    stepName: 'cvent.steps.boxes',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'CardSelection',
    options: [
      {
        from: '1',
        to: '2',
        title: 'cvent.cards.first_step.title',
        label: 'cvent.cards.first_step.button',
        image: '/workflow/minimed.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.first_step.description',
        disabled: false,
      },
    ],
  },
  {
    stepNumber: '2',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2',
        to: '5',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: '',
      },
      {
        from: '2',
        to: '4',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: '',
      },
    ],
  },
  {
    stepNumber: '4',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '5',
    centered: true,
    stepName: 'cvent.steps.se_representative',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_entry_level',
    componentType: 'RadioSelection',
    options: [
      {
        from: '5',
        to: 'Summary',
        title: 'cvent.steps.training_pump',
        label: '',
      },
      {
        from: '5',
        to: 'Summary',
        title: 'cvent.steps.training_smartGuard',
        label: '',
      },
      {
        from: '5',
        to: 'Summary',
        title: 'cvent.steps.training_carelink',
        label: '',
      },
      // {
      //   from: '5',
      //   to: 'Summary',
      //   title: 'cvent.steps.training_smartPen',
      //   label: '',
      // },
    ],
  },
  // {
  //   stepNumber: '7',
  //   centered: true,
  //   stepName: 'cvent.steps.medtronic_age_question',
  //   stepDescription: 'cvent.steps.medtronic_age_question',
  //   stepDisclaimer: '',
  //   stepCategory: 'disable_breadcumbs',
  //   componentType: 'YesOrNoSelection',
  //   options: [
  //     {
  //       from: '7',
  //       to: 'Summary',
  //       title: 'cvent.steps.cvent_check_if_age_under_17',
  //       label: '',
  //     },
  //     {
  //       from: '7',
  //       to: 'Summary',
  //       title: 'cvent.steps.cvent_check_if_age_above_18',
  //       label: '',
  //     },
  //   ],
  // },
];
