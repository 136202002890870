import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BoxShadow from '../components/box-shadow/BoxShadow';
import Home from '../components/container/Home';
import VerifyAccount from '../components/verify-account/VerifyAccount';
import { setUserVerified } from '../actions/user';

const mapStateToProps = state => {
  return {
    loggedIn: state.userReducer.loggedIn
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchVerified: verified => dispatch(setUserVerified(verified))
});


export function VerifyAccountScreen({ computedMatch, dispatchVerified, loggedIn }) {
  if (loggedIn) {
    return (
      <BoxShadow className="m-auto mt-10">
        <VerifyAccount computedMatch={computedMatch} dispatchVerified={dispatchVerified} />
      </BoxShadow>
    );
  }

  return (
    <Home>
      <BoxShadow>
        <VerifyAccount computedMatch={computedMatch} dispatchVerified={dispatchVerified} />
      </BoxShadow>
    </Home>
  );
}

VerifyAccountScreen.propTypes = {
  computedMatch: PropTypes.object.isRequired,
  dispatchVerified: PropTypes.func,
  loggedIn: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccountScreen);