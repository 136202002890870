/* eslint-disable indent */
import { SET_PATHWAY, SET_BOX } from '../actions/types/pathway';

const initialState = {};

export default function pathwayReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PATHWAY:
      return Object.assign({}, state, {
        pathway: action.payload,
      });

    case SET_BOX:
      return Object.assign({}, state, {
        box: action.payload,
      });

    default:
      return state;
  }
}
