/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import Label from '../label/Label';
import Bubble from '../ui/medtronic/Bubble';

export default function ImportantInformation({
  title,
  guideline,
  subtext,
  text,
  number,
  last,
  first,
  icon,
  header,
  footer,
  className,
}) {
  return (
    <div className='w-full '>
      <div className={['text-primary', className].join(' ')}>
        <div className='flex flex-col'>
          <div
            className={
              'flex items-center bg-navy-blue xs:pt-4 xs:pb-4 md:pb-4 md:pt-0 ' +
              `${first ? 'pl-10' : 'md:pl-0 xs:pl-10'}`
            }
          >
            <div className='z-50 w-1/6'>
              <Bubble
                active={true}
                className={header || footer ? 'hidden' : ''}
              >
                {number ? number.toString() : ''}
              </Bubble>
            </div>

            <div className='items-center w-full lg:block '>
              {!last && (
                <hr
                  className='z-0 text-center rounded-full'
                  style={{
                    borderTop: '4px dotted #0fc9f7',
                  }}
                />
              )}

              {last && (
                <hr
                  className='z-0 text-center rounded-full md:hidden'
                  style={{
                    borderTop: '4px dotted #0fc9f7',
                  }}
                />
              )}
            </div>
            {/* <div className='items-center w-full lg:block '>
              
              {!last && (
                <hr
                  className='z-0 text-center rounded-full'
                  style={{
                    borderTop: '4px dotted #0fc9f7',
                  }}
                />
              )}
            </div> */}
          </div>
        </div>

        {/* {footer && icon} */}

        <div className={' ' + `${first ? 'pl-11' : 'md:pl-2 xs:pl-6'}`}>
          {header && (
            <div className='w-5/6 text-2xl font-bold uppercase text-green'>
              {header}
            </div>
          )}
          {footer && (
            <div className='flex items-start justify-start w-5/6 text-xl font-bold uppercase text-green'>
              {footer}
            </div>
          )}
          {/* {title && (
          <div className='w-5/6'>
            <Label
              label={title}
              className='p-5 pt-0 pr-0 text-xl font-bold uppercase text-green md:p-0'
            />
          </div>
        )} */}

          {/* For future if you need title add bellow and remove mt-3 from label */}
          {/* {guideline && (
            <div className='w-5/6 py-5'>
              <Label
                label={guideline}
                className='p-5 pt-0 pr-0 text-xl text-electric-blue md:p-0'
              />
            </div>
          )} */}
          {text && (
            <div className='w-5/6 p-5 pt-0 pr-0 mt-3 text-sm md:p-0 lg:text-base'>
              <Label label={text} />
            </div>
          )}
          {subtext && (
            <div className='w-5/6 p-5 pt-0 pr-0 text-sm md:p-0 lg:text-base'>
              <Label label={subtext} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

ImportantInformation.propTypes = {
  title: PropTypes.string,
  subtext: PropTypes.string,
  className: PropTypes.string,
  number: PropTypes.number,
  text: PropTypes.string,
  guideline: PropTypes.string,
  last: PropTypes.bool,
  first: PropTypes.bool,
  icon: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
};
