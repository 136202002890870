import PropTypes from 'prop-types';

export const Bubble = ({ children, className, active, ...props }) => (
  <div
    className={`w-20 h-20 border-2 rounded-full border-light-blue bg-navy-blue text-center flex justify-center items-center ${className}`}
    {...props}
  >
    <span
      className={`text-4xl font-bold ${
        active ? 'text-white' : 'text-pale-gray'
      }`}
    >
      {children}
    </span>
  </div>
);

Bubble.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  className: PropTypes.string,
};

export default Bubble;
