import PropTypes from 'prop-types';

export function Button({ children, className, disabled, ...rest }) {
  return (
    <button
      className={[`p-2 uppercase text-white font-bold text-xs outline-none focus:outline-none break-words ${disabled ? 'bg-gray' : ''}`, className].join(' ')}
      {...rest}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export function ButtonBlue({ children, className, ...rest}) {
  return (
    <Button className={`bg-medium-blue ${className} hover:bg-green`} {...rest}>
      {children}
    </Button>
  );
}

export function ButtonGreen({ children, className, ...rest}) {
  return (
    <Button className={`bg-green ${className}`} {...rest}>
      {children}
    </Button>
  );
}

ButtonBlue.propTypes = Button.propTypes;
ButtonGreen.propTypes = Button.propTypes;

export default ButtonGreen;