const tagsExclusinCases = {
  //CGM remote/F2F
  tagCGMRemote: [
    {
      key: 'Therapy',
      value: 'CGM',
    },
  ],
  //MiniMed 780 system Starter training 1 - Training center F2F
  tag780starterTraining1: [
    { key: 'Therapy', value: 'MM780G' },
    { key: 'Pump Experience', value: 'Other' },
  ],
  //MiniMed 780 system Online training (for 640 + VEO) && F2F common event
  tag780OnlineTraining640andVeotag640GAndF2F: [
    { key: 'Therapy', value: 'MM780G' },
    { key: 'Pump Experience', value: 'Experience MDT' },
    { key: 'Pump', value: 'MM640G' },
    { key: 'Sensor Experience', value: 'Experience MDT' },
  ],
  tag780OnlineTraining640andVeotagVeoAndF2F: [
    { key: 'Therapy', value: 'MM780G' },
    { key: 'Pump Experience', value: 'Experience MDT' },
    { key: 'Pump', value: 'VEO' },
    { key: 'Sensor Experience', value: 'Experience MDT' },
  ],
  //MiniMed 780 system ONLINE training (670) && F2F common event
  tag780F2FtrainingAndF2FwithYesTag: [
    { key: 'Therapy', value: 'MM780G' },
    { key: 'Pump Experience', value: 'Experience MDT' },
    { key: 'Pump', value: 'MM670G' },
    { key: 'Sensor Experience', value: 'Experience MDT' },
  ],
  tag780F2FtrainingAndF2FwithNoTag: [
    { key: 'Therapy', value: 'MM780G' },
    { key: 'Pump Experience', value: 'Experience MDT' },
    { key: 'Pump', value: 'MM670G' },
    { key: 'Sensor Experience', value: 'Experience MDT' },
  ],
  //MiniMed 780 system Center/Online training with CGM (for 640 + VEO without CGM)
  tag780CenterANDOnlineTrainingWithCGMwithMM640g: [
    { key: 'Therapy', value: 'MM780G' },
    { key: 'Pump Experience', value: 'Experience MDT' },
    { key: 'Pump', value: 'MM640G' },
    { key: 'Sensor Experience', value: 'No Experience' },
  ],
  tag780CenterANDOnlineTrainingWithCGMwithVeo: [
    { key: 'Therapy', value: 'MM780G' },
    { key: 'Pump Experience', value: 'Experience MDT' },
    { key: 'Pump', value: 'VEO' },
    { key: 'Sensor Experience', value: 'No Experience' },
  ],
};
// for Smartguard and CGM use
// Pump experience: Other
// Therapy: SmartGuard

const ChTagSystem = {
  'cvent.steps.ch.select_product': {
    'cvent.steps.cvent_M780G': {
      key: 'Therapy',
      value: 'MM780G',
    },
    'cvent.steps.cvent_CGM': {
      key: 'Therapy',
      value: 'CGM',
    },
  },
  'cvent.steps.ch.therapy_experience': {
    'cvent.steps.ch.no_experience': {
      key: 'Pump Experience',
      value: 'Other',
    },
    'cvent.steps.ch.experience_with_mdt': {
      key: 'Pump Experience',
      value: 'Experience MDT',
    },
    'cvent.steps.ch.experience_with_other': {
      key: 'Pump Experience',
      value: 'Other',
    },
  },
  'cvent.steps.ch.select_pump': {
    'cvent.steps.cvent_VEO': {
      key: 'Pump',
      value: 'VEO',
    },
    'cvent.steps.cvent_M640G': {
      key: 'Pump',
      value: 'MM640G',
    },
    'cvent.steps.cvent_M670G': {
      key: 'Pump',
      value: 'MM670G',
    },
  },
  'cvent.steps.ch.cgm_experience': {
    'cvent.steps.ch.yes': {
      key: 'Sensor Experience',
      value: 'Experience MDT',
    },
    'cvent.steps.ch.no': {
      key: 'Sensor Experience',
      value: 'No Experience',
    },
  },
  'cvent.steps.ch.select_therapy_sensor': {
    'cvent.steps.ch.no_experience': {
      key: 'Sensor Experience',
      value: 'No Experience',
    },
    'cvent.steps.ch.experience_with_mdt': {
      key: 'Sensor Experience',
      value: 'Experience MDT',
    },
  },
};

export function flow(summary) {
  const finalTags = [];
  const finalTagsEdit = [];

  for (const choice of summary) {
    if (ChTagSystem[choice.stepName]) {
      finalTags.push(ChTagSystem[choice.stepName][choice.option.title]);
    }
  }

  if (finalTags.some(e => e.key === 'Therapy' && e.value === 'CGM')) {
    //CGM remote/F2F
    finalTagsEdit.push(...tagsExclusinCases.tagCGMRemote);
  } else if (
    //MiniMed 780 system Starter training 1 - Training center F2F
    finalTags.some(e => e.key === 'Therapy' && e.value === 'MM780G') &&
    finalTags.some(e => e.key === 'Pump Experience' && e.value === 'Other')
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tag780starterTraining1);
  } else if (
    //MiniMed 780 system ONLINE training (670) with exp mdt && f2f
    finalTags.some(e => e.key === 'Therapy' && e.value === 'MM780G') &&
    finalTags.some(
      e => e.key === 'Pump Experience' && e.value === 'Experience MDT'
    ) &&
    finalTags.some(e => e.key === 'Pump' && e.value === 'MM670G') &&
    finalTags.some(
      e => e.key === 'Sensor Experience' && e.value === 'Experience MDT'
    )
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tag780F2FtrainingAndF2FwithYesTag);
  } else if (
    //MiniMed 780 system ONLINE training (670) with no exp  && f2f
    finalTags.some(e => e.key === 'Therapy' && e.value === 'MM780G') &&
    finalTags.some(
      e => e.key === 'Pump Experience' && e.value === 'Experience MDT'
    ) &&
    finalTags.some(e => e.key === 'Pump' && e.value === 'MM670G') &&
    finalTags.some(
      e => e.key === 'Sensor Experience' && e.value === 'No Experience'
    )
  ) {
    finalTagsEdit.push(...tagsExclusinCases.tag780F2FtrainingAndF2FwithNoTag);
  } else if (
    //MiniMed 780 system Online training (for 640 + VEO) && F2F training (for 670 + 640 + VEO) with 640TAG
    finalTags.some(e => e.key === 'Therapy' && e.value === 'MM780G') &&
    finalTags.some(
      e => e.key === 'Pump Experience' && e.value === 'Experience MDT'
    ) &&
    finalTags.some(e => e.key === 'Pump' && e.value === 'MM640G') &&
    finalTags.some(
      e => e.key === 'Sensor Experience' && e.value === 'Experience MDT'
    )
  ) {
    finalTagsEdit.push(
      ...tagsExclusinCases.tag780OnlineTraining640andVeotag640GAndF2F
    );
  } else if (
    //MiniMed 780 system Online training (for 640 + VEO) && F2F training (for 670 + 640 + VEO) with VEO
    finalTags.some(e => e.key === 'Therapy' && e.value === 'MM780G') &&
    finalTags.some(
      e => e.key === 'Pump Experience' && e.value === 'Experience MDT'
    ) &&
    finalTags.some(e => e.key === 'Pump' && e.value === 'VEO') &&
    finalTags.some(
      e => e.key === 'Sensor Experience' && e.value === 'Experience MDT'
    )
  ) {
    finalTagsEdit.push(
      ...tagsExclusinCases.tag780OnlineTraining640andVeotagVeoAndF2F
    );
  } else if (
    //MiniMed 780 system Center/Online training with CGM (for 640 + VEO without CGM) tag 640
    finalTags.some(e => e.key === 'Therapy' && e.value === 'MM780G') &&
    finalTags.some(
      e => e.key === 'Pump Experience' && e.value === 'Experience MDT'
    ) &&
    finalTags.some(e => e.key === 'Pump' && e.value === 'MM640G') &&
    finalTags.some(
      e => e.key === 'Sensor Experience' && e.value === 'No Experience'
    )
  ) {
    finalTagsEdit.push(
      ...tagsExclusinCases.tag780CenterANDOnlineTrainingWithCGMwithMM640g
    );
  } else if (
    //MiniMed 780 system Center/Online training with CGM (for 640 + VEO without CGM)  tag 640
    finalTags.some(e => e.key === 'Therapy' && e.value === 'MM780G') &&
    finalTags.some(
      e => e.key === 'Pump Experience' && e.value === 'Experience MDT'
    ) &&
    finalTags.some(e => e.key === 'Pump' && e.value === 'VEO') &&
    finalTags.some(
      e => e.key === 'Sensor Experience' && e.value === 'No Experience'
    )
  ) {
    finalTagsEdit.push(
      ...tagsExclusinCases.tag780CenterANDOnlineTrainingWithCGMwithVeo
    );
  } else {
    finalTagsEdit.push(...finalTags);
  }

  return finalTagsEdit;
}
