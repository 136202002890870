import React from 'react';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
// import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
// import User from '../schema/User';
import PasswordCardDashboard from '../components/settings/PasswordCardDashboard';
// import { LoadingFullScreenCentered } from '../components/loading-indicator/Loading';

const mapStateToProps = (state) => {
  return {
    loggedInDashboard: state.userDashboardReducer.loggedIn,
    userDashboard: state.userDashboardReducer.user,
  };
};

function SettingsScreen(props) {
  let { userDashboard } = props;
  const { t } = useTranslation();
  const [profileData, setProfileData] = useState({});
  // const { loading, error, data } = useQuery(User.GET_PROFILE_INFO);

  useEffect(() => {
    // if (loading) {
    //   setProfileData({
    //     name: 'loading',
    //     email: 'loading',
    //     city: 'loading',
    //   });
    // }

    // if (error) {
    //   toast.warn('Error has occured', {
    //     position: 'top-right',
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: 'colored',
    //   });
    // }
    if (props.userDashboard) {
      setProfileData(props.userDashboard);
    }
  }, []);

  const handleSnackbar = (text, severity) => {
    if (severity === 'warning') {
      toast.warn(t(text), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } else if (severity === 'success') {
      toast.success(t(text), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } else {
      toast.info(t(text), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  // if (loading) {
  //   return <LoadingFullScreenCentered />;
  // }

  return (
    <div className='mt-10'>
      <ToastContainer />

      {profileData.country && (
        <>
          <div className='shadow-3xl flex justify-center '>
            <div className='lg:w-2/3 lg:p-18 xs:p-6 space-y-6'>
              <div className='text-electric-blue text-5xl'>
                {t('settings.title')}
              </div>
              <hr
                className='rounded-full text-center z-0'
                style={{
                  borderTop: '2px solid rgba(136,139,141, 0.3)',
                }}
              />

              <div className='text-lg text-dark-grey-body '>
                <span className='font-bold'>
                  {t('settings.email_address')}
                  {': '}
                </span>
                {/* {profileData.username} */}
                {props.userDashboard.username}
              </div>

              <div className='flex flex-col mt-10 md:flex-row md:justify-end'>
                <div className='w-full '>
                  <PasswordCardDashboard
                    userDashboard={userDashboard}
                    handleSnackbar={handleSnackbar}
                  />
                </div>
              </div>
            </div>
            {/* <div className='w-1/3 bg-no-repeat bg-cover h-content bg-login bg-30 hidden lg:block'></div> */}
          </div>
        </>
      )}
    </div>
  );
}

SettingsScreen.propTypes = {
  userDashboard: PropTypes.any,
};

export default connect(mapStateToProps, null)(SettingsScreen);
