import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import ListItem from '../list-item/ListItem';

const Entry = ({ icon, title, route, onClick, ...rest }) => {
  const location = useLocation();
  const IconName = icon;
  return (
    <ListItem
      title={title}
      onClick={onClick}
      className={
        'flex items-center text-navy-blue hover:text-electric-blue pb-2 pt-2 '
      }
      {...rest}
    >
      <span className='md:hidden'>
        <IconName className='w-5 h-5 mx-2' />
      </span>
      <span
        className={`hidden font-bold cursor-pointer md:block ${
          location.pathname === route
            ? 'border-b-10 border-electric-blue mt-2'
            : 'border-b-10 border-transparent mt-2'
        }`}
      >
        {title}
      </span>
    </ListItem>
  );
};

Entry.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

export default Entry;
