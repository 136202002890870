/* eslint-disable */

import { useState, useEffect } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import routes from '../helpers/routes';
import Auth from '../helpers/Auth';
import Config from '../config';
import User from '../schema/User';
import Country from '../components/forms/inputs/Country';
import Loading from '../components/loading-indicator/Loading';
import Text from '../components/forms/inputs/Text';
import Button from '../components/button/Button';
import Link from '../components/link/Link';
import Home from '../components/container/Home';
import BoxShadow from '../components/box-shadow/BoxShadow';
import {
  setUserLoggedInDashboard,
  setUserDashboard,
} from '../actions/userDashboard';

import Label from '../components/label/Label';

const mapDispatchToProps = (dispatch) => ({
  dispatchLoggedInDashboard: (loggedIn) =>
    dispatch(setUserLoggedInDashboard(loggedIn)),
  dispatchUserDasboard: (loggedUser) => dispatch(setUserDashboard(loggedUser)),
});

const mapStateToProps = (state) => {
  return {
    locale: state.localeReducer.locale,
  };
};

export function RegisterScreenDashboard({
  locale,
  dispatchLoggedInDashboard,
  dispatchUserDasboard,
}) {
  const { t } = useTranslation();
  const [dataRegister, setDataRegister] = useState({
    email: '',
    password: '',
    country: {
      name: '',
      alpha2: '',
    },
  });

  const dataLogin = {
    username: '',
    password: '',
    clientId: Config.CLIENT_ID,
  };
  const [register] = useMutation(User.REGISTER_DASHBOARD);
  // const [login] = useMutation(User.LOGINDASHBOARD, {
  //   variables: { input: dataLogin },
  // });
  const history = useHistory();
  const [repeatPassword, setRepeatPassword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ValidatorForm.addValidationRule('isTruthy', (value) => value);
    ValidatorForm.addValidationRule('isDefaultValue', (value) => {
      if (value === 'defaultValue') {
        return false;
      }
      return true;
    });
  });

  const handleClick = async (e) => {
    e.preventDefault();

    if (!dataRegister.country.alpha2) {
      return;
    }

    if (loading) {
      return <Loading />;
    }

    setLoading(true);

    try {
      if (repeatPassword === dataRegister.password ? false : true) {
        throw new Error(t('registration.password_not_match'));
      }

      const dataInputRegister = {
        username: dataRegister.email,
        password: dataRegister.password,
        name: dataRegister.name,
        country_code: dataRegister.country.alpha2,
        tzname: Config.DEFAULT_TZ,
      };

      const registerResp = await register({
        variables: { input: dataInputRegister },
      });
      const responseParsed = JSON.parse(
        registerResp?.data?.createUserDashboard?.response
      );
      // const dataInputLogin = {
      //   username: dataRegister.email,
      //   password: dataRegister.password,
      //   clientId: Config.CLIENT_ID,
      // };
      // const dataOutputLogin = await login({
      //   variables: { input: dataInputLogin },
      // });

      // const responseParsed = JSON.parse(
      //   dataOutputLogin.data?.loginTokenDashboard?.response
      // );
      if (responseParsed.status === 200) {
        // dispatchLoggedInDashboard(true);
        // dispatchUserDasboard(responseParsed);
        toast.warn(
          'Thank you for registering, you will need to be verified before you can log in!',
          {
            position: 'top-right',
            autoClose: 20000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          }
        );
        // history.push(routes.login_dashboard);
      } else if (responseParsed.status === 204) {
        // setinvalidCred(true);
        // console.log(invalidCred);
        toast.error(responseParsed.response, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (e) {
      if (e.message.includes('ER_DUP_ENTRY')) {
        toast.info(t('registration.account_exists'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        // setTitle(e.message);
        toast.error(e.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }

      // setSeverity('warning');
      // setOpenSnackBar(true);
      setLoading(false);
    }
  };

  return (
    <Home>
      <ToastContainer />
      <div className='mx-auto xs:w-full md:w-3/4 xs:mt-2'>
        <h1 className='text-4xl text-electric-blue'>
          {t('admin_dashboard.register.welcome')}
        </h1>
        <br />
        <p className='text-lg font-bold text-dark-grey-body lg:w-3/4'>
          {t('admin_dashboard.register.description')}
        </p>
        <br />
        <BoxShadow>
          <ValidatorForm
            className='flex flex-col items-center'
            onSubmit={handleClick}
            noValidate
          >
            <Label
              label={t('admin_dashboard.register.sign_up')}
              className='self-start pb-2 text-sm font-bold text-primary'
            />

            <Text
              name='email'
              value={dataRegister.email}
              validators={['required', 'isEmail']}
              errorMessages={[
                t('registration_error_required'),
                t('registration_error_valid_email'),
              ]}
              onChange={(e) => {
                setDataRegister({ ...dataRegister, email: e });
              }}
              disabled={loading}
              placeholder={t('email_address')}
              autoFocus
            />
            <Text
              name='password'
              value={dataRegister.password}
              type='password'
              validators={['required']}
              errorMessages={[t('registration_error_required')]}
              onChange={(e) => {
                setDataRegister({ ...dataRegister, password: e });
              }}
              disabled={loading}
              placeholder={t('password')}
            />
            <Text
              name='passwordRepeat'
              value={repeatPassword}
              type='password'
              onChange={(e) => {
                setRepeatPassword(e);
              }}
              validators={['required']}
              errorMessages={[t('registration_error_required')]}
              error={
                repeatPassword &&
                dataRegister.password &&
                repeatPassword !== dataRegister.password
                  ? true
                  : false
              }
              helperText={
                repeatPassword &&
                dataRegister.password &&
                repeatPassword !== dataRegister.password
                  ? t('registration.password_not_match')
                  : ''
              }
              disabled={loading}
              placeholder={t('repeat_password')}
            />
            <Country
              defaultLabel={true}
              name='country'
              validators={['isDefaultValue', 'required']}
              errorMessages={[t('registration_error_required')]}
              value={dataRegister.country.alpha2}
              onChange={(country) =>
                setDataRegister({
                  ...dataRegister,
                  country: { alpha2: country },
                })
              }
              disabled={loading}
            />

            <div className='flex flex-col self-start'>
              <Button
                type='submit'
                className='px-4 mt-5 rounded-full text-dark-grey-body'
                disabled={loading}
              >
                {t('register_button')}
              </Button>
              <div className='mt-5'>
                <Link
                  onClick={() => {
                    history.push(routes.login_dashboard);
                  }}
                >
                  {t('registration_existing_account')}
                </Link>
              </div>
            </div>
          </ValidatorForm>
        </BoxShadow>
      </div>
    </Home>
  );
}

RegisterScreenDashboard.propTypes = {
  locale: PropTypes.string,
  dispatchLoggedIn: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterScreenDashboard);
